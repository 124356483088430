import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { CheckBox } from "@bokio/elements/CheckBox/CheckBox";
import { Link } from "@bokio/elements/Link/Link";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";
import { TBody, Th, THead, Tr } from "@bokio/elements/Table";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { GeneralLangFactory } from "@bokio/lang";
import BankLangFactory from "@bokio/lang/BankLangFactory";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { SveaKycBlockServicesNotice } from "@bokio/settings/src/scenes/SveaKyc/SveaKycBlockServicesNotice";
import { Table } from "@bokio/shared/components/Table";
import { getRoute } from "@bokio/shared/route";
import { mergeClassNames } from "@bokio/utils/classes";

import { SignAndPayRow } from "../../SignAndPayRow/SignAndPayRow";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type * as React from "react";

import * as styles from "./signAndPayContent.scss";

type PaymentValidationRulesDto = m.Bokio.Bank.Contract.Dtos.PaymentValidationRulesDto;
type PaymentGroupForListDto = m.Bokio.Bank.Contract.Dtos.PaymentGroupForListDto;
type FailedPaymentGroup = m.Bokio.Bank.Contract.Dtos.FailedPaymentGroup;
interface SignAndPayContentProps {
	selectedPaymentGroupIds: string[];
	isPolling: boolean;
	availablePaymentGroups: PaymentGroupForListDto[];
	disabled: boolean;
	failedPaymentGroups: Record<string, FailedPaymentGroup>;
	error: string | undefined;
	onGlobalCheckClick: (checked: boolean) => void;
	togglePaymentGroupSelection: (id: string, checked: boolean) => void;
	onClose: () => void;
	paymentValidationRules: PaymentValidationRulesDto;
	hidePaymentList?: boolean;
	children: React.ReactNode;
}

export const SignAndPayContent = ({
	isPolling,
	selectedPaymentGroupIds,
	availablePaymentGroups,
	disabled,
	failedPaymentGroups,
	error,
	onGlobalCheckClick,
	togglePaymentGroupSelection,
	onClose,
	paymentValidationRules,
	hidePaymentList,
	children,
}: SignAndPayContentProps) => {
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const [addMetric] = useMetric();
	const { companyInfo } = useCompanyInfo();
	const { shouldBlockBankServices } = useActiveBankConnection();

	// This `asWidthPlaceholder` hack it's needed to make the BankID overlay cover the right place without hard coding some height
	// while aligning the columns' width in the dangling total row with the columns' width in original table.
	const thead = (asWidthPlaceholder: boolean) => {
		return (
			<THead className={styles.thead}>
				<Tr>
					<Th className={mergeClassNames(asWidthPlaceholder && styles.invisibleWidthPlaceholder)} shrink />
					<Th className={mergeClassNames(asWidthPlaceholder && styles.invisibleWidthPlaceholder)} shrink align="center">
						<CheckBox
							className={mergeClassNames(styles.margin0, asWidthPlaceholder && styles.invisibleWidthPlaceholder)}
							checked={asWidthPlaceholder ? false : selectedPaymentGroupIds.length !== 0}
							partial={selectedPaymentGroupIds.length !== availablePaymentGroups.length}
							onChange={onGlobalCheckClick}
						/>
					</Th>
					<Th className={mergeClassNames(asWidthPlaceholder && styles.invisibleWidthPlaceholder)}>
						{bankLang.PaymentTo}
					</Th>
					<Th className={mergeClassNames(asWidthPlaceholder && styles.invisibleWidthPlaceholder)} shrink>
						{generalLang.Date}
					</Th>
					<Th />
					<Th className={mergeClassNames(asWidthPlaceholder && styles.invisibleWidthPlaceholder)} align="right">
						{generalLang.Sum}
					</Th>
				</Tr>
			</THead>
		);
	};

	return (
		<>
			{!isPolling && !hidePaymentList && (
				<>
					{availablePaymentGroups.length > 0 ? (
						<Table>
							{thead(false)}
							<TBody>
								{availablePaymentGroups.map(paymentGroup => (
									<SignAndPayRow
										key={paymentGroup.Id}
										paymentGroup={paymentGroup}
										disabled={disabled}
										selected={selectedPaymentGroupIds.includes(paymentGroup.Id)}
										toggle={togglePaymentGroupSelection}
										failedPayments={failedPaymentGroups[paymentGroup.Id]?.FailedPayments ?? {}}
										paymentValidationRules={paymentValidationRules}
									/>
								))}
							</TBody>
						</Table>
					) : (
						<div className={styles.emptyView}>
							<Paragraph style="heading-default">{generalLang.BBA_NoPaymentsOrTransfers}</Paragraph>
							<Paragraph marginAway="4">{generalLang.BBA_AddPaymentsOrTransfers}</Paragraph>
							<Paragraph marginAway="8">
								<Link
									route={getRoute("bankCreatePayment", { company: companyInfo.Id })}
									onClick={() => {
										onClose();
										addMetric(AnalyticsEventCategory.Bba, "GoToCreatePayment", "FromSignAndPayPopoverEmptyView");
									}}
								>
									{generalLang.BBA_CreatePaymentOrTransfer}
								</Link>
							</Paragraph>
						</div>
					)}
					{error && (
						<Notice color="error" margin title={bankLang.SignAndPayModalErrorTitle} collapsible={false}>
							<Markdown markdownContent={error} useTypographicElements />
						</Notice>
					)}
					{shouldBlockBankServices && <SveaKycBlockServicesNotice margin={["top", "left", "right", "bottom"]} />}
				</>
			)}

			{children}
		</>
	);
};
