import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";
import { getPlanDescription, getPlanName } from "@bokio/utils/priceplanUtils";

import BokioPlan = m.Entities.BokioPlan;

interface UpgradePricePlanModalProps {
	companyId: string;
	upgradeToPlans: m.Entities.BokioPlan[];
	visible: boolean;
	onClose: () => void;
	upgradeNudgeOverride?: string;
}

export const UpgradePricePlanModal = ({
	upgradeToPlans,
	visible,
	onClose,
	upgradeNudgeOverride,
	companyId,
}: UpgradePricePlanModalProps) => {
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();

	const defaultUpgradeNudge = settingsLang.Plans_UpgradeModal_YouNeedToUpgrade;

	const getPlanFeaturesText = (plan: BokioPlan) => {
		switch (plan) {
			case BokioPlan.FreeShared: // This shouldn't happen bug we need to keep it for backwards compatibility
			case BokioPlan.Free:
			case BokioPlan.Beta:
			case BokioPlan.Balance:
			case BokioPlan.Business:
			case BokioPlan.Invalid:
				return "";
			case BokioPlan.Premium:
				return settingsLang.Plans_UpgradeModal_Premium_Features;
			case BokioPlan.Pro:
				return settingsLang.Plans_UpgradeModal_Pro_Features;
			case BokioPlan.Basic_2025:
				return settingsLang.Plans_UpgradeModal_Basic_2025_Features;
			case BokioPlan.Premium_2025:
				return settingsLang.Plans_UpgradeModal_Premium_2025_Features;
			case BokioPlan.Business_2025:
				return settingsLang.Plans_UpgradeModal_Business_2025_Features;
			case BokioPlan.Plus_2025:
				return settingsLang.Plans_UpgradeModal_Plus_2025_Features;
		}
	};
	const planNames = upgradeToPlans.map(getPlanName);

	return (
		<Modal
			onClose={onClose}
			title={formatMessage(generalLang.UpgradeToX, planNames.join(` ${generalLang._OR} `))}
			visible={visible}
		>
			<ModalContent>
				<SG section gap="16">
					<Markdown useTypographicElements markdownContent={upgradeNudgeOverride ?? defaultUpgradeNudge} />
					{upgradeToPlans.map(plan => (
						<div key={plan}>
							<Markdown useTypographicElements markdownContent={getPlanDescription(plan)} />
							<SG gap="12" horizontalInDesktop>
								<Markdown useTypographicElements={{ compact: true }} markdownContent={getPlanFeaturesText(plan)} />
							</SG>
						</div>
					))}
				</SG>
			</ModalContent>
			<ModalFooter>
				<Button appearance="secondary" onClick={onClose}>
					{generalLang.Cancel}
				</Button>
				<Button type="link" route={getRoute("billing", { company: companyId })}>
					{generalLang.PricePlan_Upgrade}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
