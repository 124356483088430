import { createMemoryRouter, RouterProvider, useParams } from "react-router";

import { useRouter } from "@bokio/shared/containers/router/useRouter";

import { createConnectHoc } from "./createConnectHoc";

import type { BokioRouterLocationState } from "@bokio/shared/containers/router/useRouter";
import type { Location } from "react-router";

/**
 * @deprecated use `useParams` from react-router instead.
 */
export type ReactRouterV5RouteComponentProps<TParams extends { [K in keyof TParams]?: string | undefined } = object> = {
	match: {
		params: TParams;
	};
	location: Location<BokioRouterLocationState>;
};

export const reactRouterV5 = {
	/**
	 * @deprecated use `useParams` from react-router instead.
	 */
	connect: <
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		T extends ReactRouterV5RouteComponentProps<TParams> = ReactRouterV5RouteComponentProps<any>,
		TParams extends { [K in keyof TParams]?: string | undefined } = object,
	>() =>
		createConnectHoc(() => {
			const router = useRouter();
			const params = useParams<TParams>();

			return {
				match: {
					params,
				},
				location: router.location,
			} as unknown as T;
			// Returning a component that doesn't accept children to make it clear
			// that the result component from this connection is only expected to be used by `createBundleLoader`
			// eslint-disable-next-line @typescript-eslint/no-empty-object-type
		}) as unknown as (originalComponent: TParams) => React.ComponentType<{}>,
};

/**
 * Ignore type errors when using `useParams` from react-router. Should only be used to ease migration from React Router v5 to v6.
 *
 * @deprecated use `useParams` from react-router instead and perform proper type guard.
 */
export const useParamsV5 = <T,>() => {
	return useParams() as T;
};

export const renderInMemoryRouterAndGetRouterReference = (
	children: React.ReactNode,
	location?: Partial<Location<BokioRouterLocationState>>,
) => {
	const router = createMemoryRouter(
		[
			{
				// Match * to ease migration from React Router v5 to v6.
				// https://github.com/remix-run/react-router/issues/9422#issuecomment-1302564759
				path: "*",
				element: <>{children}</>,
			},
		],
		{
			initialEntries: location && [location],
		},
	);

	return {
		nodeToRender: <RouterProvider router={router} />,
		router,
	};
};

export const renderInMemoryRouter = (
	children: React.ReactNode,
	location?: Partial<Location<BokioRouterLocationState>>,
) => {
	return renderInMemoryRouterAndGetRouterReference(children, location).nodeToRender;
};
