import * as React from "react";

import { LoadingBox } from "@bokio/elements/Loading";
import { toEnvelopeEndpoint, useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { trackTrace } from "@bokio/utils/t";
import { toPath, urlStartsWithCompanyId } from "@bokio/utils/url";

import type { Location } from "react-router";

const getRedirectLink = (deepLink: string, memberships: { CompanyId: string }[], partnerMembershipsCount: number) => {
	if (memberships.length === 1 && partnerMembershipsCount === 0) {
		return `/${memberships[0].CompanyId}${deepLink}`;
	}

	return getRoute("selectCompany", {}, { deepLink });
};

interface RedirectToProps {
	path: string;
}
const RedirectTo = ({ path }: RedirectToProps) => {
	const router = useRouter();
	React.useEffect(() => {
		trackTrace("LookForDeepLink.redirect", { path });
		router.redirect(path);
	}, [router, path]);
	return <LoadingBox />;
};

interface LookForDeepLinkProps {
	location: Location;
	notFound: JSX.Element;
}

export const LookForDeepLink = ({ notFound, location }: LookForDeepLinkProps) => {
	const [getUserRequest] = useApi(toEnvelopeEndpoint(proxy.Settings.UserController.Index.Get), []);

	if (urlStartsWithCompanyId(location.pathname)) {
		trackTrace("LookForDeepLink.404", { location: toPath(location) });
		return notFound;
	}

	if (getUserRequest.error || getUserRequest.data?.Error) {
		trackTrace("LookForDeepLink.401", { location: toPath(location) });
		return notFound;
	}

	const user = getUserRequest.data?.Data;

	if (!user) {
		return <LoadingBox request={getUserRequest} />;
	}

	return (
		<RedirectTo
			path={getRedirectLink(toPath(location), user.CompanyMemberships || [], user.PartnerMembershipsCount ?? 0)}
		/>
	);
};
