import * as React from "react";

import { useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { useBokioPlanLockBadge } from "../../hooks/useBokioPlanLockBadge/useBokioPlanLockBadge";
import { UpgradePricePlanModal } from "../UpgradePricePlanModal/UpgradePricePlanModal";

import type {
	BokioPlanLockBadgeHookHandle,
	PricePlanFeature,
} from "../../hooks/useBokioPlanLockBadge/useBokioPlanLockBadge";
import type { FontelloIcons } from "@bokio/assets/fontello";
import type { IconAlign } from "@bokio/designsystem/components/Button/button.types";

import AnalyticsEventCategory = m.Bokio.Common.Contract.Metrics.Analytics.Requests.AnalyticsEventCategory;

export type BokioPlanFeatureElementProps = {
	feature: PricePlanFeature;
	upgradeNudgeOverride?: string;
	planDescriptionOverride?: string;
	metricAlternative?: string;
	lockCondition?: (hook: BokioPlanLockBadgeHookHandle | undefined) => boolean;
	lockedTestId?: string;
};

export type BokioPlanFeatureLockedButtonProps<TActualButtonAnchor> = {
	onClick: (e: React.MouseEvent<TActualButtonAnchor>) => void;
	disabled: boolean;
	testId?: string;
	icon: FontelloIcons;
	iconAlign: IconAlign | undefined;
	ref: React.Ref<HTMLButtonElement | HTMLAnchorElement> | undefined;
};

export const useBokioPlanFeatureElement = <
	TActualButtonProps,
	TActualButtonAnchor extends HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
	TActualButtonRef extends React.Ref<HTMLButtonElement | HTMLAnchorElement>,
>({
	featureButtonProps: {
		feature,
		upgradeNudgeOverride,
		metricAlternative,
		lockCondition = hook => !!hook?.isFeatureLocked,
		lockedTestId,
	},
	lockButtonRef,
	lockButtonProps,
	renderActualButton,
	renderLockedButton,
}: {
	featureButtonProps: BokioPlanFeatureElementProps;
	lockButtonRef: TActualButtonRef | undefined;
	lockButtonProps: TActualButtonProps;
	renderActualButton: () => React.ReactNode;
	renderLockedButton: (props: BokioPlanFeatureLockedButtonProps<TActualButtonAnchor>) => React.ReactNode;
}): { renderButton: () => React.ReactElement } => {
	const hook = useBokioPlanLockBadge({ feature });
	const [addMetric] = useMetric();

	const [showPopup, setShowPopup] = React.useState(false);
	const { companyInfo } = useCompanyInfo();

	const checkIsLocked = (hook: BokioPlanLockBadgeHookHandle | undefined): hook is BokioPlanLockBadgeHookHandle => {
		return lockCondition(hook);
	};

	const renderButton = (): React.ReactElement => {
		if (!checkIsLocked(hook)) {
			return <>{renderActualButton()}</>;
		}

		return (
			<>
				{renderLockedButton({
					...lockButtonProps,
					onClick: e => {
						e.preventDefault();
						addMetric(
							AnalyticsEventCategory.BokioPlans,
							`BlockedFeatureClicked${metricAlternative ? `_${metricAlternative}` : ""}`,
							feature,
						);
						setShowPopup(true);
						return false;
					},
					disabled: false,
					testId: lockedTestId,
					icon: "lock",
					ref: lockButtonRef,
					iconAlign: "left",
				})}

				<UpgradePricePlanModal
					upgradeToPlans={hook.plansNeeded}
					visible={showPopup}
					onClose={() => setShowPopup(false)}
					upgradeNudgeOverride={upgradeNudgeOverride}
					companyId={companyInfo.Id}
				/>
			</>
		);
	};

	return {
		renderButton,
	};
};
