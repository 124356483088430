import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

type AgencyUserPreferencesDto = m.Bokio.Common.Contract.Services.UserPreferences.AgencyUserPreferencesDto;
import AgencyUserPreferenceType = m.Entities.AgencyUserPreferenceType;
export { AgencyUserPreferenceType };

/**
 * See also another hook {@link useUserPreferenceDismiss} if you just want to create an user preference for dismissing something.
 *
 * General instructions on how to create an user prefrence from backend to frontend:
 * 1. Add a new enum name to UserPreferenceType.
 * 2. Add a new prop to UserPreferencesDto (see how other "dismissed" props in that file were coded, you can basically copy-paste that).
 * 3. Implement GetPreference in UserPreferencesService.
 * 4. Imeplement SetPreference in UserPreferencesService.
 * 5. Add the new UserPreferenceType to UserPreferencesService -> Versions.
 * 6. Regenrate `yarn stubs`.
 * 7. Use this hook and pass the type you added above.
 */
export const useAgencyUserPreference = (partnerId: string, type: AgencyUserPreferenceType) => {
	const [getPreferenceRequest, refreshPreference] = useApi(
		proxy.BackOffice.AgencyUserPreferencesController.GetPreference.Get,
		[partnerId, type],
	);

	const [setPreference, setPreferenceRequest] = useLazyApi(
		(params: { value: AgencyUserPreferencesDto }) => {
			return proxy.BackOffice.AgencyUserPreferencesController.SetPreference.Post(partnerId, type, params.value);
		},
		{ onSuccess: refreshPreference },
	);

	return {
		preferenceValue: getPreferenceRequest.data?.Data,
		setPreference,
		setPreferenceRequest,
		refreshPreference,
		getPreferenceRequest,
	};
};
