import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";
import { LoadingProgress } from "@bokio/elements/Loading";
import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";

import type { FontelloIcons } from "@bokio/assets/fontello";

import * as styles from "./dropdownItem.scss";

interface DropdownItemLink {
	route?: string;
	external?: string;
	target?: string;
}

interface DropdownItemVisualisationProps {
	children?: React.ReactNode;
	type?: "normal" | "separator";
	loadable?: boolean;
	loading?: boolean;
	setActive?: (v: boolean) => void;
	link?: DropdownItemLink;
	appearance?: "destructive";
	icon?: FontelloIcons;
}

export interface DropdownItemProps extends DropdownItemVisualisationProps {
	onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>, a?: (v: boolean) => void) => void;
	className?: string;
	testId?: string;
}

export const DropdownItem = ({
	children,
	type,
	loadable,
	loading,
	className,
	link,
	testId,
	appearance,
	onClick,
	setActive,
	icon,
}: DropdownItemProps) => {
	const handleClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
		if (loadable) {
			onClick && onClick(e, setActive);
		} else {
			setActive && setActive(false);
			onClick && onClick(e);
		}
	};

	const itemClass = mergeClassNames(
		styles.dropdownItem,
		type === "separator" && styles.separator,
		appearance === "destructive" && styles.destructive,
		className,
	);

	return (
		<React.Fragment>
			{loadable && loading ? (
				<div className={styles.loading}>
					<LoadingProgress />
				</div>
			) : link ? (
				<Link
					className={itemClass}
					route={link.route}
					external={link.external}
					target={link.target}
					testId={testId}
					onClick={handleClick}
				>
					{children}
					{icon && <Icon name={icon} />}
				</Link>
			) : (
				<div className={itemClass} onClick={handleClick} data-testid={testId}>
					{children}
					{icon && <Icon name={icon} />}
				</div>
			)}
		</React.Fragment>
	);
};

DropdownItem.displayName = "DropdownItem";
