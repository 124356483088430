import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { BankLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { trackTrace } from "@bokio/utils/t";

import { ModalContent } from "../Modal";
import { SveaBankIdFragment } from "../SveaBankIdFragment/SveaBankIdFragment";
import { useSveaBankIdState } from "../SveaBankIdFragment/useSveaBankIdState";
import {
	clearPaymentSigningProgressInfoFromLocalStorage,
	clearSveaAuthPollingTokenFromLocalStorage,
	readSveaAuthPollingTokenFromLocalStorage,
	saveSveaAuthPollingTokenToLocalStorage,
	useBankIdPolling,
} from "./sveaPollingUtils";

import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
import BankIdHintCode = m.Bokio.Common.Contract.BankId.BankIdHintCode;

export const useSveaBankIdAuth = ({
	isOnboarding,
	onSuccess,
}: {
	onSuccess: (companyId: string, reloadCompanyInfo?: boolean) => void;
	isOnboarding: boolean | undefined;
}) => {
	const { companyInfo } = useCompanyInfo();
	const { bankIdState, resetBankIdState, setBankIdState } = useSveaBankIdState();

	const bankLang = BankLangFactory();

	const sveaAuthStatusAndVerifyPoller = useBankIdPolling(
		"useSveaBankIdAuth",
		proxy.Bank.SveaAuthController.GetBankIdAuthStatusAndVerifyAuth.Post,
		{
			isPending: data => data.Status == BankIdCollectionStatus.Pending,
			effect: data => {
				if (!data) {
					return;
				}
				if (data?.Status == BankIdCollectionStatus.Complete) {
					clearSveaAuthPollingTokenFromLocalStorage();
					resetBankIdState();
					onSuccess(companyInfo.Id, data?.ShouldRefreshCompanyInfo);
					return;
				}
				setBankIdState({
					collectionStatus: data.Status,
					bankIdQrCode: data.QrCode,
					hintCode: data.HintCode ?? BankIdHintCode.OutstandingTransaction,
				});
			},
			onStopped: (
				context, // EE: Log context to be able to figure out why the polling just stops on iPhones.
			) => {
				trackTrace("BankIdPollingStopCondition", {
					details: JSON.stringify(context),
					now: Date.now().toString(),
					companyId: companyInfo.Id,
				});

				if (context?.error || context?.apiError) {
					clearSveaAuthPollingTokenFromLocalStorage();
					clearPaymentSigningProgressInfoFromLocalStorage();
					resetBankIdState({ hasError: true });
					return;
				}
			},
		},
	);

	const [start] = useLazyApi(proxy.Bank.SveaAuthController.StartBankIdAuthentication.Post, {
		onSuccess: response => {
			setBankIdState(
				{
					autoStartToken: response.BankIdToken,
					bankIdQrCode: response.QrCode,
					hintCode: BankIdHintCode.OutstandingTransaction,
				},
				{ lockQrCode: true },
			);
			saveSveaAuthPollingTokenToLocalStorage(response.BankIdToken);
			sveaAuthStatusAndVerifyPoller.startPolling([
				companyInfo.Id,
				{
					BankIdToken: response.BankIdToken,
				},
			]);
		},
	});

	const [cancel] = useLazyApi(proxy.Bank.SveaAuthController.CancelBankIdAuth.Post);

	const renderModalContent = () => (
		<ModalContent>
			<SveaBankIdFragment
				bankIdProps={bankIdState}
				pollingRequest={sveaAuthStatusAndVerifyPoller.request}
				onRetry={() => onSuccess(companyInfo.Id)}
			>
				{isOnboarding && (
					<Paragraph testId="SveaAuthModal_OnboardingParagraph">
						{bankLang.ActivateBusinessAccount_Modal_Description}
					</Paragraph>
				)}
			</SveaBankIdFragment>
		</ModalContent>
	);

	return {
		startWithQr: () => {
			resetBankIdState();
			const hasAuthPollingToken = readSveaAuthPollingTokenFromLocalStorage();
			if (!hasAuthPollingToken) {
				start(companyInfo.Id);
			} else {
				sveaAuthStatusAndVerifyPoller.stopPolling();
				setBankIdState(
					{
						autoStartToken: hasAuthPollingToken,
						bankIdQrCode: undefined,
						hintCode: BankIdHintCode.OutstandingTransaction,
					},
					{ lockQrCode: false },
				);
				sveaAuthStatusAndVerifyPoller.startPolling([
					companyInfo.Id,
					{
						BankIdToken: hasAuthPollingToken,
					},
				]);
			}
		},
		cancelWithQr: () => {
			sveaAuthStatusAndVerifyPoller.stopPolling();
			clearSveaAuthPollingTokenFromLocalStorage();
			clearPaymentSigningProgressInfoFromLocalStorage();
			if (bankIdState.autoStartToken) {
				cancel(companyInfo.Id, {
					BankIdToken: bankIdState.autoStartToken,
				});
			}
			resetBankIdState();
		},
		renderModalContent,
	};
};
