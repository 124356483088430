import * as React from "react";

import classes, { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./dropdownMenu.scss";

interface DropdownMenuProps {
	className?: string;
	isActive?: boolean;
	children: React.ReactNode;
	align?: "left" | "right";
	setActive?: (v: boolean) => void;
}

export function DropdownMenu({ className, align = "left", isActive, children, setActive }: DropdownMenuProps) {
	const divClass = classes(styles, "dropdownMenu", {
		dropdownMenuActive: isActive,
		[align]: true,
	});

	const deepCloneChildren = (children: React.ReactNode, key?: number): React.ReactNode => {
		if (Array.isArray(children)) {
			return children.filter(child => child).map((child, index) => deepCloneChildren(child, index));
		} else if (
			React.isValidElement(children) &&
			(children.type as React.FunctionComponent).displayName === "DropdownItem"
		) {
			// TODO: MQ 2019-02-15 Using context instead
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return React.cloneElement(React.Children.only(children), { setActive, key } as any);
		} else {
			return children;
		}
	};

	return (
		<div data-testid="DropdownMenu_Container" className={mergeClassNames(divClass, className)}>
			{deepCloneChildren(children)}
		</div>
	);
}
