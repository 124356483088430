import * as React from "react";

import withErrorMessage from "@bokio/contexts/AppContext/withErrorMessage";
import { GeneralLangFactory } from "@bokio/lang";
import { toErrorEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { error, load, success } from "@bokio/mobile-web-shared/services/api/requestState";
import { produceWithoutFreeze } from "@bokio/utils/immerMigrationHelper";
import { trackError } from "@bokio/utils/t";
import {
	MAX_PDF_FILE_SIZE,
	prepareImageForUpload,
	uploadImageRequest,
	uploadPdfRequest,
} from "@bokio/utils/uploadUtils";

import type { ErrorMessageProps } from "@bokio/contexts/AppContext/withErrorMessage";
import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";
import type { BlobFile } from "@bokio/utils/uploadUtils";

type ReceiptViewModel = m.Accounting.Viewmodels.ReceiptViewModel;

export type ReceiptUploadRequest = {
	fileName: string;
	request: RequestState<m.Envelope<ReceiptViewModel, string>>;
};

export type UploadReceiptProviderRenderArgs = {
	invokeUpload: () => void;
	requests?: ReceiptUploadRequest[];
	setImmediatelyDoReceiptPrediction: (immediatelyDoReceiptPrediction: boolean) => void;
	handleUploadFiles: (files: File[]) => void;
};

export interface UploadReceiptProviderProps {
	companyId: string | undefined;
	allowMultiUpload: boolean;
	children: (args: UploadReceiptProviderRenderArgs) => React.ReactNode;
	onUploadStarted?: (request: File[]) => void;
	onSingleReceiptUpload?: (data: m.Envelope<ReceiptViewModel, string>) => void;
	onUploadChange?: (requests: ReceiptUploadRequest[]) => void;
	onUploadDone?: (request: ReceiptUploadRequest) => void;
	onAllUploadsDone?: (requests: ReceiptUploadRequest[]) => void;
	connectToAutoVerificationRow?: string;
	connectToVerification?: string;
	uploadInputTestId?: string;
}

interface UploadReceiptProviderState {
	requests?: ReceiptUploadRequest[];
}

class UploadReceiptProviderInner extends React.Component<
	UploadReceiptProviderProps & ErrorMessageProps,
	UploadReceiptProviderState
> {
	state: UploadReceiptProviderState = {};
	private inputRef: HTMLInputElement | null = null;
	private immediatelyDoReceiptPrediction = false;

	render() {
		return (
			<React.Fragment>
				<input
					data-testid={this.props.uploadInputTestId || "UploadReceiptProvider_Input"}
					ref={inputRef => (this.inputRef = inputRef)}
					id="uploadFile"
					type="file"
					accept="image/jpeg, image/png, application/pdf"
					onChange={this.handleUploadFromInputElement}
					hidden={true}
					multiple={this.props.allowMultiUpload}
					onClick={this.clearInput}
				/>
				{this.props.children({
					invokeUpload: this.invokeUpload,
					requests: this.state.requests,
					setImmediatelyDoReceiptPrediction: this.setImmediatelyDoReceiptPrediction,
					handleUploadFiles: this.handleUploadFiles,
				})}
			</React.Fragment>
		);
	}

	handleUploadFromInputElement = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.target.files && this.handleUploadFiles(Array.from(e.target.files));
	};

	handleUploadFiles = async (files: File[]) => {
		if (!files || files.length === 0) {
			return;
		}

		const { onUploadStarted } = this.props;
		const lang = GeneralLangFactory();

		const requestQueue: ReceiptUploadRequest[] = this.state.requests ?? [];
		const supportedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
		files
			// We won't create a error request for faulty file types as we message that to the user through the toaster
			.filter(f => supportedFileTypes.includes(f.type))
			.forEach(f => {
				requestQueue.push({
					fileName: f.name,
					request: load<m.Envelope<ReceiptViewModel, string>>(undefined),
				});
			});

		// Remember that the setState doens't happen immediately and that the rest of this function will run (or hit await) before it executes
		this.setState({ requests: requestQueue });
		onUploadStarted && onUploadStarted(files);

		// We await on each upload to test out with some performance concerns,
		// but be aware that this slows down the upload a lot when it's multiple uploads.
		// https://dev.azure.com/bokiodev/Voder/_git/Voder/pullrequest/22163
		//
		// The potential improvement here is to use promiseAllLimit like in the mobile app.
		for (const currentFile of files) {
			if (currentFile.type === "application/pdf") {
				await this.uploadPdf(currentFile, files.length === 1);
			} else if (currentFile.type === "image/png" || currentFile.type === "image/jpeg") {
				const image = await prepareImageForUpload(currentFile);
				await this.uploadImage(image, currentFile, files.length === 1);
			} else {
				trackError("", "handleUploadFiles", { fileType: currentFile.type, fileName: currentFile.name });
				this.props.setErrorMessage(lang.ReceiptUpload_FileTypeNotSupported);
			}
		}
	};

	invokeUpload = () => {
		if (this.inputRef) {
			this.inputRef.click();
		}
	};

	setImmediatelyDoReceiptPrediction = (immediatelyDoReceiptPrediction: boolean) => {
		this.immediatelyDoReceiptPrediction = immediatelyDoReceiptPrediction;
	};

	clearInput = () => {
		if (this.inputRef) {
			this.inputRef.value = "";
		}
	};

	updateRequests = (
		fileName: string,
		newValue: RequestState<m.Envelope<ReceiptViewModel, string>>,
		requests?: ReceiptUploadRequest[],
	) => {
		if (requests) {
			// Only pending requests shall be set to done/error. Historic requests are keep in the state
			// and queries are done by filename. If x.request.isLoading is not included in the query,
			// historic requests may be updated as instead of the active.
			const index = requests.findIndex(x => x.request.isLoading && x.fileName === fileName);
			if (index === -1) {
				trackError("", "updateRequests", { fileName, requestFileNames: requests?.map(r => r.fileName) });
				throw new Error("Tried to update a file request that didn't exist");
			} else {
				return produceWithoutFreeze(requests, draft => {
					draft[index].request = newValue;
				});
			}
		}
		return undefined;
	};

	uploadPdf = async (currentFile: File, isSingleUpload: boolean) => {
		if (!this.props.companyId) {
			throw new Error("Invalid Company Id");
		}
		await this.handleUploadPromise(
			uploadPdfRequest({
				companyId: this.props.companyId,
				pdf: currentFile,
				startReceiptPredictionNow: this.immediatelyDoReceiptPrediction || !isSingleUpload,
				autoVerificationRowId: this.props.connectToAutoVerificationRow,
				verificationId: this.props.connectToVerification,
			}),
			currentFile,
			isSingleUpload,
		);
	};

	uploadImage = async (image: BlobFile, currentFile: File, isSingleUpload: boolean) => {
		if (!this.props.companyId) {
			throw new Error("Invalid Company Id");
		}

		await this.handleUploadPromise(
			uploadImageRequest({
				companyId: this.props.companyId,
				image,
				startReceiptPredictionNow: this.immediatelyDoReceiptPrediction || !isSingleUpload,
				autoVerificationRowId: this.props.connectToAutoVerificationRow,
				verificationId: this.props.connectToVerification,
			}),
			currentFile,
			isSingleUpload,
		);
	};

	handleUploadPromise = async (
		uploadPromise: Promise<m.Envelope<ReceiptViewModel, string>>,
		currentFile: File,
		isSingleUpload: boolean,
	) => {
		try {
			const data = await uploadPromise;
			await this.setSuccess(currentFile, data, isSingleUpload);
		} catch (error) {
			// We want to investigate upload pdf null pointer exception thrown in the BE because request body, or parts of it, is null
			// since we are only interested in those errors we filter away those too big or those that are not pdfs
			if (
				currentFile === undefined ||
				(currentFile.type === "application/pdf" && currentFile.size <= MAX_PDF_FILE_SIZE)
			) {
				trackError("", "uploadPdf", {
					requestArrayLength: this.state.requests?.length,
					isSingleUpload,
					fileSize: currentFile.size,
					isFileUndefined: currentFile === undefined,
					hasDistinctFileNames:
						this.state.requests?.map(r => r.fileName).length ===
						Array.from(new Set(this.state.requests?.map(r => r.fileName))).length,
				});
			}
			await this.setError(currentFile, error, isSingleUpload);
			// TODO callback on error
		} finally {
			this.props.onUploadChange && this.props.onUploadChange(this.state.requests || []);
			if (!this.state.requests?.some(x => x.request.isLoading)) {
				this.props.onAllUploadsDone && this.props.onAllUploadsDone(this.state.requests || []);
			}
		}
	};

	setSuccess(currentFile: File, data: m.Envelope<ReceiptViewModel, string>, isSingleUpload: boolean) {
		return new Promise<void>(resolve =>
			this.setState(
				prevState => ({
					requests: this.updateRequests(currentFile.name, success(data), prevState.requests),
				}),
				() => {
					this.notifySingleUpload(isSingleUpload, data);
					this.props.onUploadDone && this.props.onUploadDone({ fileName: currentFile.name, request: success(data) });
					resolve();
				},
			),
		);
	}

	setError(currentFile: File, errorData: { message: string }, isSingleUpload: boolean) {
		return new Promise<void>(resolve => {
			this.setState(
				prevState => ({
					requests: this.updateRequests(currentFile.name, error(errorData.message), prevState.requests),
				}),
				() => {
					this.notifySingleUpload(isSingleUpload, toErrorEnvelope(errorData.message, errorData.message));
					resolve();
				},
			);
		});
	}

	notifySingleUpload = (isSingleUpload: boolean, request?: m.Envelope<ReceiptViewModel, string>) => {
		const { onSingleReceiptUpload } = this.props;
		if (onSingleReceiptUpload && isSingleUpload && request) {
			onSingleReceiptUpload(request);
		}
	};
}

export const UploadReceiptProvider = withErrorMessage(UploadReceiptProviderInner);
