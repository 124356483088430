import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

type UserPreferencesDto = m.Bokio.Common.Contract.Services.UserPreferences.UserPreferencesDto;
import UserPreferenceType = m.Entities.UserPreferenceType;
export { UserPreferenceType };

/**
 * See also another hook {@link useUserPreferenceDismiss} if you just want to create an user preference for dismissing something.
 *
 * General instructions on how to create an user prefrence from backend to frontend:
 * 1. Add a new enum name to UserPreferenceType.
 * 2. Add a new prop to UserPreferencesDto (see how other "dismissed" props in that file were coded, you can basically copy-paste that).
 * 3. Implement GetPreference in UserPreferencesService.
 * 4. Imeplement SetPreference in UserPreferencesService.
 * 5. Add the new UserPreferenceType to UserPreferencesService -> Versions.
 * 6. Regenrate `yarn stubs`.
 * 7. Use this hook and pass the type you added above.
 */
export const useUserPreference = (companyId: string, type: UserPreferenceType) => {
	const [getPreferenceRequest, refreshPreference] = useApi(proxy.Common.UserPreferencesController.GetPreference.Get, [
		companyId,
		type,
	]);

	const [setPreference, setPreferenceRequest] = useLazyApi(
		(params: {
			/**
			 * When it is company specific,
			 * say the user has 2 companies A and B,
			 * they can set different preferences for A and B.
			 *
			 * When it's not company specific, then the user will get the same preference value in all the companies.
			 */
			isCompanySpecific: boolean;

			value: UserPreferencesDto;
		}) => {
			return proxy.Common.UserPreferencesController.SetPreference.Post(
				companyId,
				type,
				params.isCompanySpecific,
				params.value,
			);
		},
		{ onSuccess: refreshPreference },
	);

	return {
		preferenceValue: getPreferenceRequest.data?.Data,
		setPreference,
		setPreferenceRequest,
		refreshPreference,
		getPreferenceRequest,
	};
};

export const useUserPreferenceWithoutCompany = (type: UserPreferenceType) => {
	const [getPreferenceRequest, refreshPreference] = useApi(
		proxy.Common.UserPreferencesController.GetUserPreference.Get,
		[type],
	);

	const [setPreference, setPreferenceRequest] = useLazyApi(
		(params: { value: UserPreferencesDto }) =>
			proxy.Common.UserPreferencesController.SetUserPreference.Post({
				IsCompanySpecific: false,
				Type: type,
				Value: params.value,
			}),
		{ onSuccess: refreshPreference },
	);

	return {
		preferenceValue: getPreferenceRequest.data?.Data,
		setPreference,
		setPreferenceRequest,
		refreshPreference,
		getPreferenceRequest,
	};
};
