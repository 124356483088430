import * as React from "react";

import { cssVariableForColor, cssVariableForMargin } from "@bokio/designsystem/theme";
import { Badge } from "@bokio/elements/Badge/Badge";
import { mergeClassNames } from "@bokio/utils/classes";

import { TypoGraphicElementInternals } from "./TypographicElements.internals";
import { marginAwayToClassNameMapping, textStylesToClassNameMapping } from "./TypographicElements.styles";

import type {
	ContainerProps,
	HeadingLevelSupportProps,
	InternalTypographicSectionProps,
	MarginProps,
	TextBlockAlignProps,
	TextStyleProps,
} from "./TypographicElements.types";
import type { BadgeProps } from "@bokio/elements/Badge/Badge";

import * as styles from "./typographicElements.scss";

// TODO: Design system team: Consider removing auto-margin on typographic elements after spacing group is in place.
const createSectionElement: React.FC<React.PropsWithChildren<InternalTypographicSectionProps>> = ({
	type = "section",
	bumpHeadingLevel,
	resetHeadingLevelTo,
	children,
	marginAway,
	textCenter,
	style: textStyle,
	color,
	testId,
}) => {
	const section = React.createElement(
		type,
		{
			style: cssVariableForColor(color, "--typographic-element-color-prop-value"),
			className: mergeClassNames(
				styles.sectionWrapper,
				marginAway && marginAwayToClassNameMapping[marginAway],
				type === "p" && styles.whiteSpacePreLine,
				textCenter && styles.textCenter,
				textStyle && textStylesToClassNameMapping[textStyle],
				color && styles.useVariableColor,
			),
			"data-testid": testId,
		},
		children,
	);

	if (bumpHeadingLevel || resetHeadingLevelTo) {
		return (
			<TypoGraphicElementInternals.HeadingLevel resetHeadingLevelTo={resetHeadingLevelTo}>
				{section}
			</TypoGraphicElementInternals.HeadingLevel>
		);
	}

	return section;
};

type SectionProps = MarginProps &
	HeadingLevelSupportProps &
	ContainerProps &
	TextBlockAlignProps &
	TextStyleProps & { testId?: string };

/**
 * A `<section>` element with automatic margin when combined with another typographic element.
 * Bumps heading level for descendant headings by default.
 */
export const Section: React.FC<React.PropsWithChildren<SectionProps>> = props => {
	return createSectionElement({ bumpHeadingLevel: props.bumpHeadingLevel ?? true, ...props, type: "section" });
};

/**
 * A `<div>` element with automatic margin when combined with another typographic element.
 * Unlike `<Section>`, this doesn't bump heading level by default.
 *
 * @deprecated Prefer using `<Div>` instead 😃.
 */
export const SectionDiv: React.FC<React.PropsWithChildren<SectionProps>> = props => {
	return createSectionElement({ ...props, type: "div" });
};

/**
 * A `<div>` element with automatic margin when combined with another typographic element.
 *
 * Same as `<SectionDiv>`, just an alias to that component.
 */
export const Div = SectionDiv;

type ArticleProps = HeadingLevelSupportProps & { testId?: string };

/**
 * An `<article>` element with automatic margin when combined with another typographic element.
 * Bumps heading level for descendant headings by default.
 */
export const Article: React.FC<React.PropsWithChildren<ArticleProps>> = props => {
	return createSectionElement({ bumpHeadingLevel: props.bumpHeadingLevel ?? true, ...props, type: "article" });
};

type ParagraphProps = MarginProps & TextBlockAlignProps & TextStyleProps & ContainerProps;

/**
 * A `<p>` element with automatic margin when combined with another typographic element.
 * @example
 * <Section>
 *     <Heading>Title</Heading>
 *     <Paragraph>Description</Paragraph>
 * </Section>
 */
export const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = props => {
	return createSectionElement({ ...props, type: "p" });
};

/**
 * Make the text bold.
 *
 * @deprecated Use {@link TextSpan} and specify a bold style there instead.
 * The reason is because increasing the font weight to semibold doesn't really align with the "bold" font styles we have in all cases.
 *
 * @example
 * <Paragraph>Make the text <Strong>bold</Strong> by wrapping it with Strong.</Paragraph>
 */
export const Strong: React.FC<React.PropsWithChildren> = props => {
	return <strong className={styles.strong}>{props.children}</strong>;
};

/**
 * Apply caption style to text.
 * @example
 * <Paragraph><Caption>This is some caption.</Caption></Paragraph>
 */
export const Caption: React.FC<React.PropsWithChildren> = props => {
	return <span className={styles.fontCaptionDefault}>{props.children}</span>;
};

type TextSpanProps = { testId?: string } & TextBlockAlignProps & TextStyleProps;

/**
 * General text <span> element for styling the text when it's not semantically appropriate to apply {@link Heading} or {@link Paragraph}.
 *
 * @example
 * <Table>
 *   <THead>
 *     <Th>
 *       <TextSpan style="body-bold">This is bold</TextSpan>
 */
export const TextSpan: React.FC<React.PropsWithChildren<TextSpanProps>> = props => {
	return (
		<span
			style={cssVariableForColor(props.color, "--typographic-element-color-prop-value")}
			className={mergeClassNames(
				props.style && textStylesToClassNameMapping[props.style],
				props.color && styles.useVariableColor,
			)}
			data-testid={props.testId}
		>
			{props.children}
		</span>
	);
};

export type HeadingProps = {
	children?: React.ReactNode;
	badgeProps?: Pick<BadgeProps, "color" | "name">;
	testId?: string;
} & MarginProps &
	TextBlockAlignProps &
	TextStyleProps;

/**
 * A general heading element. Don't pick h1~h6 yourself, wrap `Heading` in `<Section>` instead when you want to change heading level, and let the components calculate heading level for you.
 *
 * The example is a pattern you are very likely want to use in the root of a big component.
 * @example
 * <SG section>
 *     <Heading>Title</Heading>
 * </SG>
 * <Section>
 *     <Heading>Title</Heading>
 * </Section>
 * <Article resetHeadingLevelTo={1}>
 *     <Heading>Title</Heading>
 * </Article>
 */
export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
	style,
	color,
	children,
	badgeProps,
	marginAway,
	textCenter,
	testId,
}): JSX.Element => {
	const headingLevel = TypoGraphicElementInternals.useHeadingLevelContext();

	if (headingLevel === 0) {
		throw new RangeError(
			"<Heading> needs to have a <SG section>, <Section>, <Article>, or <HeadingLevel> as its ancestor. See the example of <Heading> component or report in #frontend-guild channel on Slack about this error.",
		);
	}

	const elementType = headingLevel > 6 ? "h6" : "h" + headingLevel;

	return (
		<span className={mergeClassNames(styles.headingWrapper, textCenter && styles.centerHeading)}>
			{React.createElement(
				elementType,
				{
					className: mergeClassNames(
						styles.headingHElement,
						styles.whiteSpacePreLine,
						textStylesToClassNameMapping[style || "heading-default"],
						// marginAway && marginAwayToClassNameMapping[marginAway],
						marginAway && styles.useVariableMarginBottom,
						color && styles.useVariableColor,
					),
					style: {
						...cssVariableForColor(color, "--typographic-element-color-prop-value"),
						...cssVariableForMargin(marginAway, "--typographic-element-margin-away-value"),
					},
					"data-testid": testId,
				},
				children,
			)}
			{badgeProps && <Badge noMargin {...badgeProps} />}
		</span>
	);
};
