import * as React from "react";
// eslint-disable-next-line no-restricted-imports
import { resolvePath, useLocation, useNavigate } from "react-router";

import { trackException } from "@bokio/utils/t";
import { getCurrentRoute, withCurrentReturnUrl } from "@bokio/utils/url";

import type { Location, To } from "react-router";

// Explicitly declared route state types.
// Prefer optional types so we don't have to explicitly declare everything when we only care about one of the props.
export interface BokioRouterLocationState {
	isRedirectedFromNotification?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	signUpSceneProps?: any;
	notFound?: boolean;
	error?: unknown;
}

export type RouterProps = {
	push: (path: To, state?: BokioRouterLocationState) => void;
	replace: (path: To, state?: BokioRouterLocationState) => void;
	goBack: () => void;
	goForward: () => void;
	location: Location<BokioRouterLocationState>;
	redirect: (path: To, state?: BokioRouterLocationState) => void;
	redirectHard: (path: To) => void;

	currentCompanyId: string | undefined;
	currentAgencyId: string | undefined;
};

/**
 * Tips for unit testing:
 * See the `history` prop from `renderApp(...)` for example of testing.
 */
export const useRouter = (): RouterProps => {
	const navigate = useNavigate();
	const location = useLocation();

	const routerRef = React.useRef({} as unknown as RouterProps);

	routerRef.current.goBack = React.useCallback(() => navigate(-1), [navigate]);

	routerRef.current.goForward = React.useCallback(() => navigate(1), [navigate]);

	routerRef.current.location = location;

	routerRef.current.push = React.useCallback(
		(to: To, state?: BokioRouterLocationState) =>
			navigate(to, {
				state,
			}),
		[navigate],
	);

	routerRef.current.redirect = React.useCallback(
		(to: To, state?: BokioRouterLocationState) => {
			const nextRoute = withCurrentReturnUrl(location, getCurrentRoute(resolvePath(to)));
			navigate(nextRoute, {
				state,
			});
		},
		[location, navigate],
	);

	routerRef.current.redirectHard = React.useCallback(
		(to: To) => {
			// SS 2025-02-20: Keeping the legacy behaviour where people previously passed a full URL to this function, and expected this function to link to somewhere outside of the web app.
			const isRedirectingToExternalLink = typeof to === "string" && /^(?:https?:)?\/\//i.test(to);
			window.location.href = isRedirectingToExternalLink
				? to
				: withCurrentReturnUrl(location, getCurrentRoute(resolvePath(to)));
		},
		[location],
	);

	routerRef.current.replace = React.useCallback(
		(to: To, state?: BokioRouterLocationState) => {
			try {
				navigate(to, {
					state,
					replace: true,
				});
			} catch (err) {
				trackException(err, "withRouterControls", { location });
			}
		},
		[location, navigate],
	);

	routerRef.current.currentCompanyId = location.pathname.match(
		/^\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/,
	)?.[1];
	routerRef.current.currentAgencyId = location.pathname.match(
		/^\/(?:backoffice|agencies)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/,
	)?.[1];

	return routerRef.current;
};
