import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "./Field/Field";
import { FormContext } from "./Form";
import LabelFor from "./LabelFor/LabelFor";

import type { InputFieldProps } from "@bokio/elements/Form/InputField";

import * as styles from "./form.scss";

export interface SelectFieldOption {
	hidden?: boolean;
	disabled?: boolean;
	value: string | number;
	label: string | number | React.ReactNode;
	className?: string;
	testId?: string;
}

export interface SelectFieldOptionGroup {
	label: string;
	options: SelectFieldOption[];
	disabled?: boolean;
}

interface SelectFieldProps extends Omit<InputFieldProps, "onClick"> {
	label?: string;
	hint?: string;
	options: (SelectFieldOption | SelectFieldOptionGroup)[];
	predefinedDefaultLabel?: string;
	showBlankDefault?: boolean;
	labelTestId?: string;
	onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
	mandatory?: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
	value,
	onChange,
	options,
	disabled,
	label,
	labelClassName,
	hint,
	errors,
	showBlankDefault,
	autoFocus,
	wrapperClassName,
	testId,
	predefinedDefaultLabel,
	labelTestId,
	tooltip,
	onClick,
	placeholder,
	mandatory = false,
}) => {
	const context = React.useContext(FormContext);

	const isOptionGroup = (option: SelectFieldOption | SelectFieldOptionGroup): option is SelectFieldOptionGroup => {
		return "options" in option;
	};

	const getAllOptions = (options: (SelectFieldOption | SelectFieldOptionGroup)[]): SelectFieldOption[] => {
		return options.reduce<SelectFieldOption[]>((acc, option) => {
			if (isOptionGroup(option)) {
				return [...acc, ...option.options];
			}
			return [...acc, option];
		}, []);
	};

	const renderOption = (option: SelectFieldOption, index: number) => (
		<option
			key={`${option.value}-${index}`}
			value={option.value}
			disabled={option.disabled}
			hidden={option.hidden}
			className={mergeClassNames(option.hidden && styles.hidden, option.disabled && styles.disabled, option.className)}
			data-testid={option.testId}
		>
			{option.label}
		</option>
	);

	const renderOptionOrGroup = (item: SelectFieldOption | SelectFieldOptionGroup, index: number) => {
		if (isOptionGroup(item)) {
			return (
				<optgroup key={`group-${index}`} label={item.label} disabled={item.disabled}>
					{item.options.map((option, optionIndex) => renderOption(option, optionIndex))}
				</optgroup>
			);
		}
		return renderOption(item, index);
	};

	const allOptions = getAllOptions(options);
	// MQ 2018-10-05
	// We need to set value to empty if it is not in options because select tag will choose first option as default value
	// MQ 2018-10-10
	// We has to use == because we might use not-string value for option or selected value,
	// select/option will give us back string, string coercion below solves our problem
	const selectedValue = allOptions.some(option => option.value == value) ? value : "";

	return (
		<Field className={wrapperClassName}>
			<LabelFor
				label={label}
				hint={hint}
				errors={errors && errors.errors}
				testId={labelTestId}
				tooltip={tooltip}
				className={labelClassName}
				mandatory={mandatory}
			>
				<div className={styles.selectWrapper}>
					<select
						data-testid={testId}
						autoFocus={autoFocus}
						onChange={e => onChange && onChange(e.currentTarget.value)}
						className={mergeClassNames(
							styles.field__input,
							styles.select,
							placeholder && !selectedValue && styles.placeholder,
						)}
						value={selectedValue}
						disabled={context.disableAllFields || disabled}
						onClick={onClick}
					>
						{showBlankDefault && <option key="empty" disabled hidden />}
						{placeholder && !showBlankDefault && (
							<option key="default" value="" hidden disabled>
								{placeholder}
							</option>
						)}
						{predefinedDefaultLabel && (
							<option key="default" hidden={true} disabled={true} value="">
								{predefinedDefaultLabel}
							</option>
						)}
						{options.map((item, index) => renderOptionOrGroup(item, index))}
					</select>
				</div>
			</LabelFor>
		</Field>
	);
};
