import * as React from "react";

import { BokioPayBankIdAuthModal } from "@bokio/components/BokioPayBankIdModal/BokioPayBankIdAuthModal";
import { useBankIdPolling } from "@bokio/components/SveaBankIdAuthModal/sveaPollingUtils";
import { Notice } from "@bokio/elements/Notice/Notice";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { apiPollingTimebasedStopCheck, useApiPolling, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
import BankIdHintCode = m.Bokio.Common.Contract.BankId.BankIdHintCode;

type BankIdError = m.Bokio.Common.Contract.BankId.BankIdError;
type LoginUserResult = m.Bokio.Services.Account.LoginUserResult;
type ConfirmBankIdLoginOrCreateResponse = m.Bokio.Backbone.Web.Controllers.Accounts.ConfirmBankIdLoginOrCreateResponse;

interface BankIdPollResult {
	BankIdStatus: m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
	HintCode?: m.Bokio.Common.Contract.BankId.BankIdHintCode;
}

function useBokioPayBankIdAuthGeneric<R extends BankIdPollResult>(
	startFn: () => Promise<m.Envelope<m.Bokio.Backbone.Web.Controllers.Accounts.StartBankIdLoginResponse, BankIdError>>,
	pollForUpdateFn: () => Promise<m.Envelope<R, BankIdError>>,
	onSuccess: (loginResult: R) => void,
) {
	const [autoStartToken, setAutoStartToken] = React.useState<string>();
	const [qrStartToken, setQrStartToken] = React.useState<string>();
	const [collectionStatus, setCollectionStatus] = React.useState<BankIdCollectionStatus>();
	const [hintCode, setHintCode] = React.useState<BankIdHintCode>();
	const [qrCode, setQrCode] = React.useState<string>();
	const [errorMessage, setErrorMessage] = React.useState<string>();
	const [hasError, setHasError] = React.useState(false);

	const qrCodePoller = useApiPolling(proxy.Bokio.BankIdController.QrCode.Get, {
		stopCondition: context =>
			apiPollingTimebasedStopCheck()(context) || context.error !== null || !context.data?.AnimatedQrCode || hasError,
		effect: (data, error) => {
			if (data) {
				setQrCode(data.AnimatedQrCode);
			}

			if (error && !hasError) {
				setHasError(true);
				setErrorMessage(error.UserVisibleMessage);
			}
		},
	});

	const { startPolling, stopPolling, isPolling } = useBankIdPolling("useBokioPayBankIdAuthGeneric", pollForUpdateFn, {
		isPending: data => !hasError && data.BankIdStatus == BankIdCollectionStatus.Pending,
		effect: data => {
			if (data) {
				setHintCode(data.HintCode);
				setCollectionStatus(data.BankIdStatus);

				if (data.BankIdStatus == BankIdCollectionStatus.Complete) {
					onSuccess(data);
					setErrorMessage(undefined);
				}
			}
		},
		onStopped: context => {
			if (context?.error && !hasError) {
				setHasError(true);
				setErrorMessage(context.error.UserVisibleMessage);
				stopPolling();
				qrCodePoller.stopPolling();
				setCollectionStatus(undefined);
				setHintCode(undefined);
				setQrCode(undefined);
			}
		},
	});

	const [start, startRequest] = useLazyApi(startFn, {
		onSuccess: response => {
			setHasError(false);
			setAutoStartToken(response.AutoStartToken);
			setQrStartToken(response.QrStartToken);
			startPolling([]);
		},
		onError: error => {
			setHasError(true);
			setErrorMessage(error.UserVisibleMessage);
		},
	});

	const [cancel] = useLazyApi(proxy.Bokio.BankIdController.Cancel.Post, {
		onError: error => {
			setErrorMessage(error.UserVisibleMessage);
		},
	});

	const closeBankIdModal = () => {
		// We stop all things on our side before cancelling the order in BankID to avoid sync issues
		// where we would try to poll again after BankID has cancelled the order but before we've gotten the response
		// from our backend
		stopPolling();
		qrCodePoller.stopPolling();

		setAutoStartToken(undefined);
		setQrStartToken(undefined);
		setQrCode(undefined);
		setHintCode(undefined);
		setCollectionStatus(undefined);

		cancel();
	};

	const startPollingForQrCode = () => {
		if (qrStartToken && !qrCodePoller.isPolling) {
			qrCodePoller.startPolling([qrStartToken]);
		}
	};

	const startAuth = () => {
		start();
	};

	const renderAuthModal = () => (
		<BokioPayBankIdAuthModal
			autoStartToken={autoStartToken}
			qrStartToken={qrStartToken}
			collectionStatus={collectionStatus}
			hintCode={hintCode}
			isPolling={isPolling}
			qrCode={qrCode}
			onStartPollingQrCode={startPollingForQrCode}
			onStopPollingQrCode={() => qrCodePoller.stopPolling()}
			onClose={closeBankIdModal}
		/>
	);

	const renderErrorMessage = () => {
		if (errorMessage) {
			return <Notice color="warning">{errorMessage}</Notice>;
		}

		return null;
	};

	return {
		startAuth,
		startAuthRequest: startRequest,
		isPolling,
		renderAuthModal,
		renderErrorMessage,
	};
}

export function useBokioPayBankIdAuth(
	startFn: () => Promise<m.Envelope<m.Bokio.Backbone.Web.Controllers.Accounts.StartBankIdLoginResponse, BankIdError>>,
	pollForUpdateFn: () => Promise<
		m.Envelope<m.Bokio.Backbone.Web.Controllers.Accounts.ConfirmBankIdLoginResponse, BankIdError>
	>,
	onSuccess: (loginResult: LoginUserResult) => void,
) {
	return useBokioPayBankIdAuthGeneric(startFn, pollForUpdateFn, r => onSuccess(r.SuccessfulLoginResult));
}

export function useCreateBokioPayBankIdAuth(
	onSuccess: (loginOrCreateResult: ConfirmBankIdLoginOrCreateResponse) => void,
	returnUrl: string | undefined,
	referral: m.Entities.UserReferralSource | undefined,
	acquisitionCampaign: m.Entities.AcquisitionCampaignData | undefined,
	voucherCode: string | undefined,
) {
	return useBokioPayBankIdAuthGeneric(
		() => proxy.Bokio.AccountController.StartBankIdLoginOrCreate.Post(),
		() =>
			proxy.Bokio.AccountController.ConfirmBankIdLoginOrCreate.Post(
				returnUrl,
				referral,
				acquisitionCampaign,
				voucherCode,
			),
		onSuccess,
	);
}

export function useLoginBokioPayBankIdAuth(email: string, onSuccess: (loginResult: LoginUserResult) => void) {
	return useBokioPayBankIdAuth(
		() => proxy.Bokio.AccountController.StartBankIdLogin.Post(email),
		() => proxy.Bokio.AccountController.ConfirmBankIdLogin.Post(email),
		onSuccess,
	);
}

export function useActivateBokioPayBankIdAuth(password: string, totpCode: string, onSuccess: () => void) {
	return useBokioPayBankIdAuth(
		() =>
			proxy.Settings.BokioPayBankIdAuthSettingsController.StartEnableBokioPayBankIdAuth.Post({
				Password: { Value: password },
				TotpCode: { Value: totpCode },
			}),
		() => proxy.Settings.BokioPayBankIdAuthSettingsController.ConfirmEnableBokioPayBankIdAuth.Post(),
		onSuccess,
	);
}

export function useDeactivateBokioPayBankIdAuth(password: string, totpCode: string, onSuccess: () => void) {
	return useBokioPayBankIdAuth(
		() =>
			proxy.Settings.BokioPayBankIdAuthSettingsController.StartDisableBokioPayBankIdAuth.Post({
				Password: { Value: password },
				TotpCode: { Value: totpCode },
			}),
		() => proxy.Settings.BokioPayBankIdAuthSettingsController.ConfirmDisableBokioPayBankIdAuth.Post(),
		onSuccess,
	);
}
