import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { BokioBusinessAccountButton } from "@bokio/elements/BokioBusinessAccountButton/BokioBusinessAccountButton";

import { useBokioPlanFeatureElement } from "./useBokioPlanFeatureElement";

import type { BokioPlanFeatureElementProps } from "./useBokioPlanFeatureElement";
import type { BokioBusinessAccountButtonProps } from "@bokio/elements/BokioBusinessAccountButton/BokioBusinessAccountButton";

type Props = BokioBusinessAccountButtonProps & BokioPlanFeatureElementProps;

export const BokioPlanBokioBusinessAccountButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
	(props, ref) => {
		const { renderButton } = useBokioPlanFeatureElement({
			featureButtonProps: props,
			lockButtonProps: props,
			lockButtonRef: ref,
			renderActualButton: () => <BokioBusinessAccountButton {...props} />,
			renderLockedButton: lockedProps => <Button {...lockedProps} type="button" />,
		});

		return renderButton();
	},
);
