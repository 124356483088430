import type { Accept } from "react-dropzone";

export type FileUploaderFileType =
	| "bmp"
	| "csv"
	| "gif"
	| "heic"
	| "jpg"
	| "pdf"
	| "png"
	| "se"
	| "si"
	| "tsv"
	| "webp"
	| "xls"
	| "xlsx";

/**
 * MIME type workaround used for cases where there isn't really a corresponding MIME type,
 * which is a flaw in the current design of file picker API,
 * and `react-dropzone` is following file picker API's design.
 * https://github.com/WICG/file-system-access/issues/444
 *
 * Empty mime type string will not add mime type into the `accept` attribute of the rendered file input element.
 */
const INVALID_MIME_TYPE = "" as const;

/**
 * Map from known file extension to the `accept` entry that will be accepted by `react-dropzone` and `window.showOpenFilePicker()`.
 *
 * Note that it's "or condition" in this API, for example, accepting `image/*` and `.jpg` will actually allow all images.
 */
const extensionToAcceptEntryMapping: Record<
	FileUploaderFileType,
	[
		// MIME type
		`${"application" | "image" | "text"}/${string}` | typeof INVALID_MIME_TYPE,
		// corresponding file extensions
		`.${string}`[],
	]
> = {
	bmp: ["image/bmp", [".bmp"]],
	csv: ["text/csv", [".csv"]],
	gif: ["image/gif", [".gif"]],
	heic: ["image/heic", [".heic"]],
	jpg: ["image/jpeg", [".jpg", ".jpeg"]],
	pdf: ["application/pdf", [".pdf"]],
	png: ["image/png", [".png"]],
	se: [INVALID_MIME_TYPE, [".se"]],
	si: [INVALID_MIME_TYPE, [".si"]],
	tsv: ["text/tab-separated-values", [".tsv"]],
	webp: ["image/webp", [".webp"]],
	xls: ["application/vnd.ms-excel", [".xls"]],
	xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", [".xlsx"]],
	// Use `file --mime-type` to get the mime type of a file if you are adding a new file type and unsure what to write there.
	//
	// Please double check in the browser to make sure the MIME type doesn't add file extensions that shouldn't be accepted.
	// For example:
	// `application/octet-stream` will add `.com` and `.exe` automatically to the file picker,
	// and `text/plain` will add `.txt` and `.text` automatically.
	// If you don't want such behaviour, use `INVALID_MIME_TYPE` as the MIME type.
	// This is also the reason why for `.se` and `.si` files we use `INVALID_MIME_TYPE` instead of `text/plain`.
};

export const toDropZoneAccept = (acceptedFileTypes: FileUploaderFileType[]): Accept => {
	return acceptedFileTypes.reduce((result, acceptedFileType) => {
		const [mimeType, extensions] = extensionToAcceptEntryMapping[acceptedFileType];
		result[mimeType] ??= [];
		result[mimeType] = result[mimeType].concat(extensions);
		return result;
	}, {} satisfies Accept);
};
