import { BankLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import type { getWizardStep } from "@bokio/mobile-web-shared/areas/bank/utils/bokioBusinessAccountUtils";

export const getOnboardingConnectableAccounts = (
	accountSuggestion: m.Bokio.Bank.Contract.MoneyAccount.BankFeedMoneyAccountSuggestionDto,
) =>
	accountSuggestion.ConnectableAccounts.filter(ec => accountSuggestion.AccountsInUse[ec.Account] === undefined).slice(
		0,
		// We return maximum two connectable accounts to keep the selection simple and the functionality
		// in a way that it was before refactoring how connectable accounts are configured per external system
		2,
	);

export const getMirBankAndAccountFromConnectedBanks = (
	connectedBanksRequestData: m.Bokio.Bank.Contract.Dtos.BanksDto | undefined,
) => {
	const mirBank = connectedBanksRequestData?.Banks.find(b => b.ExternalSystem === m.Entities.Bank.ExternalSystem.Mir);
	const mirAccount = mirBank?.Accounts[0];

	return { mirBank, mirAccount };
};

export const getSveaBankAndAccountFromConnectedBanks = (
	connectedBanksRequestData: m.Bokio.Bank.Contract.Dtos.BanksDto | undefined,
) => {
	const sveaBank = connectedBanksRequestData?.Banks.find(b => b.ExternalSystem === m.Entities.Bank.ExternalSystem.Svea);
	const sveaAccount = sveaBank?.Accounts[0];

	return { sveaBank, sveaAccount };
};

export const getWizardStepName = (step: ReturnType<typeof getWizardStep>) => {
	const bankLang = BankLangFactory();

	switch (step) {
		case "0":
			return bankLang.MirOnboarding_ReviewDetails_StepName;
		case "1":
			return bankLang.MirOnboarding_ConfirmAndSign_StepName;
		case "2":
			return bankLang.MirOnboarding_AdditionalQuestionsKYC_StepName;
		case "3":
			return bankLang.ConnectAccount;
		case "4":
			return bankLang.OrderCard;
		case undefined:
			return "";
	}
};
