import * as React from "react";

import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { useCompanyUser, useUser } from "@bokio/shared/state/requests";
import { setUserId, setUserIdAndDimensions, trackPageView } from "@bokio/utils/t";

import { useScript } from "../useScript/useScript";

export const useGoogleAnalyticsScript: (onGALoaded?: () => void) => void = onGALoaded => {
	const { location } = useRouter();
	const { company } = useCompanyUser();
	const { user } = useUser();

	// Google Analytics 4
	useScript(`https://www.googletagmanager.com/gtag/js?id=${window.config.ga.measurementId}`);
	const gtagScriptRequest = useScript("scripts/gtag.js");
	const isScriptsReady = gtagScriptRequest.data;

	React.useEffect(() => {
		if (!isScriptsReady) {
			return;
		}

		trackPageView(location.pathname, location.search);
	}, [
		isScriptsReady,
		// SS 2024-10-31
		// Here I use location instead of location.pathname and location.search
		// because in the previous behavior in React Router v5 history.listen,
		// we triggered the the tracking event on each locaiton change ignoring if the URL changed or not,
		// so I keep the same behavior as before here.
		location,
	]);

	React.useEffect(() => {
		if (!isScriptsReady) {
			return;
		}

		onGALoaded?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isScriptsReady]);

	React.useEffect(() => {
		if (!isScriptsReady) {
			return;
		}

		if (company && user) {
			setUserIdAndDimensions(company, user.Id);
		}

		if (user) {
			user && setUserId(user.Id);
		}
	}, [isScriptsReady, company, user]);
};
