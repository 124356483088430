import * as React from "react";
import { v1 as uuidv1 } from "uuid";

import { Validation } from "@bokio/elements/Form";
import { mergeClassNames } from "@bokio/utils/classes";

import type { FieldValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./checkBox.scss";

export interface CheckBoxProps {
	label?: React.ReactNode;
	id?: string;
	checked?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	className?: string;
	rightMargin?: boolean; // This should be controlled by the parent not here
	errors?: FieldValidationResult;
	testId?: string;
	/**
	 * Partial should be used in cases where we have a group checkbox and some of the children are checked.
	 * "checked" must be true for this to work
	 */
	partial?: boolean;
}

export const CheckBox = (props: CheckBoxProps) => {
	const { label, errors, className } = props;

	const [uniqueId] = React.useState(() => props.id || uuidv1());

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(!props.checked);
		}
		e.stopPropagation();
	};

	const renderInput = (className?: string) => {
		const { rightMargin, testId, checked, partial, disabled, id } = props;

		return (
			<input
				data-testid={testId}
				disabled={disabled}
				type="checkbox"
				onChange={onChange}
				className={mergeClassNames(
					styles.checkBox,
					className,
					rightMargin && styles.rightMargin,
					checked && partial && styles.partial,
				)}
				checked={!!checked}
				onClick={e => e.stopPropagation()}
				id={id ?? uniqueId}
			/>
		);
	};

	return (
		<React.Fragment>
			{label ? (
				<label htmlFor={uniqueId} className={mergeClassNames(styles.label, className)}>
					{renderInput()}
					{label}
					<Validation errors={errors && errors.errors} />
				</label>
			) : (
				renderInput(className)
			)}
		</React.Fragment>
	);
};
