import { Navigate } from "react-router";

import { AccountantDetailsModal } from "@bokio/backoffice/src/components/AccountantDetailsModal/AccountantDetailsModal";
import { backOfficeContext } from "@bokio/backoffice/src/utils/BackOfficeContext";
import Layout from "@bokio/components/Layout/Layout";
import Sidebar from "@bokio/components/Sidebar/Sidebar";
import TopBar, { TopBarMode } from "@bokio/components/TopBar/TopBar";
import { Box } from "@bokio/elements/Box";
import { LoadingBox } from "@bokio/elements/Loading";
import BackOfficeLangFactory from "@bokio/lang/BackOfficeLangFactory";
import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";
import { useUser } from "@bokio/shared/state/requests";
import { withBooleanFilter } from "@bokio/utils/arrayUtils";
import { useParamsV5 } from "@bokio/utils/reactRouterCompatibility";

import type { BackOfficeContext } from "@bokio/backoffice/src/utils/BackOfficeContext";
import type { MenuSection, MenuSub } from "@bokio/components/Menu/utils/MenuHelper";
import type { ReactNode } from "react";

const BackOfficeContent = ({ partnerId, children }: { partnerId: string; children?: ReactNode }) => {
	const getUser = useUser();

	const user = getUser.user;
	if (!user) {
		return (
			<Box>
				<LoadingBox />
			</Box>
		);
	}

	if (!user.FirstName || !user.LastName) {
		return <AccountantDetailsModal />;
	}

	if (!children) {
		const defaultRoute = getRoute("backOfficeAdminAgencies", { partner: partnerId });
		return <Navigate to={defaultRoute} replace />;
	}

	return children;
};

export const getMenuSections = (context: BackOfficeContext) => {
	const backOfficeLang = BackOfficeLangFactory();
	const access = context.partner.Access;
	const withPartner = { partner: context.partner.Id };
	return withBooleanFilter<MenuSection>([
		access.ManageUsers && {
			icon: "group",
			title: backOfficeLang.Scene_Users_Title,
			route: getRoute("backOfficeUsers", withPartner),
			subs: withBooleanFilter<MenuSub>([]),
			accessRequirements: [],
		},
		context.partner.IsRoot &&
			access.ManageCompanies && {
				icon: "accounting-firm",
				title: backOfficeLang.Partners,
				route: getRoute("backOfficeAdminAgencies", { ...withPartner }),
				subs: [],
				accessRequirements: [],
			},
	]);
};

export const BackOfficeRoute = ({ children }: { children?: ReactNode }) => {
	const params = useParamsV5<{ partner: string }>();

	const [partnerInfoRequest] = useApi(proxy.BackOffice.PartnerViewerController.AgencyStatus.Get, [params.partner]);

	const context: BackOfficeContext | undefined = partnerInfoRequest.data?.Data
		? {
				partner: partnerInfoRequest.data?.Data,
			}
		: undefined;

	return (
		<Layout
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.BackOffice} toggleMenu={menuProps.toggleMenu} />
					<Sidebar
						expanded={menuProps.expanded}
						onToggle={menuProps.toggleMenu}
						onClose={menuProps.closeMenu}
						sections={(context && getMenuSections(context)) || []}
						showBookkeepingButton={false}
					/>
				</div>
			)}
			content={
				context && (
					<backOfficeContext.Provider value={context}>
						<BackOfficeContent partnerId={params.partner}>{children}</BackOfficeContent>
					</backOfficeContext.Provider>
				)
			}
		/>
	);
};
