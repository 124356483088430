import * as m from "@bokio/mobile-web-shared/core/model/model";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";
import memoize from "@bokio/utils/memoize";

import type { PaletteCommand, PaletteCommandSource, PaletteContext } from "./commandPalette.types";
import type { MenuSection, MenuSub } from "@bokio/components/Menu/utils/MenuHelper";
import type { GeneralLang } from "@bokio/lang/GeneralLangFactory";
import type {
	SettingsBuilderSection,
	SettingsItem,
} from "@bokio/settings/src/scenes/Overview/components/utils/settingsHelper";

export function pushSource(old: PaletteContext, newSource: PaletteCommandSource): PaletteContext {
	return { stack: [newSource, ...old.stack] };
}

export function popSource(old: PaletteContext): PaletteContext {
	return { stack: old.stack.slice(1) };
}

function jaro(s1: string, s2: string): number {
	if (s1 === s2) {
		return 1;
	}

	const len1 = s1.length;
	const len2 = s2.length;
	const maxDist = Math.floor(Math.max(len1, len2) / 2) - 1;
	let match = 0;
	const hashS1 = new Array(len1);
	const hashS2 = new Array(len2);

	for (let i = 0; i < len1; i++) {
		for (let j = Math.max(0, i - maxDist); j < Math.min(len2, i + maxDist + 1); j++) {
			if (s1[i] === s2[j] && !hashS2[j]) {
				hashS1[i] = hashS2[j] = 1;
				match++;
				break;
			}
		}
	}

	if (match === 0) {
		return 0;
	}

	let transpositions = 0;
	let point = 0;

	for (let i = 0; i < len1; i++) {
		if (hashS1[i]) {
			while (hashS2[point] === 0 && point < len2) {
				point++;
			}
			if (s1[i] !== s2[point++]) {
				transpositions++;
			}
		}
	}

	transpositions = transpositions / 2;

	return (match / len1 + match / len2 + (match - transpositions) / match) / 3.0;
}

// This calculates the similarity between two strings using the Jaro-Winkler distance as a number between 0 and 1.
// https://en.wikipedia.org/wiki/Jaro%E2%80%93Winkler_distance
function jaroWinkler(s1: string, s2: string): number {
	const jaroDistance = jaro(s1, s2);
	if (jaroDistance > 0.7) {
		let prefix = 0;
		for (let i = 0; i < Math.min(s1.length, s2.length); i++) {
			if (s1[i] === s2[i]) {
				prefix++;
			} else {
				break;
			}
		}
		prefix = Math.min(4, prefix);
		return jaroDistance + 0.1 * prefix * (1 - jaroDistance);
	} else {
		return jaroDistance;
	}
}

const memoizedJaroWinkler = memoize(jaroWinkler);

const MAX_SEARCH_RESULT_COUNT = 10;
export function matchCommands(search: string, cmds: PaletteCommand[]): PaletteCommand[] {
	return cmds
		.sort(
			(a, b) =>
				Math.max(
					memoizedJaroWinkler((b.name as string).toLowerCase(), search.toLocaleLowerCase()),
					...b.aliases.map(alias => memoizedJaroWinkler(alias.toLowerCase(), search.toLocaleLowerCase())),
				) -
				Math.max(
					memoizedJaroWinkler((a.name as string).toLowerCase(), search.toLocaleLowerCase()),
					...a.aliases.map(alias => memoizedJaroWinkler(alias.toLowerCase(), search.toLocaleLowerCase())),
				),
		)
		.slice(0, MAX_SEARCH_RESULT_COUNT);
}

function menuSubToPaletteCommand(generalLang: GeneralLang, subs: MenuSub[]): PaletteCommand[] {
	const cmds: PaletteCommand[] = [];
	for (let i = 0; i < subs.length; i++) {
		const sub = subs[i];
		if (sub.route && !sub.hidden) {
			cmds.push({
				kind: "route",
				name: sub.title,
				aliases: [],
				route: sub.route,
				prefix: generalLang.CommandSearch_GoTo_Prefix,
			});
		}
	}
	return cmds;
}

export function menuSectionsToPaletteCommand(generalLang: GeneralLang, sections: MenuSection[]): PaletteCommand[] {
	const commands = sections
		.filter(s => !s.isDivider)
		.flatMap(s =>
			s.subs.length
				? menuSubToPaletteCommand(generalLang, s.subs)
				: [
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							kind: "route",
							name: s.title,
							route: s.route,
							aliases: [],
							prefix: generalLang.CommandSearch_GoTo_Prefix,
						} as PaletteCommand,
					],
		);

	return commands;
}

function settingsItemToPaletteCommand(generalLang: GeneralLang, items: SettingsItem[]): PaletteCommand[] {
	const cmds: PaletteCommand[] = [];
	for (let i = 0; i < items.length; i++) {
		const item = items[i];
		if (item.route) {
			cmds.push({
				kind: "route",
				name: item.title,
				aliases: item.aliases,
				route: item.route,
				prefix: `${generalLang.CommandSearch_GoTo_Prefix} ${generalLang.Settings} >`,
			});
		}
	}
	return cmds;
}

export function settingsSectionsToPaletteCommand(
	generalLang: GeneralLang,
	sections: SettingsBuilderSection[],
): PaletteCommand[] {
	const commands = sections.flatMap(s => (s.items.length ? settingsItemToPaletteCommand(generalLang, s.items) : []));

	return commands;
}

export function commandsAsCommandSource(placeholder: string, commands: PaletteCommand[]): PaletteCommandSource {
	return { placeholder, search: s => Promise.resolve(matchCommands(s, commands)) };
}
export const bookkeepWithTemplateCommandSource = (
	generalLang: GeneralLang,
	companyId: string,
): PaletteCommandSource => ({
	placeholder: generalLang.CompanyCommandPalette_BookkeepWithTemplate,
	search: async (searchParam: string) => {
		if (searchParam.length < 2) {
			return [];
		}
		const templates = await proxy.Accounting.RegisterRecipeController.Search.Get(companyId, searchParam)
			.catch(() => ({ Data: { Matches: [] } }))
			.then(e => e.Data?.Matches || []);

		const matchingTemplateCommands = templates.slice(0, MAX_SEARCH_RESULT_COUNT).map(t => {
			const command: PaletteCommand = {
				kind: "route",
				route: getRoute("bookkeepReceipt", { company: companyId, category: "other" }, { templateId: t.Id }),
				name: t.FixedName,
				aliases: [],
				prefix: generalLang.CommandSearch_GoTo_Prefix,
			};
			return command;
		});

		return matchingTemplateCommands;
	},
});

export const invoiceSearchCommandSource = (generalLang: GeneralLang, companyId: string): PaletteCommandSource => ({
	placeholder: generalLang.CommandSearch_SearchInvoices,
	search: async (searchParam: string) => {
		if (searchParam.length < 2) {
			return [];
		}
		const invoices = await proxy.Invoices.InvoiceController.All.Get(companyId, {
			Filters: `SearchTerm@=*${encodeURIComponent(searchParam)}`,
			PageSize: MAX_SEARCH_RESULT_COUNT,
			PageNumber: 1,
		})
			.catch(() => ({ Data: [] }))
			.then(e => e.Data || []);

		const matchingTemplateCommands = invoices.map(i => {
			const command: PaletteCommand = {
				kind: "route",
				route: getRoute("invoicesShow", { company: companyId, invoiceId: i.Id }),
				name: `${generalLang.Invoice} ${i.InvoiceNumberWithPrefix} — ${
					i.CustomerName
				} — ${i.InvoiceDate.toISOString()} — ${i.AmountSEK}`,
				aliases: [],
				prefix: generalLang.CommandSearch_GoTo_Prefix,
			};
			return command;
		});
		return matchingTemplateCommands || [];
	},
});

export const supplierInvoiceSearchCommandSource = (
	generalLang: GeneralLang,
	companyId: string,
): PaletteCommandSource => ({
	placeholder: generalLang.CommandSearch_SearchSupplierInvoices,
	search: async (searchParam: string) => {
		if (searchParam.length < 2) {
			return [];
		}
		const supplierInvoices = await proxy.Accounting.SuppliersController.GetSupplierInvoicesForList.Get(companyId, {
			Filter: {
				SearchTerm: searchParam,
				Tab: m.Bokio.Accounting.Contract.SupplierInvoices.Requests.SupplierInvoiceListTab.All,
			},
			Pagination: {
				PageSize: MAX_SEARCH_RESULT_COUNT,
				PageNumber: 1,
			},
			Sort: {
				Direction: m.Bokio.Common.Contract.Utils.SortDirection.Desc,
				Property: m.Bokio.Accounting.Contract.SupplierInvoices.Requests.SupplierInvoiceSortableProperties.Deadline,
			},
		})
			.catch(() => ({ Data: { Invoices: [] } }))
			.then(e => e.Data?.Invoices || []);

		const matchingTemplateCommands = supplierInvoices.map(i => {
			const command: PaletteCommand = {
				kind: "route",
				route: getRoute("supplierInvoice", { company: companyId, invoiceId: i.Id }),
				name: `${i.ReferenceNumber ? i.ReferenceNumber + " — " : ""}${i.Title} — ${i.Deadline.toISOString()} — ${
					i.Sum
				}`,
				aliases: [],
				prefix: generalLang.CommandSearch_GoTo_Prefix,
			};
			return command;
		});

		return matchingTemplateCommands || [];
	},
});
