import { Children, cloneElement, useCallback, useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router";

import { getMarginClassName } from "@bokio/designsystem/components/Button/button.helper";
import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type { Margin } from "@bokio/designsystem/components/Button/button.types";

import * as styles from "./splitButton.scss";

interface SplitButtonRenderHandle {
	onClose: () => void;
}

export interface SplitButtonProps {
	primaryText: string;
	testId?: string;
	dropDownTriggerTestId?: string;
	/**
	 * EE: 2025-03-21
	 * The type HTMLButtonElement here should be HTMLAnchorElement when the event comes from a child SplitButtonItem but that doesn't
	 * match with the type of onClickSecondary. So let's keep it as HTMLButtonElement for now.
	 */
	onClick?: (e: React.MouseEvent<HTMLButtonElement>, value: string) => void;
	onClickPrimary?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	onClickSecondary?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	target?: "_self" | "_blank" | "_parent" | "_top";
	className?: string;
	/** Will disable both the primary and secondary  */
	disabled?: boolean;
	/** Will disable only primary and secondary  */
	primaryDisabled?: boolean;
	icon?: FontelloIcons;
	isIconLeftAlign?: boolean;
	innerRef?: (element: HTMLDivElement) => void;
	noIconLeft?: boolean;
	/**
	 * NE 2019-06-26
	 * Optional render prop for rendering content
	 * that can trigger events, like closing the menu.
	 */
	menuContent?: (handle: SplitButtonRenderHandle) => JSX.Element;
	stretch?: boolean;
	primaryRoute?: string;
	margin?: Margin;
	/**
	 * Render as a single button that only behaves as the "split". Used in the bookkeeping menu for example
	 */
	onlyDropdownMode?: boolean;
	appearance?: "primaryDark" | "secondary" | "primary" | "destructive";
	children?: React.ReactNode;
}

export const SplitButton = ({
	primaryText,
	testId,
	dropDownTriggerTestId,
	onClick,
	onClickPrimary,
	onClickSecondary,
	target,
	className,
	disabled,
	primaryDisabled,
	icon,
	isIconLeftAlign,
	innerRef,
	noIconLeft,
	menuContent,
	stretch,
	primaryRoute,
	margin,
	onlyDropdownMode,
	appearance,
	children,
}: SplitButtonProps) => {
	const [showDropDown, setShowDropDown] = useState(false);
	const secondaryRef = useRef<HTMLButtonElement | null>(null);
	const clickedInsideRef = useRef(false);
	const clickTimeoutRef = useRef<number | undefined>();

	useEffect(() => {
		return () => {
			clearTimeout(clickTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		if (disabled && showDropDown) {
			setShowDropDown(false);
		}
	}, [disabled, showDropDown]);

	const handleContainerMouseDown = () => {
		clickedInsideRef.current = true;
		clickTimeoutRef.current = window.setTimeout(() => {
			clickedInsideRef.current = false;
		}, 0);
	};

	const toggleDropDown = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			onClickSecondary && onClickSecondary(e);
			(Children.count(children) || menuContent) && setShowDropDown(prev => !prev);
		},
		[onClickSecondary, children, menuContent],
	);

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>, value: string) => {
			onClick && onClick(e, value);
			toggleDropDown(e);
		},
		[onClick, toggleDropDown],
	);

	const handleBlur = () => {
		setShowDropDown(clickedInsideRef.current);
		if (clickedInsideRef.current && secondaryRef.current) {
			secondaryRef.current.focus();
		}
	};

	const renderButton = () => {
		const props = {
			disabled: disabled || primaryDisabled,
			className: styles.primary,
		};

		const textAndIcon = (
			<>
				{icon && isIconLeftAlign && <Icon className={styles.iconAlignLeft} name={icon} size="20" />}
				<span>{primaryText}</span>
				{icon && !isIconLeftAlign && <Icon className={styles.icon} name={icon} size="20" />}
			</>
		);

		if (primaryRoute && !props.disabled) {
			return (
				<RouterLink
					to={primaryRoute}
					data-testid={testId}
					onClick={onClickPrimary}
					className={props.className}
					target={target}
				>
					{textAndIcon}
				</RouterLink>
			);
		} else {
			return (
				<button data-testid={testId} {...props} onClick={onClickPrimary} type="button">
					{textAndIcon}
				</button>
			);
		}
	};

	const renderDropDownModeButton = () => {
		const ficonShown = showDropDown ? " ficon-up-open-big" : " ficon-down-open-big";
		const textAndIcon = (
			<>
				{icon && isIconLeftAlign && <Icon className={styles.iconAlignLeft} name={icon} />}
				<span>{primaryText}</span>
				{icon && !isIconLeftAlign && <Icon className={styles.icon} name={icon} />}
			</>
		);

		const buttonClassName = mergeClassNames(
			styles.dropdownOnly,
			ficonShown,
			className,
			noIconLeft && styles.noIconLeft,
			appearance === "primaryDark" && styles.primaryDark,
			appearance === "destructive" && styles.destructive,
			appearance === "secondary" && styles.secondary,
		);

		return (
			<button
				data-testid={dropDownTriggerTestId || "SplitButton_Trigger"}
				disabled={disabled}
				ref={secondaryRef}
				className={buttonClassName}
				onClick={toggleDropDown}
				onBlur={handleBlur}
				type="button"
			>
				{textAndIcon}
			</button>
		);
	};

	const renderChildren = useCallback(
		(children: React.ReactNode) =>
			Children.map(children, child => {
				const c = child as JSX.Element;
				if (!c) {
					return null;
				}
				if (c.props.onClick) {
					return cloneElement(c);
				}

				return cloneElement(c, {
					...c.props,
					onClick: handleClick,
				});
			}),
		[handleClick],
	);

	const ficonShown = showDropDown ? " ficon-up-open-big" : " ficon-down-open-big";

	return (
		<div
			className={mergeClassNames(className, stretch && styles.stretch, margin && getMarginClassName(margin))}
			ref={innerRef}
		>
			<div className={styles.buttonContainer}>
				{!onlyDropdownMode && (
					<>
						{renderButton()}
						<button
							data-testid={dropDownTriggerTestId || "SplitButton_Trigger"}
							disabled={disabled}
							ref={secondaryRef}
							className={styles.secondary + ficonShown}
							onClick={toggleDropDown}
							onBlur={handleBlur}
							type="button"
						/>
					</>
				)}
				{onlyDropdownMode && <>{renderDropDownModeButton()}</>}
			</div>
			<div className={styles.dropDownContainer}>
				<div className={showDropDown ? styles.dropDown : ""} onMouseDown={handleContainerMouseDown}>
					{showDropDown && (
						<>
							{renderChildren(children)}
							{menuContent && menuContent({ onClose: () => setShowDropDown(prev => !prev) })}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
