import { useEffect, useRef } from "react";
import { Navigate, Route, Routes, useNavigationType } from "react-router";

import ErrorBoundary from "@bokio/components/ErrorBoundary/ErrorBoundary";
import GenericNotFound from "@bokio/components/GenericNotFound/GenericNotFound";
import { LookForDeepLink } from "@bokio/components/LookForDeepLink/LookForDeepLink";
import { useRouter } from "@bokio/shared/containers/router/useRouter";

import { Scenes } from "./Scenes";

import type { BokioRouterLocationState } from "@bokio/shared/containers/router/useRouter";
import type * as React from "react";

export const AppRoutes: React.FC = () => {
	const { location } = useRouter();
	const navigationType = useNavigationType();
	const previousLocation = useRef(location);

	useEffect(() => {
		if (previousLocation.current.pathname !== location.pathname) {
			previousLocation.current = location;
		}

		if (navigationType !== "POP" && (!location.state || !location.state.error)) {
			previousLocation.current = location;
		}
	}, [location, navigationType]);

	const isError = !!(location.state && location.state.notFound && previousLocation.current !== location); // not initial render
	if (isError) {
		return <GenericNotFound />;
	}

	const notFoundState: BokioRouterLocationState = { notFound: true };

	return (
		<ErrorBoundary source="Route">
			<Routes>
				{Scenes().props.children}
				<Route
					path="*"
					element={<LookForDeepLink location={location} notFound={<Navigate to="" replace state={notFoundState} />} />}
				/>
			</Routes>
		</ErrorBoundary>
	);
};
