import { mergeClassNames } from "@bokio/utils/classes";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type * as React from "react";

import * as styles from "./style.scss";

export type IconColor =
	| "normal"
	| "white"
	| "green"
	| "red"
	| "blue"
	| "yellow"
	| "lightGrey"
	| "infoGrey"
	| "grey"
	| "darkGrey"
	| "warning"
	| "burgundy";

const iconSizes = {
	12: "12px",
	14: "14px",
	16: "16px",
	18: "18px",
	20: "20px",
	22: "22px",
	24: "24px",
	28: "28px",
	32: "32px",
	40: "40px",
	48: "48px",
	60: "60px",
};

export const iconSizeArray = Object.keys(iconSizes);
export type IconSize = `${keyof typeof iconSizes}`;

// @TODO make a better solution for colors? Something that can take in our colors as they are used everywhere instead of this?
// There is a lot of icons using custom css when the color is not available here instead of sending it as the color prop.
export const iconColorMap: Record<IconColor, string | undefined> = {
	normal: undefined,
	white: styles.whiteIcon,
	green: styles.greenIcon,
	red: styles.redIcon,
	blue: styles.blueIcon,
	yellow: styles.yellowIcon,
	lightGrey: styles.lightGreyIcon,
	infoGrey: styles.infoGreyIcon,
	grey: styles.greyIcon,
	darkGrey: styles.darkGreyIcon,
	warning: styles.warningIcon,
	burgundy: styles.burgundyIcon,
};

export interface IconProps {
	name: FontelloIcons;
	title?: string;
	color?: IconColor;
	size?: IconSize;
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	testId?: string;
	noMargin?: boolean;
}

const Icon: React.FC<IconProps> = props => {
	const { name, size, className, color, title, testId, onClick, noMargin } = props;
	const iconClassName = `ficon-${name}`;
	const colorClassName = iconColorMap[color || ""];

	return (
		<i
			className={mergeClassNames(
				iconClassName,
				colorClassName,
				className,
				noMargin && styles.noMargin,
				onClick && styles.clickHandler,
			)}
			title={title}
			data-testid={testId}
			style={size && { fontSize: iconSizes[size] }}
			onClick={onClick}
		/>
	);
};

export default Icon;
