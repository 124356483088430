import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router";

import LoginModal from "@bokio/components/AuthenticationChecker/LoginModal";
import { FloatingTutorial } from "@bokio/components/BookkeepingSchool/FloatingTutorial/FloatingTutorial";
import { ContactSupportLink } from "@bokio/components/ContactSupportLink/ContactSupportLink";
import ErrorBoundary from "@bokio/components/ErrorBoundary/ErrorBoundary";
import { GoogleAnalytics } from "@bokio/components/GoogleAnalytics/GoogleAnalytics";
import { GoogleAnalyticsConsent } from "@bokio/components/GoogleAnalytics/GoogleAnalyticsConsent";
import { LinkedinAds } from "@bokio/components/LinkedinAds/LinkedinAds";
import { MetaPixel } from "@bokio/components/MetaPixel/MetaPixel";
import { MicrosoftAds } from "@bokio/components/MicrosoftAds/MicrosoftAds";
import { SveaBankIdAuthModal } from "@bokio/components/SveaBankIdAuthModal/SveaBankIdAuthModal";
import { SveaKycReminderModal } from "@bokio/components/SveaKyc/SveaKycReminderModal/SveaKycReminderModal";
import { TaboolaPixel } from "@bokio/components/TaboolaPixel/TaboolaPixel";
import { VerveTracking } from "@bokio/components/VerveTracking";
import { AppContext, AppMessageType, generateMessageKey } from "@bokio/contexts/AppContext/AppContext";
import { BankFeedActivityProvider } from "@bokio/contexts/BankFeedActivityContext/BankFeedActivityProvider";
import { CashbackContextProvider } from "@bokio/contexts/CashbackContext/CashbackContextProvider";
import { CompanyInfoContextProvider } from "@bokio/contexts/CompanyInfoContext/CompanyInfoContextProvider";
import { EmailDeliveryProvider } from "@bokio/contexts/EmailDeliveryContext";
import { HelpContextProvider } from "@bokio/contexts/HelpContext/HelpContextProvider";
import { MultiContextProvider } from "@bokio/contexts/MultiContextProvider/MultiContextProvider";
import { NotificationActivityProvider } from "@bokio/contexts/NotificationActivityContext/NotificationActivityProvider";
import { PaymentContextProvider } from "@bokio/contexts/PaymentContext/PaymentContextProvider";
import { PlaidLinkScriptProvider } from "@bokio/contexts/PlaidLinkScriptContext/PlaidLinkScriptProvider";
import { PricePlanContextProvider } from "@bokio/contexts/PricePlanContext/PricePlanContextProvider";
import { PricePlanFeatureContextProvider } from "@bokio/contexts/PricePlanFeatureContext/PricePlanFeatureContextProvider";
import { ReleaseNotesContextProvider } from "@bokio/contexts/ReleaseNotesContext/ReleaseNotesContextProvider";
import { TopLevelUserProvider } from "@bokio/contexts/TopLevelUserContext/TopLevelUserProvider";
import { TutorialContextProvider } from "@bokio/contexts/TutorialContext/TutorialContextProvider";
import { UploadContextProvider } from "@bokio/contexts/UploadContext/UploadContextProvider";
import { AppMessagesOverlay } from "@bokio/elements/AppMessagesOverlay/AppMessagesOverlay";
import { CookieWarning } from "@bokio/elements/CookieWarning/CookieWarning";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { setInlineTranslateLang } from "@bokio/lang/inlineTranslator";
import { languageNotifier } from "@bokio/lang/languageNotifier";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { appMiddleware } from "@bokio/shared/services/api/client/AppMiddleware";
import { noop } from "@bokio/shared/utils";
import { formatMessage } from "@bokio/shared/utils/format";
import { pageUpdaterNotifier } from "@bokio/utils/pageUpdaterNotifier";
import { trackEvent, trackTrace } from "@bokio/utils/t";
import { getCurrentRoute, toPath } from "@bokio/utils/url";
import { ModalStackContextProvider } from "@bokio/utils/UseModalStack/ModalStackContext";

import { AppRoutes } from "./scenes/AppRoutes";

import type { ReleaseInfo } from "../../typings/bokioGlobal";
import type { AddAppMessageRequest, AppState } from "@bokio/contexts/AppContext/AppContext";

import * as styles from "./app.scss";

type FeatureAvailabilityConfig = m.Config.FeatureAvailabilityConfig;
type BankAuthenticationPrompt = m.Bokio.Bank.Contract.Dtos.BankAuthenticationPrompt;

interface AppProps {
	children?: React.ReactNode;
}

interface AppInnerProps extends AppProps {
	contextProviders: React.FunctionComponent[];
	releaseInformation?: ReleaseInfo;
	upgradeAppToNewVersion?: (reason: string | undefined) => void;
	featureAvailability: FeatureAvailabilityConfig;
	updateLanguage?: (lang: string) => void; //Hook to be able to change the language on the HTML tag in index.html and make this testable
}

async function getResponseError(
	response: Response,
): Promise<{ Error: m.SimpleError; ErrorMessage: string; ErrorCode: string } | undefined> {
	try {
		const responseJson = (await response.json()) as m.Envelope<unknown, m.SimpleError>;
		if (responseJson.Error) {
			return {
				Error: responseJson.Error,
				ErrorMessage: responseJson.ErrorMessage,
				ErrorCode: responseJson.ErrorCode ?? "",
			};
		}
	} catch {
		// do nothing
	}
	return undefined;
}

let ongoingBankIdAuthPromiseResolver: (() => void) | null = null;
let ongoingBankIdAuthPromiseRejector: ((error: BankIdAuthenticationPromptCancelledError) => void) | null = null;
let ongoingBankIdAuthPromise = Promise.resolve();

class BankIdAuthenticationPromptCancelledError extends Error {}

async function getBankError(
	response: Response,
): Promise<{ Error: m.Bokio.Bank.Contract.Dtos.BankError; ErrorMessage: string } | undefined> {
	try {
		const responseJson = (await response.clone().json()) as m.Envelope<unknown, m.Bokio.Bank.Contract.Dtos.BankError>;
		if (responseJson.Error) {
			return {
				Error: responseJson.Error,
				ErrorMessage: responseJson.ErrorMessage,
			};
		}
	} catch {
		// do nothing
	}
	return undefined;
}

export const AppInner: React.FC<AppInnerProps> = props => {
	const [state, setState] = React.useState<AppState & { keyCounter: number; showLogin: boolean }>({
		keyCounter: 0,
		messages: [],
		existsNewerVersion: false,
		showLogin: false,
	});

	const [gaScriptsLoaded, setGaScriptsLoaded] = React.useState(false);
	const [gaConsentLoaded, setGaConsentLoaded] = React.useState(false);
	const [bankIdAuthenticationPrompt, setBankIdAuthenticationPrompt] = React.useState<BankAuthenticationPrompt>();

	const [doNotTrackStatus, setDoNotTrackStatus] = React.useState(window.doNotTrackStatus);
	const disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef = React.useRef(0);

	const router = useRouter();

	/* ME: Use this ref because we get a stale closure issue in the appMiddleware.fetch function otherwise.
	 * The reason to keep both the ref and the state is because the state is possibly needed to notify
	 * updates as it's sent to the context.
	 *
	 * Issue: https://dev.azure.com/bokiodev/Voder/_workitems/edit/20543
	 */
	const hasNewerVersion = React.useRef(false);

	const addMessage = (message: AddAppMessageRequest) => {
		const messageKey = message.staticKey ?? generateMessageKey();
		setState(s => ({
			...s,
			messages: uniqBy(
				// Reverse before uniq so the last error of the same key will be flowed to the top in UI
				[...s.messages, { ...message, key: messageKey }].reverse(),
				message => message.key,
			).reverse(),
		}));

		return messageKey;
	};
	const dismissMessage = (key: string) => setState(s => ({ ...s, messages: s.messages.filter(ms => ms.key !== key) }));
	const reloadForNewVersion = (reason: string | undefined) => {
		props.upgradeAppToNewVersion && props.upgradeAppToNewVersion(reason);
	};

	const handleLoginSuccess = async () => {
		setState(state => ({ ...state, showLogin: false }));
		await pageUpdaterNotifier.notify();
	};

	// This is a hack to reload the whole tree without doing a page reload
	const forceRefresh = () => setState(s => ({ ...s, keyCounter: s.keyCounter + 1 }));

	const openBankAuthenticationModal = () => {
		if (!ongoingBankIdAuthPromiseResolver) {
			// BankID retry: step 1 - block all promises and open SveaBankIdModal
			setBankIdAuthenticationPrompt({
				ExternalSystem: m.Entities.Bank.ExternalSystem.Svea,
			});
			ongoingBankIdAuthPromise = new Promise((resolve, reject) => {
				ongoingBankIdAuthPromiseResolver = resolve;
				ongoingBankIdAuthPromiseRejector = reject;
			});
		}
	};

	const onBankAuthenticationModalClose = () => {
		ongoingBankIdAuthPromiseRejector = null;
		ongoingBankIdAuthPromiseResolver = null;
		setBankIdAuthenticationPrompt(undefined);
	};

	const onBankAuthenticationModalCancel = () => {
		// BankID retry: step 3.1.1 - BankID is cancelled by the user, throw error (see 3.1.2)
		ongoingBankIdAuthPromiseRejector?.(new BankIdAuthenticationPromptCancelledError());
		onBankAuthenticationModalClose();
	};

	const [getSveaStatus] = useLazyApi(proxy.Bank.SveaStatusController.Status.Get);
	const [synchronizeInternationalPayments] = useLazyApi(
		proxy.Bank.SveaInternationalPaymentsController.SynchronizeIfInternationalPaymentsIsActive.Post,
	);

	const onBankAuthenticationModalSuccess = (companyId: string) => {
		// BankID retry: step 3.2 - BankID auth successful, resolve promise and unblocks all pending requests
		ongoingBankIdAuthPromiseResolver?.();
		onBankAuthenticationModalClose();

		// We don't need to await this because the response will not be used, see backend code of this endpoint for the full comments.
		getSveaStatus(companyId);
		// If there isn't another component in the view that will call sync the international payment preregistrations,
		// here we sync once automatically in the background.
		//
		// We don't sync the international payment preregistrations automatically when there's another component that might do the sync,
		// this is to prevent that resuming from the `BankID retry` flow and continuing to the original sync request in such a component
		// will lead to 2 syncs running at the same time in both that component and here,
		// and we want to avoid having 2 syncs running at the same time
		// since that might trigger multiple transaction rollbacks in the backend before both requests complete.
		if (!disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef.current) {
			disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef.current++;
			synchronizeInternationalPayments(companyId)
				.finally(() => {
					disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef.current--;
				})
				.catch(noop);
		}
	};

	const currentRoute = getCurrentRoute(router.location);
	React.useEffect(() => {
		setState(state => ({ ...state, messages: state.messages.filter(message => message.persist) }));
	}, [currentRoute]);

	React.useEffect(() => {
		/* ME: NOTE! This whole block is subject to stale-closure bugs.
		 * Be really careful with refering any state in there because
		 * it's very unlikely it behaves like you think.
		 * Stale closure bugs: https://dmitripavlutin.com/react-hooks-stale-closures/
		 */

		// 'New content available' is triggered by the service worker when it
		window.addEventListener("newContentAvailable", () => {
			hasNewerVersion.current = true;
			setState(state => ({ ...state, existsNewerVersion: true }));
			addMessage({ type: AppMessageType.NewAppVersion, persist: true });
		});

		setInlineTranslateLang(languageNotifier.currentLang);
		languageNotifier.subscribe(async lang => {
			if (props.updateLanguage) {
				props.updateLanguage(lang);
			}
			setInlineTranslateLang(lang);
		});
		pageUpdaterNotifier.subscribe(
			() =>
				new Promise(resolve => {
					forceRefresh();
					resolve();
				}),
		);

		appMiddleware.fetch = async (input, options) => {
			const { releaseInformation, upgradeAppToNewVersion } = props;
			if (releaseInformation) {
				const { version, date } = releaseInformation;
				options.headers.ClientVersion = version;
				options.headers.ClientReleaseDate = date;
			}

			// BankID retry: step 2 - block all requests except BankID requests
			const bankIdAuthEndpointsRegex =
				/\/BankApi\/SveaAuth\/(CancelBankIdAuth|StartBankIdAuthentication|GetBankIdAuthStatusAndVerifyAuth)$/;
			const isBankIdAuthRequest = bankIdAuthEndpointsRegex.test(typeof input === "string" ? input : input.url);

			if (!isBankIdAuthRequest) {
				try {
					await ongoingBankIdAuthPromise;
				} catch (error) {
					if (error instanceof BankIdAuthenticationPromptCancelledError) {
						// BankID retry: step 3.1.2 - BankID auth was cancelled, reject original request with proper error message
						const bankLang = BankLangFactory();
						const errorMessage = bankLang.BankId_Cancelled_ErrorMessage;
						const bankError: m.Envelope<unknown, m.Bokio.Bank.Contract.Dtos.BankError> = {
							Success: false,
							Data: undefined,
							ErrorMessage: errorMessage,
							Error: {
								Message: errorMessage,
							},
						};

						// Cleanup promise resolver and rejector to prevent further requests from blocking
						ongoingBankIdAuthPromiseResolver?.();
						ongoingBankIdAuthPromiseRejector = null;
						ongoingBankIdAuthPromiseResolver = null;
						ongoingBankIdAuthPromise = Promise.resolve();

						return new Response(JSON.stringify(bankError), {
							headers: new Headers([["Content-Type", "application/json"]]),
						});
					}
				}
			}

			const response = await fetch(input, options);
			const releaseInfoFromApi = {
				version: response.headers.get("ReleaseVersion"),
				date: response.headers.get("ReleaseDate"),
			};

			const versionFromApi = releaseInfoFromApi.version;
			const versionFromSource = releaseInformation ? releaseInformation.version : null;
			const existsNewRelease = !!versionFromApi && !!versionFromSource && versionFromApi !== versionFromSource;

			if (existsNewRelease && !hasNewerVersion.current) {
				trackTrace("ReleaseMismatch", {});
				hasNewerVersion.current = true;
				setState(state => ({ ...state, existsNewerVersion: true }));
				addMessage({ staticKey: "NewAppVersion", type: AppMessageType.NewAppVersion, persist: false });
			}

			const forcedUpdateFlag = response.headers.has("ForcedClientUpdate");
			if (forcedUpdateFlag) {
				upgradeAppToNewVersion && upgradeAppToNewVersion("ForcedUpdate");
			}

			const bankError = await getBankError(response);
			if (bankError?.Error.BankAuthenticationPrompt) {
				openBankAuthenticationModal();

				// The original request is awaited here and will only resolve once BankID auth is complete.
				return await appMiddleware.fetch(input, options);
			}

			// 2023-10-13 PH: Remove once we have deleted all demo companies in the DBs
			if (response.status === 405) {
				trackEvent("Demo", "ActionUnavailable");
				addMessage({
					staticKey: "DemoActionUnavailable",
					type: AppMessageType.WarningMessage,
					message: "Demo not available",
					persist: false,
				});
				throw new Error("Demo not available");
			}

			if (response.status === 401) {
				setState(state => ({ ...state, showLogin: true }));
				return response;
			}

			// NE 2020-03-10
			// Http.Forbidden. Should be handled by the requesting domain.
			if (response.status === 403) {
				if (response.headers.get("login-restriction") == "bokiopay-bankid") {
					const currentUrl = window.location.href;
					// This is done to prevent recurring url concat when concurrent api calls fails.
					// TODO Check inlcud(getRoute("bankIdRequired", { returnUrl: "" })) failure
					if (!currentUrl.includes("/settings-r/bankid-required")) {
						router.push(getRoute("bankIdRequired", { returnUrl: currentUrl }));
					}
				}
				return response;
			}

			if (response.status >= 400) {
				const error = await getResponseError(response);
				const errorMessage =
					error && error.Error === m.SimpleError.NotAllowSupportUser ? error.ErrorMessage : undefined;
				const lang = GeneralLangFactory();
				addMessage({
					staticKey: "GlobalBackendError",
					type: AppMessageType.DetailedError,
					title: lang.GeneralAppError_Title,
					content:
						errorMessage ??
						formatMessage(lang.GeneralAppError_Content, link => (
							<ContactSupportLink area={m.Contracts.SupportFormArea.NotSet} referenceId={error?.ErrorCode}>
								{link}
							</ContactSupportLink>
						)),
					persist: false,
					refrenceId: error?.ErrorCode,
				});

				throw new Error(`Server error exception: ${response.status} ${response.statusText}`);
			}

			// NE 2019-09-26
			// This code currently lacks tests.
			// Is it used at all?

			if (response.status === 200 && response.redirected) {
				trackTrace("Response.Redirected", {});
				router.push(getRoute("login", {}, { returnUrl: toPath(router.location) }));
			}

			return response;
		};

		window.softVisit = route => router.push(route);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pushDisableSveaInternationalPaymentsAutoSyncOnAuth = React.useCallback(() => {
		disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef.current++;
	}, []);

	const popDisableSveaInternationalPaymentsAutoSyncOnAuth = React.useCallback(() => {
		disableSveaInternationalPaymentsAutoSyncOnAuthStackCounterRef.current--;
	}, []);

	const context: AppContext = {
		messages: state.messages,
		existsNewerVersion: state.existsNewerVersion,
		addMessage: addMessage,
		dismissMessage: dismissMessage,
		reloadForNewVersion: reloadForNewVersion,
		featureAvailability: props.featureAvailability,
		isBankAuthModalOpen: !!bankIdAuthenticationPrompt,
		openBankAuthenticationModal,
		pushDisableSveaInternationalPaymentsAutoSyncOnAuth,
		popDisableSveaInternationalPaymentsAutoSyncOnAuth,
	};

	return (
		<ErrorBoundary source="App">
			<AppContext.Provider value={context}>
				<MultiContextProvider providers={props.contextProviders}>
					<GoogleAnalyticsConsent onScriptsLoaded={() => setGaConsentLoaded(true)} />
					{doNotTrackStatus === "Track" && gaConsentLoaded && (
						<GoogleAnalytics onScriptsLoaded={() => setGaScriptsLoaded(true)} />
					)}
					{/* ME: Verve has to be included after GA but before interesting GA events are sent. I ignored the last part as one pageview is sent on load */}
					{doNotTrackStatus === "Track" && gaScriptsLoaded && !!window.config.useVerve && <VerveTracking />}
					{doNotTrackStatus === "Track" && !!window.config.metaPixel.Id && <MetaPixel />}
					{doNotTrackStatus === "Track" && !!window.config.microsoftAds.trackingId && <MicrosoftAds />}
					{doNotTrackStatus === "Track" && !!window.config.linkedinAds.partnerId && <LinkedinAds />}
					{doNotTrackStatus === "Track" && !!window.config.taboolaPixel.partnerId && <TaboolaPixel />}
					{doNotTrackStatus === "NotSet" && (
						<CookieWarning
							onChange={val => {
								setDoNotTrackStatus(val);
							}}
						/>
					)}
					<div id="body-root" className={styles.baseLayer}>
						{props.children || <AppRoutes />}
					</div>
					<div id="modal-root" className={styles.modalLayer} />
					<div id="tooltip-root" className={styles.tooltipLayer} />
					<div id="flyout-root"></div>
					<div id="chat-root"></div>

					<AppMessagesOverlay className={styles.messageLayer} />
					<AppMessagesOverlay className={styles.messageLayerRight} isAlignRight={true} />
					{state.showLogin && (
						<LoginModal visible={state.showLogin} idleLoggedOut={false} handleLoginSuccess={handleLoginSuccess} />
					)}

					{bankIdAuthenticationPrompt && (
						<SveaBankIdAuthModal
							bankIdAuthenticationPrompt={bankIdAuthenticationPrompt}
							onCloseModal={onBankAuthenticationModalCancel}
							onSuccess={onBankAuthenticationModalSuccess}
						/>
					)}

					<SveaKycReminderModal />

					<FloatingTutorial />
				</MultiContextProvider>
			</AppContext.Provider>
		</ErrorBoundary>
	);
};

const reload = (reason: string | undefined) => {
	trackTrace(`AppRefresh.${reason || "UnknownReason"}`);
	window.location.reload();
};
const updateLang = (lang: string) => {
	const html = document.querySelector("html");
	if (html) {
		html.lang = lang.substr(0, Math.max(lang.indexOf("-"), lang.length)); //Defensive to make sure we don't crash the page if the lang don't contain a dash
	}
};

/**
 * Entrypoint for rendering in web.
 */
export const renderBrowserAppRoot = () => {
	const router = createBrowserRouter([
		{
			path: "*",
			element: (
				<TopLevelUserProvider>
					<AppInner
						releaseInformation={window.releaseInfo}
						featureAvailability={window.featureAvailability}
						upgradeAppToNewVersion={reload}
						updateLanguage={updateLang}
						contextProviders={[
							CompanyInfoContextProvider,
							EmailDeliveryProvider,
							BankFeedActivityProvider,
							NotificationActivityProvider,
							PlaidLinkScriptProvider,
							PaymentContextProvider,
							UploadContextProvider,
							PricePlanContextProvider,
							PricePlanFeatureContextProvider,
							HelpContextProvider,
							TutorialContextProvider,
							CashbackContextProvider,
							ReleaseNotesContextProvider,
							// SS 2020-12-10
							// If you want to add a new context,
							// please only do that above of this comment block to save other devs' debugging time unless you are super sure what you are doing.
							// ModalStackContextProvider should be the innermost context provider because modals are rendered in it,
							// and sometimes devs would want to access context values from a modal.
							ModalStackContextProvider,
						]}
					/>
				</TopLevelUserProvider>
			),
		},
	]);

	const container = document.getElementById("root") as HTMLElement;
	const root = createRoot(container);
	root.render(<RouterProvider router={router} />);
};
