import * as React from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import { formatMessage } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";
import { toDropZoneAccept } from "@bokio/utils/reactDropZoneHelper";

import { useDeviceQuery } from "../DeviceQuery/useDeviceQuery";
import { FeedbackGraphic } from "../Feedback";
import { LoadingSpinner } from "../Loading";
import { Notice } from "../Notice/Notice";
import { FilePreview } from "./components/FilePreview/FilePreview";
import { FilePreviewModal } from "./components/FilePreviewModal/FilePreviewModal";

import type { FileWithPreview } from "./components/FilePreview/FilePreview";
import type { FileUploaderFileType } from "@bokio/utils/reactDropZoneHelper";
import type { FileRejection } from "react-dropzone";

import * as styles from "./fileUploader.scss";

const convertBytesToMegaBytes = (n: number): number => {
	return Number((n / 1_048_576).toFixed(2));
};

interface MessageWithId {
	message: string;
	id: string;
}

type FileUploaderBaseProps = {
	fileLoading?: boolean;
	acceptedFileTypes: FileUploaderFileType[];
	label?: string;
	testId?: string;
	allowMultiple?: boolean;
	small?: boolean;
	disabled?: boolean;
	onFileUpload: (files: FileWithPreview[]) => void;
	children?: React.ReactNode;
	noMarginBottom?: boolean;
	noMarginTop?: boolean;
	noMargin?: boolean;
	fullscreen?: boolean;
	transparentButton?: boolean;
	dropOnly?: boolean;
	onDropHandled?: () => void;
	rejectAllOnAnyRejection?: boolean;
	maxFileSize?: number;
	disablePreview: boolean;
};

type FileUploaderProps =
	| (FileUploaderBaseProps & { disablePreview: true })
	| (FileUploaderBaseProps & {
			disablePreview: false;
			files: FileWithPreview[];
			onFileDelete: (file: FileWithPreview) => void;
	  });

export const FileUploader = (props: FileUploaderProps) => {
	const {
		fileLoading,
		testId,
		acceptedFileTypes,
		allowMultiple,
		small,
		disabled,
		onFileUpload,
		files,
		children,
		fullscreen,
		transparentButton,
		dropOnly,
		onDropHandled,
		rejectAllOnAnyRejection,
		noMarginBottom,
		noMarginTop,
		noMargin,
		maxFileSize,
		disablePreview,
		onFileDelete,
	} = !props.disablePreview ? props : { ...props, files: [], onFileDelete: undefined };
	const [dropzoneStyles, setDropzoneStyles] = React.useState<string[]>([]);
	const [errorMessages, setErrorMessages] = React.useState<MessageWithId[]>([]);
	const { isMobile } = useDeviceQuery();

	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();

	const dropzoneAccept = toDropZoneAccept(acceptedFileTypes);
	const acceptedFileExtensions = Object.values(dropzoneAccept).flat().sort();
	const acceptedFileExtensionsString = `${
		acceptedFileExtensions.length > 1
			? settingsLang.FileUploader_Accepted_Multiple
			: settingsLang.FileUploader_Accepted_Single
	} ${acceptedFileExtensions
		.map((fileExtension, i, a) => {
			if (i === a.length - 1) {
				return `${fileExtension}`;
			}
			if (i === a.length - 2) {
				return `${fileExtension} ${generalLang.And} `;
			}
			return `${fileExtension}, `;
		})
		.join("")}`;

	const resetDropzoneStyles = () => {
		setDropzoneStyles([]);
	};

	const handleDragOver = () => {
		resetDropzoneStyles();
		setDropzoneStyles([...dropzoneStyles, styles.active]);
	};

	const handleDropRejected = () => {
		resetDropzoneStyles();
		setDropzoneStyles([...dropzoneStyles, styles.rejected]);
	};

	const handleErrorMessages = (rejections: FileRejection[]) => {
		const messages: MessageWithId[] = [];
		rejections
			.map(f => f.file)
			.forEach(f => {
				const isTooBig = maxFileSize ? f.size > maxFileSize : false;
				const isWrongType = !acceptedFileExtensions.some(extension =>
					f.name.toLowerCase().endsWith(`${extension}`.toLowerCase()),
				);

				// AT 2020-07-17: Have to give the error messages unique id's to be used as keys.
				// If the message is used as a key and the notice gets dismissed, the notice won't re-appear if the same file is uploaded again.
				if (maxFileSize && isTooBig) {
					messages.push({
						message: `${f.name} (${convertBytesToMegaBytes(f.size)} MB): ${formatMessage(
							generalLang.MaximumFileSize,
							convertBytesToMegaBytes(maxFileSize),
						)}`,
						id: uuidv4(),
					});
				}
				if (isWrongType) {
					messages.push({
						message: `${f.name}: ${settingsLang.FileUploader_WrongFileType}. ${acceptedFileExtensionsString}`,
						id: uuidv4(),
					});
				}
			});

		setErrorMessages(messages);
	};

	const [previewFile, setPreviewFile] = React.useState<FileWithPreview | undefined>();

	React.useEffect(() => {
		// Prevent memory leak
		return () => {
			Object.values(files).forEach(file => URL.revokeObjectURL(file.preview));
		};
		// Only run when we unmount the component
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFileUpload = (uploadedFiles: File[]) => {
		const filesWithPreview = uploadedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
		onFileUpload(filesWithPreview);
	};

	const handleRemoveFile = (preview: FileWithPreview) => {
		URL.revokeObjectURL(preview.preview);
		setPreviewFile(undefined);
		onFileDelete && onFileDelete(preview);
	};

	const onDrop = React.useCallback(
		(accepted: File[], rejected: FileRejection[]) => {
			if (accepted.length && !(rejected.length && rejectAllOnAnyRejection)) {
				resetDropzoneStyles();
				handleFileUpload(accepted);
				// TODO: Fix loading.
			}
			if (!rejected.length) {
				setErrorMessages([]);
				onDropHandled?.();
				return;
			}

			handleErrorMessages(rejected);
			handleDropRejected();
			setTimeout(() => {
				rejected.length = 0;
				resetDropzoneStyles();
				onDropHandled?.();
			}, 2000);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onFileUpload, onDropHandled, handleDropRejected, handleErrorMessages],
	);

	const disabledOrLoading = disabled || fileLoading;

	const { getRootProps, getInputProps } = useDropzone({
		multiple: allowMultiple || false,
		accept: dropzoneAccept,
		onDrop,
		disabled: disabledOrLoading,
		noClick: disabledOrLoading || dropOnly,
		noDragEventsBubbling: disabledOrLoading,
		noDrag: disabledOrLoading,
		noKeyboard: disabledOrLoading,
		maxSize: maxFileSize,
	});

	const renderUpload = () => {
		const { label } = props;

		const instruction = dropOnly
			? settingsLang.FileUploader_MainText_DropOnly
			: isMobile
				? settingsLang.FileUploader_MainText_Mobile
				: settingsLang.FileUploader_MainText_Desktop;

		return (
			<button
				type="button"
				className={mergeClassNames(styles.activeWrapper, transparentButton && styles.transparentButton)}
			>
				<FeedbackGraphic type="settingsImportPreviousBookkeeping" />
				<div className={styles.p}>
					{!fullscreen && <div className={styles.mainText}>{label ? label : instruction}</div>}
					{fullscreen &&
						(label ? (
							<div className={styles.mainText}>{label}</div>
						) : !errorMessages.length ? (
							<div className={styles.mainText}>{instruction}</div>
						) : (
							<>
								{errorMessages.map(e => (
									<div className={styles.mainText} key={e.id}>
										{e.message}
									</div>
								))}
							</>
						))}
					<i className={styles.subText}>{acceptedFileExtensionsString}</i>
				</div>
			</button>
		);
	};

	if (fileLoading) {
		return (
			<div className={mergeClassNames(styles.loading, small && styles.smallLoading)}>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<>
			{errorMessages.length > 0 &&
				!fullscreen &&
				errorMessages.map(e => (
					<Notice key={e.id} color="error" dismissible>
						{e.message}
					</Notice>
				))}
			<div
				{...getRootProps()}
				className={mergeClassNames(
					styles.logo,
					disabled && styles.disabled,
					(noMarginTop || noMargin) && styles.noMarginTop,
					(noMarginBottom || noMargin) && styles.noMarginBottom,
					fullscreen && styles.fullscreen,
				)}
			>
				<div
					className={mergeClassNames(
						fullscreen ? styles.fullscreen : styles.dropzoneDefaultSize,
						styles.dropzone,
						small ? styles.small : null,
						[...dropzoneStyles],
					)}
					onDragOver={handleDragOver}
					onDragLeave={resetDropzoneStyles}
				>
					<input {...getInputProps()} data-testid={testId ? (small ? `${testId}_small` : testId) : undefined} />
					{children || (!disablePreview && files.length > 0) ? (
						<FilePreview files={files} openPreview={setPreviewFile} />
					) : (
						renderUpload()
					)}
				</div>
			</div>
			<FilePreviewModal
				open={!!previewFile}
				previewFile={previewFile}
				onClose={() => setPreviewFile(undefined)}
				onRemoveFile={file => handleRemoveFile(file)}
			/>
		</>
	);
};
