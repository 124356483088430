import { useCallback, useEffect, useRef } from "react";

const activityEventNames = [
	"mousemove",
	"keydown",
	"wheel",
	"DOMMouseScroll",
	"mouseWheel",
	"mousedown",
	"touchstart",
	"touchmove",
	"MSPointerDown",
	"MSPointerMove",
];

export const useIdleTimer = ({
	idleTimeout,
	onActive,
}: {
	/**
	 * How long the user has to be idle before the status is changed to idle.
	 */
	idleTimeout: number;
	/**
	 * Callback function when the user becomes active again after being idle.
	 */
	onActive: () => void;
}) => {
	const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const isIdleRef = useRef(false);
	const activeStatusRef = useRef<{
		mouseMovePageX: number | undefined;
		mouseMovePageY: number | undefined;
	}>({
		mouseMovePageX: undefined,
		mouseMovePageY: undefined,
	});

	const markAsIdle = useCallback(() => {
		isIdleRef.current = true;
	}, []);

	const markAsActive = useCallback(
		({
			mouseMovePageX,
			mouseMovePageY,
		}: {
			mouseMovePageX: number | undefined;
			mouseMovePageY: number | undefined;
		}) => {
			const wasIdle = isIdleRef.current;
			isIdleRef.current = false;
			activeStatusRef.current = {
				mouseMovePageX: mouseMovePageX,
				mouseMovePageY: mouseMovePageY,
			};

			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = setTimeout(markAsIdle, idleTimeout);

			if (wasIdle) {
				onActive();
			}
		},
		[idleTimeout, markAsIdle, onActive],
	);

	const handleActivityEvent = useCallback(
		(e: Event) => {
			const mouseMovePageX = e instanceof MouseEvent && e.type === "mousemove" ? e.pageX : undefined;
			const mouseMovePageY = e instanceof MouseEvent && e.type === "mousemove" ? e.pageY : undefined;

			// Don't update active status if the mouse hasn't moved
			if (
				mouseMovePageX !== undefined &&
				mouseMovePageY !== undefined &&
				activeStatusRef.current.mouseMovePageX === mouseMovePageX &&
				activeStatusRef.current.mouseMovePageY === mouseMovePageY
			) {
				return;
			}

			markAsActive({
				mouseMovePageX,
				mouseMovePageY,
			});
		},
		[markAsActive],
	);

	useEffect(() => {
		clearTimeout(timeoutIdRef.current);
		timeoutIdRef.current = setTimeout(markAsIdle, idleTimeout);

		return () => {
			clearTimeout(timeoutIdRef.current);
		};
	}, [idleTimeout, markAsIdle]);

	useEffect(() => {
		activityEventNames.forEach(eventName => {
			document.addEventListener(eventName, handleActivityEvent, {
				passive: true,
			});
		});

		return () => {
			activityEventNames.forEach(eventName => {
				document.removeEventListener(eventName, handleActivityEvent);
			});
		};
	}, [handleActivityEvent]);
};
