import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { LoadingSpinner } from "@bokio/elements/Loading";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./search.scss";

type InputElementAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export interface SearchFieldProps {
	value: string;
	disabled?: boolean;
	padding?: boolean;
	placeholder?: string;
	onChange: (value: string) => void;
	doSearch?: (value: string) => void;
	allowClear?: boolean;
	onClearSearch?: () => void;
	wide?: boolean;
	autoFocus?: boolean;
	testId?: string;
	className?: string;
	onFocus?: () => void;
	onBlur?: (e: React.FocusEvent) => void;
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
	/**
	 * Additional props to the actual input element.
	 */
	fieldProps?: InputElementAttributes;
	/**
	 * Whether to show the looking glass icon. `true` by default.
	 */
	showIcon?: boolean;
	loading?: boolean;
}

export class SearchField extends React.Component<SearchFieldProps> {
	constructor(props: SearchFieldProps) {
		super(props);
		this.searchClick = this.searchClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
	}

	doSearch() {
		if (this.props.doSearch) {
			this.props.doSearch(this.props.value);
		}
	}

	searchClick = () => this.doSearch();
	onChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(e.target.value);

	onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === "Enter" && (this.props.value || this.props.value === "")) {
			this.doSearch();
		}
	}

	render() {
		// Hourglass icon is shown by default
		const showIcon = this.props.showIcon ?? true;

		return (
			<div className={this.props.padding ? styles.padding : undefined}>
				<div className={mergeClassNames(this.props.className, this.props.wide ? styles.searchWide : styles.search)}>
					<input
						{...this.props.fieldProps}
						ref={this.props.inputRef}
						data-testid={this.props.testId}
						type="search"
						autoFocus={this.props.autoFocus}
						onChange={this.onChange}
						value={this.props.value || ""}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder}
						onKeyUp={this.onKeyUp}
						onFocus={this.props.onFocus}
						onBlur={this.props.onBlur}
					/>

					{this.props.loading && (
						<div>
							<LoadingSpinner className={mergeClassNames(styles.icon, styles.spinner)} color={"blue"} size={20} />
						</div>
					)}
					{showIcon &&
						!this.props.loading &&
						(this.props.allowClear && this.props.onClearSearch && this.props.value ? (
							<div onClick={this.props.onClearSearch}>
								<Icon name="cancel" color="darkGrey" className={styles.icon} size={"24"} />
							</div>
						) : (
							<div onClick={this.searchClick}>
								<Icon
									name="search"
									color="darkGrey"
									className={styles.icon}
									size={"24"}
									testId="SearchField_HourglassIcon"
								/>
							</div>
						))}
				</div>
			</div>
		);
	}
}
