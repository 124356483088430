import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { useEventListener } from "@bokio/hooks/useEventListener/useEventListener";
import { useScript } from "@bokio/hooks/useScript/useScript";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { usePricePlanFeatureContext } from "@bokio/mobile-web-shared/core/contexts/PricePlanFeatureContext/PricePlanFeatureContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useUser } from "@bokio/shared/state/requests";

import PricePlanFeature = m.Bokio.Common.Contract.ViewData.PricePlanFeature;
export const FreshdeskSupportChat = () => {
	const { plan } = usePricePlanContext();
	const { isPricePlanFeatureEnabled } = usePricePlanFeatureContext();
	const { companyInfo } = useCompanyInfo();
	const { user } = useUser();

	const shouldShowChat = isPricePlanFeatureEnabled(PricePlanFeature.LiveChat);

	//PP2022: Change to new plans + handle feature toggle for those that should have chat
	useScript(shouldShowChat && !!window.config.freshchat.key ? "scripts/freshchat.js" : "");

	/*ME: The current approach of initating the chat is not perfect because it only goes away on a full page reload
	 * That is good when we move into personal setting from a company with business and OK when we use the company selector because it does a full reload.
	 *
	 * It is less good when we go from a company with business through the flow to create a new company as no hard reload happens there. But I think that is OK
	 */

	//ME: Freshchat API documentation is a pile of trash. This gives more info https://community.freshworks.dev/t/needed-complete-list-of-available-fields-for-freshchats-js-messenger-widget-user-api/1594
	const onLoad: () => void = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const fcWidget = (window as any).fcWidget;
		fcWidget.init({
			token: window.config.freshchat.key, //ME: From config to control display, not secret as it will be visible in FE anyway
			widgetUuid: window.config.freshchat.widgetKey !== "" ? window.config.freshchat.widgetKey : undefined,
			host: "https://bokiogroupab-help.freshchat.com",
			externalId: user?.Id,
			siteId: companyInfo.Id, //ME: Set this so the users get a different conversation for each company
			firstName: user?.FirstName,
			lastName: user?.LastName,
			email: user?.Email,
			tags: [companyInfo.Country, plan],
			meta: {
				companyId: companyInfo.Id,
				companyName: companyInfo.Name,
				companyType: companyInfo.CompanyType,
				paymentState: companyInfo.BillingPaymentState,
				country: companyInfo.Country,
				plan: plan,
			},
		});
	};

	useEventListener("freshchat.loaded", onLoad);

	return <></>;
};

/** Exist to make it possible to conditionally swap between Chatlio and Freshdesk */
export const SupportChat = () => {
	return <FreshdeskSupportChat />;
};
