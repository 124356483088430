import { produce, setAutoFreeze } from "immer";

/**
 * SS 2025-01-13
 * When migrating from `immutability-helper` to `immer` I found that some of our code tries to mutate the React state object directly,
 * which is not allowed in `immer` by default, and is error-prone in general.
 * This function is just for helping with the migration and should eventually be removed.
 *
 * This function can't handle async recipe.
 *
 * @deprecated Should use produce from 'immer' instead and don't turn off auto freeze.
 */
export const produceWithoutFreeze = <T>(base: T, recipe: (draft: T) => void): T => {
	setAutoFreeze(false);
	const result = produce(base, recipe);
	setAutoFreeze(true);
	return result;
};
