import * as React from "react";

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { useIdleTimer } from "@bokio/hooks/useIdleTimer/useIdleTimer";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { pageUpdaterNotifier } from "@bokio/utils/pageUpdaterNotifier";

import LoginModal from "./LoginModal";

export const AuthenticationChecker: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [idleLoggedOut, setIdleLoggedOut] = React.useState(false);
	const { showLogin, setShowLogin } = useTopLevelUser();

	const handleShowLogin = React.useCallback(
		(idle: boolean) => {
			setIdleLoggedOut(idle);
			setShowLogin(true);
		},
		[setShowLogin],
	);

	const checkAuthentication = React.useCallback(async () => {
		const response = await proxy.Bokio.AccountController.IsAuthenticated.Get();
		if (response.Success && response.Data) {
			showLogin && setShowLogin(false);
		} else {
			handleShowLogin(true);
		}
	}, [showLogin, setShowLogin, handleShowLogin]);

	const handleLoginSuccess = async () => {
		// Deep reload the react tree
		setShowLogin(false);
		await pageUpdaterNotifier.notify();
	};

	useIdleTimer({
		onActive: checkAuthentication,
		idleTimeout: 60_000,
	});

	return (
		<>
			{children}
			<LoginModal visible={showLogin} idleLoggedOut={idleLoggedOut} handleLoginSuccess={handleLoginSuccess} />
		</>
	);
};
