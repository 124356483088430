/**
 * ┌────────────────────────────────────────────────────────────────────────┐
 * │ This file has been generated by routeGenerator                         │
 * │                                                                        │
 * │ The routes are defined in bundles.ts                                   │
 * │ Some routes may require changes in Rewrite.cs                          │
 * │ Please do not edit this file manually.                                 │
 * └────────────────────────────────────────────────────────────────────────┘
**/
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment */
import * as React from "react";
import { Route } from "react-router";

import { BackOfficeRoute } from "@bokio/backoffice/src/scenes/BackOfficeRoute";
import { AgencyRoute } from "@bokio/components/Route/AgencyRoute";
import { CompanyRoute } from "@bokio/components/Route/CompanyRoute";
import { UserRoute } from "@bokio/components/Route/UserRoute";
import { routes } from "@bokio/shared/route";
import createBundleLoader from "@bokio/utils/createBundleLoader";
import { ServerRouteRedirect } from "@bokio/utils/ServerRouteRedirect";

// Keep the imports just so they don't get removed
createBundleLoader;
ServerRouteRedirect;
Route;
routes;
UserRoute;
CompanyRoute;
BackOfficeRoute;
AgencyRoute;

const scenes = {

  /* Signup */
  SignUp: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  SignUpAccountant: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  Login: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Login/LoginScene");
    },
    narrowPage: true
  }),
  ConnectAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Login/LoginScene");
    },
    narrowPage: true
  }),
  ForgotPassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ForgotPassword/ForgotPasswordScene");
    },
    narrowPage: true
  }),
  ResetPassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ResetPassword/ResetPasswordScene");
    },
    narrowPage: true
  }),
  AcceptInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/AcceptInvitation/AcceptInvitationScene");
    },
    narrowPage: true
  }),
  AcceptCompanyOwnerInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/AcceptInvitation/AcceptCompanyOwnerInvitationScene");
    },
    narrowPage: true
  }),
  ConfirmEmail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ConfirmEmail/ConfirmEmailScene");
    },
    narrowPage: true
  }),
  IntegrationTests: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/IntegrationTests/IntegrationTestsScene");
    },
    narrowPage: true
  }),
  VoucherFlow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Voucher/VoucherFlowScene");
    },
    narrowPage: true
  }),
  BokioCreateCompanyKYC: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/BCC/KYC/KYCScene");
    },
    narrowPage: true
  }),
  /* Settings */
  CreateCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/guest/src/scenes/SignUp/CreateCompanyScene");
    },
    narrowPage: true
  }),
  PreCreateClient: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/guest/src/scenes/SignUp/CreateClientPreScene");
    },
    narrowPage: true
  }),
  SettingsImportCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ImportBookkeeping/ImportBookkeepingScene");
    },
  }),
  SettingsImportOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ImportPreviousBookkeeping/ImportPreviousBookkeepingScene");
    },
  }),
  FiscalList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearsListScene");
    },
  }),
  FiscalEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearEditScene");
    },
  }),
  FiscalCreate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearCreateScene");
    },
  }),
  LockPeriod: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LockPeriod/LockPeriodScene");
    },
  }),
  AccountPlan: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountPlan/AccountPlanScene");
    },
  }),
  MapAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/MapAccounts/MapAccountsScene");
    },
  }),
  InvoiceSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/InvoiceSettings/InvoiceSettingsScene");
    },
  }),
  EInvoiceSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/EInvoiceSettings/EInvoiceSettingsScene");
    },
  }),
  AutomaticCheckingSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AutomaticCheckingSettings/AutomaticCheckingSettingsScene");
    },
  }),
  PaymentMethodSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PaymentMethodSettings/PaymentMethodSettingsScene");
    },
  }),
  SettingsOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Overview/SettingsOverviewScene");
    },
  }),
  PersonalSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PersonalSettings/PersonalSettingsScene");
    },
  }),
  CompanyDataStorage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanyDataStorage/CompanyDataStorageScene");
    },
  }),
  TotpActivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPActivateScene");
    },
  }),
  TotpDeactivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPDeactivateScene");
    },
  }),
  TotpSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPSettingsScene");
    },
  }),
  TotpRecoveryDocument: ServerRouteRedirect,
  RecentLogins: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/RecentLogins/RecentLoginsScene");
    },
  }),
  LogOutEverywhere: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LogOutEverywhere/LogOutEverywhereScene");
    },
  }),
  BankidSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdSettingsScene");
    },
  }),
  BankidActivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdActivateScene");
    },
  }),
  BankidDeactivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdDeactivateScene");
    },
  }),
  BankIdRequired: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdLogin/BankIdRequiredScene");
    },
  }),
  UpdateUsername: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/UpdateUsername/UpdateUsernameScene");
    },
  }),
  ConfirmUpdateUsername: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/UpdateUsernameConfirm/UpdateUsernameConfirmScene");
    },
  }),
  MoneyAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/MoneyAccounts/MoneyAccountsScene");
    },
  }),
  TagTypes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TagTypes/TagTypesScene");
    },
  }),
  ExportBookkeeping: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ExportBookkeeping/ExportBookkeepingScene");
    },
  }),
  OpeningBalances: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/OpeningBalances/OpeningBalancesScene");
    },
  }),
  AccountPayables: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountPayables/AccountPayablesScene");
    },
  }),
  AccountReceivables: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountReceivables/AccountReceivablesScene");
    },
  }),
  LayoutLogoColor: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LayoutLogoColor/LayoutLogoColorScene");
    },
  }),
  CompanySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanySettings/CompanySettingsScene");
    },
  }),
  ChangeCompanySystem: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ChangeCompanySystem/ChangeCompanySystemScene");
    },
  }),
  DeleteCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/DeleteCompany/DeleteCompanyScene");
    },
  }),
  LeaveCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LeaveCompany/LeaveCompanyScene");
    },
  }),
  DeleteUserAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/DeleteUserAccount/DeleteUserAccountScene");
    },
  }),
  FeatureToggles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FeatureToggles/FeatureTogglesScene");
    },
  }),
  Agreements: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Legal/CurrentAgreementsScene");
    },
  }),
  SignTerms: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Legal/SignTermsScene");
    },
  }),
  Settings: ServerRouteRedirect,
  CompanySetting: ServerRouteRedirect,
  Inbox: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Inbox/InboxScene");
    },
  }),
  InboxHistory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Inbox/InboxHistory/InboxHistoryScene");
    },
  }),
  InboxAttachmentDownload: ServerRouteRedirect,
  ExportData: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ExportDataScene/ExportDataScene");
    },
  }),
  Users: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Users/UsersScene");
    },
  }),
  ChangePassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ChangePassword/ChangePasswordScene");
    },
  }),
  AuditHistory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AuditHistory/AuditHistoryScene");
    },
  }),
  ExportAuditHistory: ServerRouteRedirect,
  CompanyVacationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanyVacationSettings/CompanyVacationSettingsScene");
    },
  }),
  PlaceOfEmploymentSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PlaceOfEmploymentSettings/PlaceOfEmploymentSettingsScene");
    },
  }),
  SalarySettingUK: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SalarySettingsUK/SalarySettingsUKScene");
    },
  }),
  ActivateVoucher: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/ActivateVoucherScene");
    },
  }),
  Billing: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/BillingScene");
    },
  }),
  ViewBill: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/ViewBillScene");
    },
  }),
  DownloadBillingReceiptPdf: ServerRouteRedirect,
  CardList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/CardListScene");
    },
  }),
  Card: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/CardScene");
    },
  }),
  OrderCard: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/OrderCardScene");
    },
  }),
  SupportMessages: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SupportMessages/SupportMessageScene");
    },
  }),
  BokioBusinessAccountOffboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BokioBusinessAccountOffboarding/BokioBusinessAccountOffboardingScene");
    },
  }),
  SveaBankConsent: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Svea/SveaConsentScene");
    },
  }),
  SveaKycScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SveaKyc/SveaKycApplicationScene");
    },
  }),
  NotificationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/NotificationSettings/NotificationSettingsScene");
    },
  }),
  NotificationRedirect: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/NotificationRedirect/NotificationRedirectScene");
    },
  }),
  ExportSIEFile: ServerRouteRedirect,
  CustomTemplates: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CustomTemplates/CustomTemplatesScene");
    },
  }),
  AutomationRules: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AutomationRules/AutomationRulesScene");
    },
  }),
  MirChangeSignatory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/bank/src/scenes/MirChangeSignatory/MirChangeSignatoryScene");
    },
  }),
  RemoveImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/RemoveImport/RemoveImportScene");
    },
  }),
  /* Bookkeeping */
  HmrcAuth: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/web/scenes/HMRC/HMRCAuthScene");
    },
  }),
  Accounting: ServerRouteRedirect,
  PreBookkeep: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/PreBookkeep/PreBookkeepScene");
    },
  }),
  BookkeepReceipt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepScene");
    },
  }),
  RecordingCheckList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/RecordingCheckList");
    },
  }),
  RecordingCheckCreate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/CreateRecordingCheckScene");
    },
  }),
  RecordingCheckView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/RecordingCheckViewScene");
    },
  }),
  BookkeepExpense: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepExpenseScene");
    },
  }),
  BookkeepSupplier: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepSupplierInvoiceScene");
    },
  }),
  BookkeepTransaction: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepAutoTransactionScene");
    },
  }),
  EditVerification: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/EditVerification/EditVerificationScene");
    },
  }),
  Attest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Attest/AttestScene");
    },
  }),
  EventsToRecord: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/EventsToRecord/EventsToRecordScene");
    },
  }),
  VerificationAndTransactionMatching: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/VerificationAndBankTransactionMatching/VerificationAndBankTransactionMatchingScene");
    },
  }),
  ReceiptImage: ServerRouteRedirect,
  DownloadReceipt: ServerRouteRedirect,
  ReceiptThumbnail: ServerRouteRedirect,
  ReceiptThumbnailAlt: ServerRouteRedirect,
  ReceiptExtraImage: ServerRouteRedirect,
  LoginToArsRedovisningOnline: ServerRouteRedirect,
  ImportSi: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/ImportSi/ImportSiScene");
    },
  }),
  /* Bank */
  BankImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/ImportFromBank/ImportFromBankScene");
    },
  }),
  BankList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankScene");
    },
  }),
  SveaOnboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaOnboardingScene");
    },
  }),
  SveaOnboardingAgreementPdf: ServerRouteRedirect,
  SveaAddInternationalPaymentsScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaAddInternationalPaymentsScene");
    },
  }),
  SveaInternationalPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Preregistrations/SveaPreregistrationListScene");
    },
  }),
  SveaPreregistrationViewScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Preregistrations/SveaPreregistrationViewScene");
    },
  }),
  SveaMatchingScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/PaymentMatching/SveaPaymentMatchingScene");
    },
  }),
  SveaAddSavingsAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaAddSavingsAccountScene");
    },
  }),
  SveaSwishOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Swish/SwishOverviewScene");
    },
  }),
  SveaSwishOnboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Swish/SwishOnboardingScene");
    },
  }),
  SveaInternationalPaymentsPreregistrations: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/InternationalPaymentsV2/SveaInternationalPaymentsScene");
    },
  }),
  SveaInternationalPaymentsPreregistrationDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/InternationalPaymentsV2/Preregistrations/SveaInternationalPaymentsPreregistrationDetailsScene");
    },
  }),
  BankManage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/ManageBankConnection/ManageBankConnectionScene");
    },
  }),
  BankAccountEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankAccountEditImports/BankAccountEditImportsScene");
    },
  }),
  BankAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankAccount/BankAccountScene");
    },
  }),
  BankInbox: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Inbox/SveaInboxScene");
    },
  }),
  SveaInboxAttachmentFile: ServerRouteRedirect,
  BankAccountQrCode: ServerRouteRedirect,
  BankPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/PaymentsCentral/PaymentsCentralScene");
    },
  }),
  BankCreatePayment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/CreatePayment/CreatePaymentScene");
    },
  }),
  BankCreatePaymentSuccess: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/CreatePaymentSuccess/CreatePaymentSuccessScene");
    },
  }),
  BokioBusinessAccountOnboardingSteps: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/MirOnboardingSteps/MirOnboardingStepsScene");
    },
  }),
  MirOnboardingAgreementPdf: ServerRouteRedirect,
  BankStatementPdf: ServerRouteRedirect,
  TransactionDetailsPdf: ServerRouteRedirect,
  BlockedBankList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BlockedBankScene/BlockedBankScene");
    },
  }),
  /* Supplier invoices */
  SuppliersInvoicesAll: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/AllSupplierInvoices/AllSupplierInvoicesScene");
    },
  }),
  SupplierInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierInvoiceList/SupplierInvoiceListScene");
    },
  }),
  SupplierInvoice: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierInvoice/SupplierInvoiceScene");
    },
  }),
  Suppliers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierList/SupplierListScene");
    },
  }),
  SuppliersImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SuppliersImport/SuppliersImport");
    },
  }),
  SupplierInvoiceQrCode: ServerRouteRedirect,
  /* Customer invoices */
  PeriodicReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Reports/PeriodicReport/PeriodicReportScene");
    },
  }),
  InvoicesShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/InvoiceShow/InvoiceShow");
    },
  }),
  InvoicesCreateOrEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/CreateOrEditInvoice/CreateOrEditInvoiceScene");
    },
  }),
  Invoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Invoices/InvoicesScene");
    },
  }),
  Articles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Articles/ArticlesScene");
    },
  }),
  ArticlesImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/ArticlesImport/ArticlesImport");
    },
  }),
  RotRutErrands: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RotRutErrands/RotRutErrands");
    },
  }),
  LogoImageUrl: ServerRouteRedirect,
  FactoringActivation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/FactoringOnboarding/FactoringOnboardingScene");
    },
  }),
  ExportArticles: ServerRouteRedirect,
  CustomerDownload: ServerRouteRedirect,
  InvoiceLatestPdf: ServerRouteRedirect,
  InvoicePdf: ServerRouteRedirect,
  RotXML: ServerRouteRedirect,
  RutXML: ServerRouteRedirect,
  SupplierInvoiceImage: ServerRouteRedirect,
  ExportInvoices: ServerRouteRedirect,
  CustomersImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/CustomersImport/CustomersImport");
    },
  }),
  Customers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Customers/CustomersScene");
    },
  }),
  CustomerInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Customers/CustomerInvoiceListScene");
    },
  }),
  ExportCustomers: ServerRouteRedirect,
  ExportAccountsReceivable: ServerRouteRedirect,
  ExportAccountsPayable: ServerRouteRedirect,
  InvoiceAttachment: ServerRouteRedirect,
  QuotesCreateEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/QuoteCreateEdit/QuoteCreateEditScene");
    },
  }),
  QuoteDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/QuoteDetails/QuoteDetailsScene");
    },
  }),
  Quotes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Quotes/QuotesScene");
    },
  }),
  QuotePdf: ServerRouteRedirect,
  RecurringInvoiceDetailsOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RecurringInvoices/components/RecurringInvoicesDetailsScene/RecurringInvoicesDetailsOverviewScene");
    },
  }),
  RecurringInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RecurringInvoices/RecurringInvoicesScene");
    },
  }),
  /* Expenses */
  Expenses: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/MyExpensesScene/MyExpensesScene");
    },
  }),
  ExpensesUpload: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CreateExpense/UploadExpense/UploadExpenseScene");
    },
  }),
  ExpensesUploadShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CreateExpense/EditExpense/EditExpenseScene");
    },
  }),
  ExpenseReceiptView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpenseReceiptViewScene");
    },
  }),
  ExpensesPaymentsList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpensePayments/AllExpensePaymentsList");
    },
  }),
  ExpensesPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpensePayments/ExpensePayments");
    },
  }),
  ShowExpensePayment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ShowPayment/ShowPaymentScene");
    },
  }),
  ExpensesPermissions: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ConnectEmployees");
    },
  }),
  ExpensesOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/AllExpenses");
    },
  }),
  MyExpenseAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpenseAccounts/ExpenseAccountsScene");
    },
  }),
  CompanyCards: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CompanyCards/CompanyCardsScene");
    },
  }),
  ConvertToExpenses: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ConvertToExpense/ConvertToExpenseScene");
    },
  }),
  /* Salary */
  SalarySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/SalarySettings/SalarySettingsScene");
    },
  }),
  SalaryPayrollPayslipShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/web/scenes/Payslip/Payslip");
    },
  }),
  SalaryPayrollTaxreport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/TaxReport/TaxReport");
    },
  }),
  SalaryTaxreportRedirect: ServerRouteRedirect,
  MyPayslipPdf: ServerRouteRedirect,
  BulkpayslipPdf: ServerRouteRedirect,
  P60Pdf: ServerRouteRedirect,
  P45Pdf: ServerRouteRedirect,
  Salary2Payroll_old: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/PayrollScene/PayrollScene");
    },
  }),
  SalaryPayrollDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/PayrollDetails");
    },
  }),
  SalaryAGIdownload: ServerRouteRedirect,
  SalaryTaxPeriodList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/SalaryTaxPeriods/SalaryTaxPeriodListScene");
    },
  }),
  SalaryTaxPeriodView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/SalaryTaxPeriods/SalaryTaxPeriodViewScene");
    },
  }),
  /* Salary/Absence */
  SalaryAbsense: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "absence" */ "@bokio/salaries/src/scenes/Salary2/Absence/AbsenceScene/AbsenceScene");
    },
  }),
  SalaryAGI: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "absence" */ "@bokio/salaries/src/scenes/Reports/AGI/AGIScene");
    },
  }),
  /* Reports */
  BalanceReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/BalanceReport/BalanceReportScene");
    },
  }),
  BalanceReportAccountPage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/BalanceReport/pages/BalanceReportAccountPage/BalanceReportAccountPage");
    },
  }),
  VatList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatList/VatListScene");
    },
  }),
  VatReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReportSceneSE/VatReportSceneSE");
    },
  }),
  VatReport2: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReportSceneSE/VatReportSceneSE2");
    },
  }),
  VatReportHmrc: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatReportHmrcScene");
    },
  }),
  VatCheck: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatCheck/VatCheckScene");
    },
  }),
  HmrcVatCheck: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatCheck/HmrcVatCheckScene");
    },
  }),
  ResultReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/ResultReport/ResultReportScene");
    },
  }),
  TagReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/TagReport/TagReportScene");
    },
  }),
  DrillDownReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/DrilldownReport/DrilldownReportScene");
    },
  }),
  ResultReportAccountPage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/ResultReport/pages/ResultReportAccountPage/ResultReportAccountPage");
    },
  }),
  LedgerReconcilliation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/LedgerReconcilliation/LedgerReconcilliationScene");
    },
  }),
  LedgerReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/Ledger/LedgerScene");
    },
  }),
  AccountedList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/Accounted/AccountedScene");
    },
  }),
  AccountingCheckShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/AccountingCheckShow/AccountingCheckShowScene");
    },
  }),
  CheckVerifications: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/CheckVerifications/CheckVerificationsScene");
    },
  }),
  VatReportXmlDownload: ServerRouteRedirect,
  BalanceReportPdf: ServerRouteRedirect,
  BalanceReportExcel: ServerRouteRedirect,
  ResultReportPdf: ServerRouteRedirect,
  ResultReportExcel: ServerRouteRedirect,
  LedgerPdf: ServerRouteRedirect,
  TrialBalanceExcel: ServerRouteRedirect,
  LedgerAccountExcel: ServerRouteRedirect,
  InvoicesReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/InvoicesReport/InvoicesReportScene");
    },
  }),
  SalaryReports: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReports/SalaryReportsScene");
    },
  }),
  SalaryReportSalaryTotal: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/SalaryTotal/SalaryTotalScene");
    },
  }),
  SalaryReportVacationSummary: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/VacationSummary/VacationSummaryScene");
    },
  }),
  SalaryReportSickDays: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/SickDays/SickDaysScene");
    },
  }),
  SalaryReportHourlyPaid: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/HourlyPaid/HourlyPaidScene");
    },
  }),
  SalaryReportVacationDebt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/VacationDebtReport/VacationDebtReportScene");
    },
  }),
  AgedDebtorsReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AgedDebtorsReport/AgedDebtorsReportScene");
    },
  }),
  AgedDebtorsReportPdf: ServerRouteRedirect,
  AgedCreditorsReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AgedCreditorsReport/AgedCreditorsReportScene");
    },
  }),
  AccountPayablePdf: ServerRouteRedirect,
  /* EmployeesWithContracts */
  EmployeeList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeList/EmployeeListScene");
    },
  }),
  EmployeeAdd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/AddEmployeeScene");
    },
  }),
  ManageActiveEmployees: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ManageActiveEmployees/ManageActiveEmployeesScene");
    },
  }),
  EmployeeDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/EmployeeDetailsScene");
    },
  }),
  EmployeeVacationTrackingForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/VacationSettingsScene");
    },
  }),
  EmployeeVacationForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/VacationSettingsForm");
    },
  }),
  VacationTracking: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationTracking/VacationTrackingScene");
    },
  }),
  VacationTrackingSetup: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationTrackingSetup/SetupVacationTrackingScene");
    },
  }),
  EmployeeView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeView/EmployeeViewScene");
    },
  }),
  EditDirector: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EditDirector/EditDirectorScene");
    },
  }),
  ContractView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractView/ContractViewScene");
    },
  }),
  ContractAdd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractAddForm/ContractAddFormScene");
    },
  }),
  ContractEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractEditForm/ContractEditFormScene");
    },
  }),
  MyContractView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractView/MyContractViewScene");
    },
  }),
  MyEmployee: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/MyEmployeeView/MyEmployeeViewScene");
    },
  }),
  MyEmployeeSalary: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/MyEmployeeView/MyEmployeeViewScene");
    },
  }),
  EmployeesImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeImport/EmployeesImport");
    },
  }),
  ExportEmployees: ServerRouteRedirect,
  EmployeeDownload: ServerRouteRedirect,
  VacationYearEnd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationYearEnd/VacationYearEndScene");
    },
  }),
  VacationYearEndEmployeeDetail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationYearEnd/VacationYearEndEmployeeDetailScene");
    },
  }),
  GetMyP45: ServerRouteRedirect,
  GetMyP60: ServerRouteRedirect,
  /* Development */
  DeveloperTools: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/DeveloperToolsScene");
    },
  }),
  MockTools: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/StagingMockToolsScene");
    },
  }),
  StagingEnableBankingScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/StagingEnableBankingScene");
    },
  }),
  /* Closures */
  AnnualReportForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/AnnualReport/AnnualReportFormScene");
    },
  }),
  ClosureNEAttachment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureReports/NEAttachment/NEAttachment");
    },
  }),
  ClosuresShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureShow/ClosureShow");
    },
  }),
  AnnualReportArchive: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/AnnualReportArchive/AnnualReportArchiveScene");
    },
  }),
  FetchFiles: ServerRouteRedirect,
  Closures: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureList/ClosureList");
    },
  }),
  /* Todo */
  Todo: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Todo/TodoScene");
    },
  }),
  ImportantDates: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Todo/ImportantDatesScene");
    },
  }),
  PreliminaryTaxes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Taxes/PreliminaryTaxesScene");
    },
  }),
  PreliminaryTaxesPaymentDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Taxes/PreliminaryTaxesPaymentDetailsScene");
    },
  }),
  TodoPromotionAvtal24: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/TodoPromotionScenes/Avtal24Promotion/Avtal24PromotionScene");
    },
  }),
  TodoPromotionMynt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/TodoPromotionScenes/MyntPromotion/MyntPromotionScene");
    },
  }),
  /* Agencies */
  AgencyIndex: ServerRouteRedirect,
  CreateAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/guest/src/scenes/SignUp/CreateAgencyScene");
    },
    narrowPage: true
  }),
  PreCreateAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/guest/src/scenes/SignUp/CreateAgencyPreScene");
    },
    narrowPage: true
  }),
  AgencyOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyOverview/AgencyOverviewScene");
    },
  }),
  AgencyPartnerInfo: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyPartnerInfo/AgencyPartnerInfoScene");
    },
  }),
  AgencyClients: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClients/AgencyClientsScene");
    },
  }),
  AgencyUsers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyUsers/AgencyUsersScene");
    },
  }),
  AgencyNotes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotes/AgencyNotesScene");
    },
  }),
  AgencyClientDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClientDetails/AgencyClientDetailsScene");
    },
  }),
  ExportNotes: ServerRouteRedirect,
  AgencyPartnerContractPdf: ServerRouteRedirect,
  AgencySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencySettings/AgencySettingsScene");
    },
  }),
  AgencyImportClients: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyImportClients/AgencyImportClientsScene");
    },
  }),
  AgencyClientRequests: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClientRequests/AgencyClientRequestsScene");
    },
  }),
  AgencyDetailsScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyDetails/AgencyDetailsScene");
    },
  }),
  AgencyNotificationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotificationSettings/AgencyNotificationSettingsScene");
    },
  }),
  AgencyExport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyExport/AgencyExportScene");
    },
  }),
  LeaveAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/LeaveAgency/LeaveAgencyScene");
    },
  }),
  DeleteAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/DeleteAgency/DeleteAgencyScene");
    },
  }),
  BackofficeFeatureToggles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/BackofficeFeatureToggles/BackofficeFeatureTogglesScene");
    },
  }),
  AgencyPartnerProgram: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyPartnerProgram/AgencyPartnerProgramScene");
    },
  }),
  AgencyQuoteRequestsList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyQuoteRequests/AgencyQuoteRequestsListScene");
    },
  }),
  AgencyQuoteRequest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyQuoteRequests/AgencyQuoteRequestScene");
    },
  }),
  AgencyNotificationRedirect: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotificationRedirect/AgencyNotificationRedirectScene");
    },
  }),
  /* BackOffice */
  BackOfficeAcceptInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/BackOfficeAcceptInvitation/BackOfficeAcceptInvitationScene");
    },
  }),
  RecordingSupport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/RecordingSupport/RecordingSupportScene");
    },
  }),
  SupportTicketReceiptImage: ServerRouteRedirect,
  SupportTicketReceiptExtraPage: ServerRouteRedirect,
  BackOffice: () => <>{/* Mounts only the route layout wrapper */}</>,
  BackOfficeCompaniesDashboard: () => <>{/* Mounts only the route layout wrapper */}</>,
  BackOfficeAdminAgencies: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/BackOfficeAdminAgencies/BackOfficeAdminAgenciesScene");
    },
    narrowPage: true
  }),
  BackOfficeAdminAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/BackOfficeAdminAgency/BackOfficeAdminAgencyScene");
    },
    narrowPage: true
  }),
  BackOfficeUsers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/Users/UsersScene");
    },
    narrowPage: true
  }),
  /* Companies */
  Root: ServerRouteRedirect,
  CompanyIndex: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/SelectCompany/SelectCompanyDeepLinkScene");
    },
  }),
  SelectCompanyFromMail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/SelectCompany/SelectCompanyFromMailScene");
    },
  }),
  SelectCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/SelectCompany/SelectCompanyScene");
    },
  }),
  Dashboard: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/Overview/OverviewScene");
    },
  }),
  /* Services */
  AccountingServices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/AccountingServices/AccountingServicesScene");
    },
  }),
  SubmitAgencyInterest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/PartnerOnboarding/AgencyRequestScene");
    },
  }),
  BusinessServices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/BusinessServices/BusinessServicesScene");
    },
  }),
  /* Accounting */
  BankTransactions: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "accounting" */ "@bokio/bookkeeping/src/scenes/BankTransactions/BankTransactionsScene");
    },
  }),
  AssetsManagement: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "accounting" */ "@bokio/bookkeeping/src/scenes/AssetManagement/AssetManagementScene");
    },
  }),
  /* Uploads */
  Uploads: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "uploads" */ "@bokio/uploads/src/scenes/UploadsScene");
    },
  }),
  DownloadAdditionalFile: ServerRouteRedirect,
  DownloadAdditionalFilesAsZip: ServerRouteRedirect,
  /* Integrations */
  Integrations: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/Integrations/IntegrationsScene");
    },
  }),
  Stripe: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/Stripe/StripeScene");
    },
  }),
  StripeCallback: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/OauthStripeCallback/OauthStripeCallbackScene");
    },
  }),
  StripeOnboardingMock: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/StripeMockOnboarding/StripeMockOnboardingScene");
    },
  }),
  /* CustomerPortal */
  InvoicePortal: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "customerportal" */ "@bokio/customerportal/src/scenes/InvoicePortal/InvoicePortalScene");
    },
  }),
  InvoicePortalPdf: ServerRouteRedirect,
  /* DirectorsPayroll */
  EmployerDirectorSetup: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/employeeswithcontracts/src/scenes/DirectorsPayroll/EmployerDirectorSetupScene");
    },
  }),
  DirectorsPayrollList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/directorspayroll/src/scenes/DirectorsPayroll/DirectorsPayrollScene");
    },
  }),
  DirectorsPayrollDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/directorspayroll/src/scenes/DirectorsPayrollDetails/DirectorsPayrollDetailsScene");
    },
  }),
  AddDirector: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/employeeswithcontracts/src/scenes/AddDirector/AddDirectorScene");
    },
  }),
  /* Sales */
  DailyTakings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sales" */ "@bokio/sales/src/scenes/DailyTakings/DailyTakingsScene");
    },
  })
}

export const Scenes = () => {
	return <React.Fragment>          
          {/* Signup */}
          <Route path={routes.signUp} element={<scenes.SignUp />} />
          <Route path={routes.signUpAccountant} element={<scenes.SignUpAccountant />} />
          <Route path={routes.login} element={<scenes.Login />} />
          <Route path={routes.connectAgency} element={<scenes.ConnectAgency />} />
          <Route path={routes.forgotPassword} element={<scenes.ForgotPassword />} />
          <Route path={routes.resetPassword} element={<scenes.ResetPassword />} />
          <Route path={routes.acceptInvitation} element={<scenes.AcceptInvitation />} />
          <Route path={routes.acceptCompanyOwnerInvitation} element={<scenes.AcceptCompanyOwnerInvitation />} />
          <Route path={routes.confirmEmail} element={<scenes.ConfirmEmail />} />
          <Route path={routes.integrationTests} element={<scenes.IntegrationTests />} />
          <Route path={routes.voucherFlow} element={<scenes.VoucherFlow />} />
          <Route path={routes.bokioCreateCompanyKYC} element={<scenes.BokioCreateCompanyKYC />} />
          
          {/* Settings */}
          <Route path={routes.createCompany} element={<UserRoute><scenes.CreateCompany /></UserRoute>} />
          <Route path={routes.preCreateClient} element={<UserRoute><scenes.PreCreateClient /></UserRoute>} />
          <Route path={routes.settingsImportCompany} element={<CompanyRoute><scenes.SettingsImportCompany /></CompanyRoute>} />
          <Route path={routes.settingsImportOverview} element={<CompanyRoute><scenes.SettingsImportOverview /></CompanyRoute>} />
          <Route path={routes.fiscalList} element={<CompanyRoute><scenes.FiscalList /></CompanyRoute>} />
          <Route path={routes.fiscalEdit} element={<CompanyRoute><scenes.FiscalEdit /></CompanyRoute>} />
          <Route path={routes.fiscalCreate} element={<CompanyRoute><scenes.FiscalCreate /></CompanyRoute>} />
          <Route path={routes.lockPeriod} element={<CompanyRoute><scenes.LockPeriod /></CompanyRoute>} />
          <Route path={routes.accountPlan} element={<CompanyRoute><scenes.AccountPlan /></CompanyRoute>} />
          <Route path={routes.mapAccounts} element={<CompanyRoute><scenes.MapAccounts /></CompanyRoute>} />
          <Route path={routes.invoiceSettings} element={<CompanyRoute><scenes.InvoiceSettings /></CompanyRoute>} />
          <Route path={routes.eInvoiceSettings} element={<CompanyRoute><scenes.EInvoiceSettings /></CompanyRoute>} />
          <Route path={routes.automaticCheckingSettings} element={<CompanyRoute><scenes.AutomaticCheckingSettings /></CompanyRoute>} />
          <Route path={routes.paymentMethodSettings} element={<CompanyRoute><scenes.PaymentMethodSettings /></CompanyRoute>} />
          <Route path={routes.settingsOverview} element={<CompanyRoute><scenes.SettingsOverview /></CompanyRoute>} />
          <Route path={routes.personalSettings} element={<UserRoute><scenes.PersonalSettings /></UserRoute>} />
          <Route path={routes.companyDataStorage} element={<UserRoute><scenes.CompanyDataStorage /></UserRoute>} />
          <Route path={routes.totpActivate} element={<UserRoute><scenes.TotpActivate /></UserRoute>} />
          <Route path={routes.totpDeactivate} element={<UserRoute><scenes.TotpDeactivate /></UserRoute>} />
          <Route path={routes.totpSettings} element={<UserRoute><scenes.TotpSettings /></UserRoute>} />
          <Route path={routes.totpRecoveryDocument} element={<scenes.TotpRecoveryDocument />} />
          <Route path={routes.recentLogins} element={<UserRoute><scenes.RecentLogins /></UserRoute>} />
          <Route path={routes.logOutEverywhere} element={<UserRoute><scenes.LogOutEverywhere /></UserRoute>} />
          <Route path={routes.bankidSettings} element={<UserRoute><scenes.BankidSettings /></UserRoute>} />
          <Route path={routes.bankidActivate} element={<UserRoute><scenes.BankidActivate /></UserRoute>} />
          <Route path={routes.bankidDeactivate} element={<UserRoute><scenes.BankidDeactivate /></UserRoute>} />
          <Route path={routes.bankIdRequired} element={<UserRoute><scenes.BankIdRequired /></UserRoute>} />
          <Route path={routes.updateUsername} element={<UserRoute><scenes.UpdateUsername /></UserRoute>} />
          <Route path={routes.confirmUpdateUsername} element={<UserRoute><scenes.ConfirmUpdateUsername /></UserRoute>} />
          <Route path={routes.moneyAccounts} element={<CompanyRoute><scenes.MoneyAccounts /></CompanyRoute>} />
          <Route path={routes.tagTypes} element={<CompanyRoute><scenes.TagTypes /></CompanyRoute>} />
          <Route path={routes.exportBookkeeping} element={<CompanyRoute><scenes.ExportBookkeeping /></CompanyRoute>} />
          <Route path={routes.openingBalances} element={<CompanyRoute><scenes.OpeningBalances /></CompanyRoute>} />
          <Route path={routes.accountPayables} element={<CompanyRoute><scenes.AccountPayables /></CompanyRoute>} />
          <Route path={routes.accountReceivables} element={<CompanyRoute><scenes.AccountReceivables /></CompanyRoute>} />
          <Route path={routes.layoutLogoColor} element={<CompanyRoute><scenes.LayoutLogoColor /></CompanyRoute>} />
          <Route path={routes.companySettings} element={<CompanyRoute><scenes.CompanySettings /></CompanyRoute>} />
          <Route path={routes.changeCompanySystem} element={<CompanyRoute><scenes.ChangeCompanySystem /></CompanyRoute>} />
          <Route path={routes.deleteCompany} element={<CompanyRoute><scenes.DeleteCompany /></CompanyRoute>} />
          <Route path={routes.leaveCompany} element={<CompanyRoute><scenes.LeaveCompany /></CompanyRoute>} />
          <Route path={routes.deleteUserAccount} element={<UserRoute><scenes.DeleteUserAccount /></UserRoute>} />
          <Route path={routes.featureToggles} element={<CompanyRoute><scenes.FeatureToggles /></CompanyRoute>} />
          <Route path={routes.agreements} element={<scenes.Agreements />} />
          <Route path={routes.signTerms} element={<UserRoute><scenes.SignTerms /></UserRoute>} />
          <Route path={routes.settings} element={<scenes.Settings />} />
          <Route path={routes.companySetting} element={<scenes.CompanySetting />} />
          <Route path={routes.inbox} element={<CompanyRoute><scenes.Inbox /></CompanyRoute>} />
          <Route path={routes.inboxHistory} element={<CompanyRoute><scenes.InboxHistory /></CompanyRoute>} />
          <Route path={routes.inboxAttachmentDownload} element={<scenes.InboxAttachmentDownload />} />
          <Route path={routes.exportData} element={<CompanyRoute><scenes.ExportData /></CompanyRoute>} />
          <Route path={routes.users} element={<CompanyRoute><scenes.Users /></CompanyRoute>} />
          <Route path={routes.changePassword} element={<UserRoute><scenes.ChangePassword /></UserRoute>} />
          <Route path={routes.auditHistory} element={<CompanyRoute><scenes.AuditHistory /></CompanyRoute>} />
          <Route path={routes.exportAuditHistory} element={<scenes.ExportAuditHistory />} />
          <Route path={routes.companyVacationSettings} element={<CompanyRoute><scenes.CompanyVacationSettings /></CompanyRoute>} />
          <Route path={routes.placeOfEmploymentSettings} element={<CompanyRoute><scenes.PlaceOfEmploymentSettings /></CompanyRoute>} />
          <Route path={routes.salarySettingUK} element={<CompanyRoute><scenes.SalarySettingUK /></CompanyRoute>} />
          <Route path={routes.activateVoucher} element={<CompanyRoute><scenes.ActivateVoucher /></CompanyRoute>} />
          <Route path={routes.billing} element={<CompanyRoute><scenes.Billing /></CompanyRoute>} />
          <Route path={routes.viewBill} element={<CompanyRoute><scenes.ViewBill /></CompanyRoute>} />
          <Route path={routes.downloadBillingReceiptPdf} element={<scenes.DownloadBillingReceiptPdf />} />
          <Route path={routes.cardList} element={<CompanyRoute><scenes.CardList /></CompanyRoute>} />
          <Route path={routes.card} element={<CompanyRoute><scenes.Card /></CompanyRoute>} />
          <Route path={routes.orderCard} element={<CompanyRoute><scenes.OrderCard /></CompanyRoute>} />
          <Route path={routes.supportMessages} element={<CompanyRoute><scenes.SupportMessages /></CompanyRoute>} />
          <Route path={routes.bokioBusinessAccountOffboarding} element={<CompanyRoute><scenes.BokioBusinessAccountOffboarding /></CompanyRoute>} />
          <Route path={routes.sveaBankConsent} element={<CompanyRoute><scenes.SveaBankConsent /></CompanyRoute>} />
          <Route path={routes.sveaKycScene} element={<CompanyRoute><scenes.SveaKycScene /></CompanyRoute>} />
          <Route path={routes.notificationSettings} element={<CompanyRoute><scenes.NotificationSettings /></CompanyRoute>} />
          <Route path={routes.notificationRedirect} element={<CompanyRoute><scenes.NotificationRedirect /></CompanyRoute>} />
          <Route path={routes.exportSIEFile} element={<scenes.ExportSIEFile />} />
          <Route path={routes.customTemplates} element={<CompanyRoute><scenes.CustomTemplates /></CompanyRoute>} />
          <Route path={routes.automationRules} element={<CompanyRoute><scenes.AutomationRules /></CompanyRoute>} />
          <Route path={routes.mirChangeSignatory} element={<CompanyRoute><scenes.MirChangeSignatory /></CompanyRoute>} />
          <Route path={routes.removeImport} element={<CompanyRoute><scenes.RemoveImport /></CompanyRoute>} />
          
          {/* Bookkeeping */}
          <Route path={routes.hmrcAuth} element={<UserRoute><scenes.HmrcAuth /></UserRoute>} />
          <Route path={routes.accounting} element={<scenes.Accounting />} />
          <Route path={routes.preBookkeep} element={<CompanyRoute><scenes.PreBookkeep /></CompanyRoute>} />
          <Route path={routes.preBookkeepSupplierInvoice} element={<CompanyRoute><scenes.PreBookkeep /></CompanyRoute>} />
          <Route path={routes.bookkeepReceipt} element={<CompanyRoute><scenes.BookkeepReceipt /></CompanyRoute>} />
          <Route path={routes.recordingCheckList} element={<CompanyRoute><scenes.RecordingCheckList /></CompanyRoute>} />
          <Route path={routes.recordingCheckCreate} element={<CompanyRoute><scenes.RecordingCheckCreate /></CompanyRoute>} />
          <Route path={routes.recordingCheckView} element={<CompanyRoute><scenes.RecordingCheckView /></CompanyRoute>} />
          <Route path={routes.bookkeepExpense} element={<CompanyRoute><scenes.BookkeepExpense /></CompanyRoute>} />
          <Route path={routes.bookkeepSupplier} element={<CompanyRoute><scenes.BookkeepSupplier /></CompanyRoute>} />
          <Route path={routes.bookkeepTransaction} element={<CompanyRoute><scenes.BookkeepTransaction /></CompanyRoute>} />
          <Route path={routes.editVerification} element={<CompanyRoute><scenes.EditVerification /></CompanyRoute>} />
          <Route path={routes.attest} element={<CompanyRoute><scenes.Attest /></CompanyRoute>} />
          <Route path={routes.eventsToRecord} element={<CompanyRoute><scenes.EventsToRecord /></CompanyRoute>} />
          <Route path={routes.verificationAndTransactionMatching} element={<CompanyRoute><scenes.VerificationAndTransactionMatching /></CompanyRoute>} />
          <Route path={routes.receiptImage} element={<scenes.ReceiptImage />} />
          <Route path={routes.downloadReceipt} element={<scenes.DownloadReceipt />} />
          <Route path={routes.receiptThumbnail} element={<scenes.ReceiptThumbnail />} />
          <Route path={routes.receiptThumbnailAlt} element={<scenes.ReceiptThumbnailAlt />} />
          <Route path={routes.receiptExtraImage} element={<scenes.ReceiptExtraImage />} />
          <Route path={routes.loginToArsRedovisningOnline} element={<scenes.LoginToArsRedovisningOnline />} />
          <Route path={routes.importSi} element={<CompanyRoute><scenes.ImportSi /></CompanyRoute>} />
          
          {/* Bank */}
          <Route path={routes.bankImport} element={<CompanyRoute><scenes.BankImport /></CompanyRoute>} />
          <Route path={routes.bankList} element={<CompanyRoute><scenes.BankList /></CompanyRoute>} />
          <Route path={routes.sveaOnboarding} element={<CompanyRoute><scenes.SveaOnboarding /></CompanyRoute>} />
          <Route path={routes.sveaOnboardingAgreementPdf} element={<scenes.SveaOnboardingAgreementPdf />} />
          <Route path={routes.sveaAddInternationalPaymentsScene} element={<CompanyRoute><scenes.SveaAddInternationalPaymentsScene /></CompanyRoute>} />
          <Route path={routes.sveaInternationalPayments} element={<CompanyRoute><scenes.SveaInternationalPayments /></CompanyRoute>} />
          <Route path={routes.sveaPreregistrationViewScene} element={<CompanyRoute><scenes.SveaPreregistrationViewScene /></CompanyRoute>} />
          <Route path={routes.sveaMatchingScene} element={<CompanyRoute><scenes.SveaMatchingScene /></CompanyRoute>} />
          <Route path={routes.sveaAddSavingsAccount} element={<CompanyRoute><scenes.SveaAddSavingsAccount /></CompanyRoute>} />
          <Route path={routes.sveaSwishOverview} element={<CompanyRoute><scenes.SveaSwishOverview /></CompanyRoute>} />
          <Route path={routes.sveaSwishOnboarding} element={<CompanyRoute><scenes.SveaSwishOnboarding /></CompanyRoute>} />
          <Route path={routes.sveaInternationalPaymentsPreregistrations} element={<CompanyRoute><scenes.SveaInternationalPaymentsPreregistrations /></CompanyRoute>} />
          <Route path={routes.sveaInternationalPaymentsPreregistrationDetails} element={<CompanyRoute><scenes.SveaInternationalPaymentsPreregistrationDetails /></CompanyRoute>} />
          <Route path={routes.bankManage} element={<CompanyRoute><scenes.BankManage /></CompanyRoute>} />
          <Route path={routes.bankAccountEdit} element={<CompanyRoute><scenes.BankAccountEdit /></CompanyRoute>} />
          <Route path={routes.bankAccount} element={<CompanyRoute><scenes.BankAccount /></CompanyRoute>} />
          <Route path={routes.bankInbox} element={<CompanyRoute><scenes.BankInbox /></CompanyRoute>} />
          <Route path={routes.sveaInboxAttachmentFile} element={<scenes.SveaInboxAttachmentFile />} />
          <Route path={routes.bankAccountQrCode} element={<scenes.BankAccountQrCode />} />
          <Route path={routes.bankPayments} element={<CompanyRoute><scenes.BankPayments /></CompanyRoute>} />
          <Route path={routes.bankCreatePayment} element={<CompanyRoute><scenes.BankCreatePayment /></CompanyRoute>} />
          <Route path={routes.bankCreatePaymentSuccess} element={<CompanyRoute><scenes.BankCreatePaymentSuccess /></CompanyRoute>} />
          <Route path={routes.bokioBusinessAccountOnboardingSteps} element={<CompanyRoute><scenes.BokioBusinessAccountOnboardingSteps /></CompanyRoute>} />
          <Route path={routes.mirOnboardingAgreementPdf} element={<scenes.MirOnboardingAgreementPdf />} />
          <Route path={routes.bankStatementPdf} element={<scenes.BankStatementPdf />} />
          <Route path={routes.transactionDetailsPdf} element={<scenes.TransactionDetailsPdf />} />
          <Route path={routes.blockedBankList} element={<CompanyRoute><scenes.BlockedBankList /></CompanyRoute>} />
          
          {/* Supplier invoices */}
          <Route path={routes.suppliersInvoicesAll} element={<CompanyRoute><scenes.SuppliersInvoicesAll /></CompanyRoute>} />
          <Route path={routes.supplierInvoices} element={<CompanyRoute><scenes.SupplierInvoices /></CompanyRoute>} />
          <Route path={routes.supplierInvoice} element={<CompanyRoute><scenes.SupplierInvoice /></CompanyRoute>} />
          <Route path={routes.suppliers} element={<CompanyRoute><scenes.Suppliers /></CompanyRoute>} />
          <Route path={routes.suppliersImport} element={<CompanyRoute><scenes.SuppliersImport /></CompanyRoute>} />
          <Route path={routes.supplierInvoiceQrCode} element={<scenes.SupplierInvoiceQrCode />} />
          
          {/* Customer invoices */}
          <Route path={routes.periodicReport} element={<CompanyRoute><scenes.PeriodicReport /></CompanyRoute>} />
          <Route path={routes.invoicesShow} element={<CompanyRoute><scenes.InvoicesShow /></CompanyRoute>} />
          <Route path={routes.invoicesCreate} element={<CompanyRoute><scenes.InvoicesCreateOrEdit /></CompanyRoute>} />
          <Route path={routes.invoicesEdit} element={<CompanyRoute><scenes.InvoicesCreateOrEdit /></CompanyRoute>} />
          <Route path={routes.invoices} element={<CompanyRoute><scenes.Invoices /></CompanyRoute>} />
          <Route path={routes.articles} element={<CompanyRoute><scenes.Articles /></CompanyRoute>} />
          <Route path={routes.articlesImport} element={<CompanyRoute><scenes.ArticlesImport /></CompanyRoute>} />
          <Route path={routes.rotRutErrands} element={<CompanyRoute><scenes.RotRutErrands /></CompanyRoute>} />
          <Route path={routes.logoImageUrl} element={<scenes.LogoImageUrl />} />
          <Route path={routes.factoringActivation} element={<CompanyRoute><scenes.FactoringActivation /></CompanyRoute>} />
          <Route path={routes.exportArticles} element={<scenes.ExportArticles />} />
          <Route path={routes.customerDownload} element={<scenes.CustomerDownload />} />
          <Route path={routes.invoiceLatestPdf} element={<scenes.InvoiceLatestPdf />} />
          <Route path={routes.invoicePdf} element={<scenes.InvoicePdf />} />
          <Route path={routes.rotXML} element={<scenes.RotXML />} />
          <Route path={routes.rutXML} element={<scenes.RutXML />} />
          <Route path={routes.supplierInvoiceImage} element={<scenes.SupplierInvoiceImage />} />
          <Route path={routes.exportInvoices} element={<scenes.ExportInvoices />} />
          <Route path={routes.customersImport} element={<CompanyRoute><scenes.CustomersImport /></CompanyRoute>} />
          <Route path={routes.customers} element={<CompanyRoute><scenes.Customers /></CompanyRoute>} />
          <Route path={routes.customerInvoices} element={<CompanyRoute><scenes.CustomerInvoices /></CompanyRoute>} />
          <Route path={routes.exportCustomers} element={<scenes.ExportCustomers />} />
          <Route path={routes.exportAccountsReceivable} element={<scenes.ExportAccountsReceivable />} />
          <Route path={routes.exportAccountsPayable} element={<scenes.ExportAccountsPayable />} />
          <Route path={routes.invoiceAttachment} element={<scenes.InvoiceAttachment />} />
          <Route path={routes.quotesCreate} element={<CompanyRoute><scenes.QuotesCreateEdit /></CompanyRoute>} />
          <Route path={routes.quotesEdit} element={<CompanyRoute><scenes.QuotesCreateEdit /></CompanyRoute>} />
          <Route path={routes.quoteDetails} element={<CompanyRoute><scenes.QuoteDetails /></CompanyRoute>} />
          <Route path={routes.quotes} element={<CompanyRoute><scenes.Quotes /></CompanyRoute>} />
          <Route path={routes.quotePdf} element={<scenes.QuotePdf />} />
          <Route path={routes.recurringInvoiceDetailsOverview} element={<CompanyRoute><scenes.RecurringInvoiceDetailsOverview /></CompanyRoute>} />
          <Route path={routes.recurringInvoices} element={<CompanyRoute><scenes.RecurringInvoices /></CompanyRoute>} />
          
          {/* Expenses */}
          <Route path={routes.expenses} element={<CompanyRoute><scenes.Expenses /></CompanyRoute>} />
          <Route path={routes.expensesUpload} element={<CompanyRoute><scenes.ExpensesUpload /></CompanyRoute>} />
          <Route path={routes.expensesUploadShow} element={<CompanyRoute><scenes.ExpensesUploadShow /></CompanyRoute>} />
          <Route path={routes.expenseReceiptView} element={<CompanyRoute><scenes.ExpenseReceiptView /></CompanyRoute>} />
          <Route path={routes.expensesPaymentsList} element={<CompanyRoute><scenes.ExpensesPaymentsList /></CompanyRoute>} />
          <Route path={routes.expensesPayments} element={<CompanyRoute><scenes.ExpensesPayments /></CompanyRoute>} />
          <Route path={routes.showExpensePayment} element={<CompanyRoute><scenes.ShowExpensePayment /></CompanyRoute>} />
          <Route path={routes.expensesPermissions} element={<CompanyRoute><scenes.ExpensesPermissions /></CompanyRoute>} />
          <Route path={routes.expensesOverview} element={<CompanyRoute><scenes.ExpensesOverview /></CompanyRoute>} />
          <Route path={routes.myExpenseAccounts} element={<CompanyRoute><scenes.MyExpenseAccounts /></CompanyRoute>} />
          <Route path={routes.companyCards} element={<CompanyRoute><scenes.CompanyCards /></CompanyRoute>} />
          <Route path={routes.convertToExpenses} element={<CompanyRoute><scenes.ConvertToExpenses /></CompanyRoute>} />
          
          {/* Salary */}
          <Route path={routes.salarySettings} element={<CompanyRoute><scenes.SalarySettings /></CompanyRoute>} />
          <Route path={routes.salaryPayrollPayslipShow} element={<CompanyRoute><scenes.SalaryPayrollPayslipShow /></CompanyRoute>} />
          <Route path={routes.salaryPayrollTaxreport} element={<CompanyRoute><scenes.SalaryPayrollTaxreport /></CompanyRoute>} />
          <Route path={routes.salaryTaxreportRedirect} element={<scenes.SalaryTaxreportRedirect />} />
          <Route path={routes.myPayslipPdf} element={<scenes.MyPayslipPdf />} />
          <Route path={routes.bulkpayslipPdf} element={<scenes.BulkpayslipPdf />} />
          <Route path={routes.p60Pdf} element={<scenes.P60Pdf />} />
          <Route path={routes.P45Pdf} element={<scenes.P45Pdf />} />
          <Route path={routes.salary2Payroll_old} element={<CompanyRoute><scenes.Salary2Payroll_old /></CompanyRoute>} />
          <Route path={routes.salaryPayrollDetails} element={<CompanyRoute><scenes.SalaryPayrollDetails /></CompanyRoute>} />
          <Route path={routes.salaryAGIdownload} element={<scenes.SalaryAGIdownload />} />
          <Route path={routes.salaryTaxPeriodList} element={<CompanyRoute><scenes.SalaryTaxPeriodList /></CompanyRoute>} />
          <Route path={routes.salaryTaxPeriodView} element={<CompanyRoute><scenes.SalaryTaxPeriodView /></CompanyRoute>} />
          
          {/* Salary/Absence */}
          <Route path={routes.salaryAbsense} element={<CompanyRoute><scenes.SalaryAbsense /></CompanyRoute>} />
          <Route path={routes.salaryAGI} element={<CompanyRoute><scenes.SalaryAGI /></CompanyRoute>} />
          
          {/* Reports */}
          <Route path={routes.balanceReport} element={<CompanyRoute><scenes.BalanceReport /></CompanyRoute>} />
          <Route path={routes.balanceReportAccountPage} element={<CompanyRoute><scenes.BalanceReportAccountPage /></CompanyRoute>} />
          <Route path={routes.vatList} element={<CompanyRoute><scenes.VatList /></CompanyRoute>} />
          <Route path={routes.vatReport} element={<CompanyRoute><scenes.VatReport /></CompanyRoute>} />
          <Route path={routes.vatReport2} element={<CompanyRoute><scenes.VatReport2 /></CompanyRoute>} />
          <Route path={routes.vatReportHmrc} element={<CompanyRoute><scenes.VatReportHmrc /></CompanyRoute>} />
          <Route path={routes.vatCheck} element={<CompanyRoute><scenes.VatCheck /></CompanyRoute>} />
          <Route path={routes.hmrcVatCheck} element={<CompanyRoute><scenes.HmrcVatCheck /></CompanyRoute>} />
          <Route path={routes.resultReport} element={<CompanyRoute><scenes.ResultReport /></CompanyRoute>} />
          <Route path={routes.tagReport} element={<CompanyRoute><scenes.TagReport /></CompanyRoute>} />
          <Route path={routes.drillDownReport} element={<CompanyRoute><scenes.DrillDownReport /></CompanyRoute>} />
          <Route path={routes.resultReportAccountPage} element={<CompanyRoute><scenes.ResultReportAccountPage /></CompanyRoute>} />
          <Route path={routes.ledgerReconcilliation} element={<CompanyRoute><scenes.LedgerReconcilliation /></CompanyRoute>} />
          <Route path={routes.ledgerReport} element={<CompanyRoute><scenes.LedgerReport /></CompanyRoute>} />
          <Route path={routes.accountedList} element={<CompanyRoute><scenes.AccountedList /></CompanyRoute>} />
          <Route path={routes.accountingCheckShow} element={<CompanyRoute><scenes.AccountingCheckShow /></CompanyRoute>} />
          <Route path={routes.checkVerifications} element={<CompanyRoute><scenes.CheckVerifications /></CompanyRoute>} />
          <Route path={routes.vatReportXmlDownload} element={<scenes.VatReportXmlDownload />} />
          <Route path={routes.balanceReportPdf} element={<scenes.BalanceReportPdf />} />
          <Route path={routes.balanceReportExcel} element={<scenes.BalanceReportExcel />} />
          <Route path={routes.resultReportPdf} element={<scenes.ResultReportPdf />} />
          <Route path={routes.resultReportExcel} element={<scenes.ResultReportExcel />} />
          <Route path={routes.ledgerPdf} element={<scenes.LedgerPdf />} />
          <Route path={routes.trialBalanceExcel} element={<scenes.TrialBalanceExcel />} />
          <Route path={routes.ledgerAccountExcel} element={<scenes.LedgerAccountExcel />} />
          <Route path={routes.invoicesReport} element={<CompanyRoute><scenes.InvoicesReport /></CompanyRoute>} />
          <Route path={routes.salaryReports} element={<CompanyRoute><scenes.SalaryReports /></CompanyRoute>} />
          <Route path={routes.salaryReportSalaryTotal} element={<CompanyRoute><scenes.SalaryReportSalaryTotal /></CompanyRoute>} />
          <Route path={routes.salaryReportVacationSummary} element={<CompanyRoute><scenes.SalaryReportVacationSummary /></CompanyRoute>} />
          <Route path={routes.salaryReportSickDays} element={<CompanyRoute><scenes.SalaryReportSickDays /></CompanyRoute>} />
          <Route path={routes.salaryReportHourlyPaid} element={<CompanyRoute><scenes.SalaryReportHourlyPaid /></CompanyRoute>} />
          <Route path={routes.salaryReportVacationDebt} element={<CompanyRoute><scenes.SalaryReportVacationDebt /></CompanyRoute>} />
          <Route path={routes.agedDebtorsReport} element={<CompanyRoute><scenes.AgedDebtorsReport /></CompanyRoute>} />
          <Route path={routes.agedDebtorsReportPdf} element={<scenes.AgedDebtorsReportPdf />} />
          <Route path={routes.agedCreditorsReport} element={<CompanyRoute><scenes.AgedCreditorsReport /></CompanyRoute>} />
          <Route path={routes.accountPayablePdf} element={<scenes.AccountPayablePdf />} />
          
          {/* EmployeesWithContracts */}
          <Route path={routes.employeeList} element={<CompanyRoute><scenes.EmployeeList /></CompanyRoute>} />
          <Route path={routes.employeeAdd} element={<CompanyRoute><scenes.EmployeeAdd /></CompanyRoute>} />
          <Route path={routes.manageActiveEmployees} element={<CompanyRoute><scenes.ManageActiveEmployees /></CompanyRoute>} />
          <Route path={routes.employeeDetails} element={<CompanyRoute><scenes.EmployeeDetails /></CompanyRoute>} />
          <Route path={routes.employeeVacationTrackingForm} element={<CompanyRoute><scenes.EmployeeVacationTrackingForm /></CompanyRoute>} />
          <Route path={routes.employeeVacationForm} element={<CompanyRoute><scenes.EmployeeVacationForm /></CompanyRoute>} />
          <Route path={routes.vacationTracking} element={<CompanyRoute><scenes.VacationTracking /></CompanyRoute>} />
          <Route path={routes.vacationTrackingSetup} element={<CompanyRoute><scenes.VacationTrackingSetup /></CompanyRoute>} />
          <Route path={routes.employeeView} element={<CompanyRoute><scenes.EmployeeView /></CompanyRoute>} />
          <Route path={routes.editDirector} element={<CompanyRoute><scenes.EditDirector /></CompanyRoute>} />
          <Route path={routes.contractView} element={<CompanyRoute><scenes.ContractView /></CompanyRoute>} />
          <Route path={routes.contractAdd} element={<CompanyRoute><scenes.ContractAdd /></CompanyRoute>} />
          <Route path={routes.contractEdit} element={<CompanyRoute><scenes.ContractEdit /></CompanyRoute>} />
          <Route path={routes.myContractView} element={<CompanyRoute><scenes.MyContractView /></CompanyRoute>} />
          <Route path={routes.myEmployee} element={<CompanyRoute><scenes.MyEmployee /></CompanyRoute>} />
          <Route path={routes.myEmployeeSalary} element={<CompanyRoute><scenes.MyEmployeeSalary /></CompanyRoute>} />
          <Route path={routes.employeesImport} element={<CompanyRoute><scenes.EmployeesImport /></CompanyRoute>} />
          <Route path={routes.exportEmployees} element={<scenes.ExportEmployees />} />
          <Route path={routes.employeeDownload} element={<scenes.EmployeeDownload />} />
          <Route path={routes.vacationYearEnd} element={<CompanyRoute><scenes.VacationYearEnd /></CompanyRoute>} />
          <Route path={routes.vacationYearEndEmployeeDetail} element={<CompanyRoute><scenes.VacationYearEndEmployeeDetail /></CompanyRoute>} />
          <Route path={routes.GetMyP45} element={<scenes.GetMyP45 />} />
          <Route path={routes.GetMyP60} element={<scenes.GetMyP60 />} />
          
          {/* Development */}
          <Route path={routes.developerTools} element={<CompanyRoute><scenes.DeveloperTools /></CompanyRoute>} />
          <Route path={routes.mockTools} element={<CompanyRoute><scenes.MockTools /></CompanyRoute>} />
          <Route path={routes.stagingEnableBankingScene} element={<CompanyRoute><scenes.StagingEnableBankingScene /></CompanyRoute>} />
          
          {/* Closures */}
          <Route path={routes.annualReportForm} element={<CompanyRoute><scenes.AnnualReportForm /></CompanyRoute>} />
          <Route path={routes.closureNEAttachment} element={<CompanyRoute><scenes.ClosureNEAttachment /></CompanyRoute>} />
          <Route path={routes.closuresShow} element={<CompanyRoute><scenes.ClosuresShow /></CompanyRoute>} />
          <Route path={routes.annualReportArchive} element={<CompanyRoute><scenes.AnnualReportArchive /></CompanyRoute>} />
          <Route path={routes.FetchFiles} element={<scenes.FetchFiles />} />
          <Route path={routes.closures} element={<CompanyRoute><scenes.Closures /></CompanyRoute>} />
          
          {/* Todo */}
          <Route path={routes.todo} element={<CompanyRoute><scenes.Todo /></CompanyRoute>} />
          <Route path={routes.importantDates} element={<CompanyRoute><scenes.ImportantDates /></CompanyRoute>} />
          <Route path={routes.preliminaryTaxes} element={<CompanyRoute><scenes.PreliminaryTaxes /></CompanyRoute>} />
          <Route path={routes.preliminaryTaxesPaymentDetails} element={<CompanyRoute><scenes.PreliminaryTaxesPaymentDetails /></CompanyRoute>} />
          <Route path={routes.todoPromotionAvtal24} element={<CompanyRoute><scenes.TodoPromotionAvtal24 /></CompanyRoute>} />
          <Route path={routes.todoPromotionMynt} element={<CompanyRoute><scenes.TodoPromotionMynt /></CompanyRoute>} />
          
          {/* Agencies */}
          <Route path={routes.agencyIndex} element={<scenes.AgencyIndex />} />
          <Route path={routes.createAgency} element={<UserRoute><scenes.CreateAgency /></UserRoute>} />
          <Route path={routes.preCreateAgency} element={<UserRoute><scenes.PreCreateAgency /></UserRoute>} />
          <Route path={routes.agencyOverview} element={<AgencyRoute><scenes.AgencyOverview /></AgencyRoute>} />
          <Route path={routes.agencyPartnerInfo} element={<AgencyRoute><scenes.AgencyPartnerInfo /></AgencyRoute>} />
          <Route path={routes.agencyClients} element={<AgencyRoute><scenes.AgencyClients /></AgencyRoute>} />
          <Route path={routes.agencyUsers} element={<AgencyRoute><scenes.AgencyUsers /></AgencyRoute>} />
          <Route path={routes.agencyNotes} element={<AgencyRoute><scenes.AgencyNotes /></AgencyRoute>} />
          <Route path={routes.agencyClientDetails} element={<AgencyRoute><scenes.AgencyClientDetails /></AgencyRoute>} />
          <Route path={routes.exportNotes} element={<scenes.ExportNotes />} />
          <Route path={routes.AgencyPartnerContractPdf} element={<scenes.AgencyPartnerContractPdf />} />
          <Route path={routes.agencySettings} element={<AgencyRoute><scenes.AgencySettings /></AgencyRoute>} />
          <Route path={routes.agencyImportClients} element={<AgencyRoute><scenes.AgencyImportClients /></AgencyRoute>} />
          <Route path={routes.agencyClientRequests} element={<AgencyRoute><scenes.AgencyClientRequests /></AgencyRoute>} />
          <Route path={routes.agencyDetailsScene} element={<AgencyRoute><scenes.AgencyDetailsScene /></AgencyRoute>} />
          <Route path={routes.agencyNotificationSettings} element={<AgencyRoute><scenes.AgencyNotificationSettings /></AgencyRoute>} />
          <Route path={routes.agencyExport} element={<AgencyRoute><scenes.AgencyExport /></AgencyRoute>} />
          <Route path={routes.leaveAgency} element={<AgencyRoute><scenes.LeaveAgency /></AgencyRoute>} />
          <Route path={routes.deleteAgency} element={<AgencyRoute><scenes.DeleteAgency /></AgencyRoute>} />
          <Route path={routes.backofficeFeatureToggles} element={<AgencyRoute><scenes.BackofficeFeatureToggles /></AgencyRoute>} />
          <Route path={routes.agencyPartnerProgram} element={<AgencyRoute><scenes.AgencyPartnerProgram /></AgencyRoute>} />
          <Route path={routes.agencyQuoteRequestsList} element={<AgencyRoute><scenes.AgencyQuoteRequestsList /></AgencyRoute>} />
          <Route path={routes.agencyQuoteRequest} element={<AgencyRoute><scenes.AgencyQuoteRequest /></AgencyRoute>} />
          <Route path={routes.agencyNotificationRedirect} element={<AgencyRoute><scenes.AgencyNotificationRedirect /></AgencyRoute>} />
          
          {/* BackOffice */}
          <Route path={routes.backOfficeAcceptInvitation} element={<scenes.BackOfficeAcceptInvitation />} />
          <Route path={routes.recordingSupport} element={<scenes.RecordingSupport />} />
          <Route path={routes.supportTicketReceiptImage} element={<scenes.SupportTicketReceiptImage />} />
          <Route path={routes.supportTicketReceiptExtraPage} element={<scenes.SupportTicketReceiptExtraPage />} />
          <Route path={routes.backOffice} element={<BackOfficeRoute><scenes.BackOffice /></BackOfficeRoute>} />
          <Route path={routes.backOfficeCompaniesDashboard} element={<BackOfficeRoute><scenes.BackOfficeCompaniesDashboard /></BackOfficeRoute>} />
          <Route path={routes.backOfficeAdminAgencies} element={<BackOfficeRoute><scenes.BackOfficeAdminAgencies /></BackOfficeRoute>} />
          <Route path={routes.backOfficeAdminAgency} element={<BackOfficeRoute><scenes.BackOfficeAdminAgency /></BackOfficeRoute>} />
          <Route path={routes.backOfficeUsers} element={<BackOfficeRoute><scenes.BackOfficeUsers /></BackOfficeRoute>} />
          
          {/* Companies */}
          <Route path={routes.root} element={<scenes.Root />} />
          <Route path={routes.companyIndex} element={<scenes.CompanyIndex />} />
          <Route path={routes.selectCompanyFromMail} element={<UserRoute><scenes.SelectCompanyFromMail /></UserRoute>} />
          <Route path={routes.selectCompany} element={<UserRoute><scenes.SelectCompany /></UserRoute>} />
          <Route path={routes.dashboard} element={<CompanyRoute><scenes.Dashboard /></CompanyRoute>} />
          
          {/* Services */}
          <Route path={routes.accountingServices} element={<CompanyRoute><scenes.AccountingServices /></CompanyRoute>} />
          <Route path={routes.submitAgencyInterest} element={<CompanyRoute><scenes.SubmitAgencyInterest /></CompanyRoute>} />
          <Route path={routes.businessServices} element={<CompanyRoute><scenes.BusinessServices /></CompanyRoute>} />
          
          {/* Accounting */}
          <Route path={routes.bankTransactions} element={<CompanyRoute><scenes.BankTransactions /></CompanyRoute>} />
          <Route path={routes.assetsManagement} element={<CompanyRoute><scenes.AssetsManagement /></CompanyRoute>} />
          
          {/* Uploads */}
          <Route path={routes.uploads} element={<CompanyRoute><scenes.Uploads /></CompanyRoute>} />
          <Route path={routes.downloadAdditionalFile} element={<scenes.DownloadAdditionalFile />} />
          <Route path={routes.downloadAdditionalFilesAsZip} element={<scenes.DownloadAdditionalFilesAsZip />} />
          
          {/* Integrations */}
          <Route path={routes.integrations} element={<CompanyRoute><scenes.Integrations /></CompanyRoute>} />
          <Route path={routes.stripe} element={<CompanyRoute><scenes.Stripe /></CompanyRoute>} />
          <Route path={routes.stripeCallback} element={<scenes.StripeCallback />} />
          <Route path={routes.stripeOnboardingMock} element={<scenes.StripeOnboardingMock />} />
          
          {/* CustomerPortal */}
          <Route path={routes.invoicePortal} element={<scenes.InvoicePortal />} />
          <Route path={routes.invoicePortalPdf} element={<scenes.InvoicePortalPdf />} />
          
          {/* DirectorsPayroll */}
          <Route path={routes.employerDirectorSetup} element={<CompanyRoute><scenes.EmployerDirectorSetup /></CompanyRoute>} />
          <Route path={routes.directorsPayrollList} element={<CompanyRoute><scenes.DirectorsPayrollList /></CompanyRoute>} />
          <Route path={routes.directorsPayrollDetails} element={<CompanyRoute><scenes.DirectorsPayrollDetails /></CompanyRoute>} />
          <Route path={routes.addDirector} element={<CompanyRoute><scenes.AddDirector /></CompanyRoute>} />
          
          {/* Sales */}
          <Route path={routes.dailyTakings} element={<CompanyRoute><scenes.DailyTakings /></CompanyRoute>} /></React.Fragment>;
};
