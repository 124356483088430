import { UnorderedList } from "@bokio/components/UnorderedList/UnorderedList";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./checklist.scss";

interface ChecklistProps {
	heading?: string;
	list: (React.ReactNode | string)[];
	className?: string;
	addDefaultIcons?: boolean;
}

export const Checklist: React.FC<ChecklistProps> = ({ heading, list, className, addDefaultIcons = true }) => {
	const containerClassName = className
		? mergeClassNames(styles.checklistContainer, className)
		: styles.checklistContainer;

	return (
		<div className={containerClassName}>
			{heading && <h4>{heading}</h4>}
			<UnorderedList
				items={list}
				iconProps={
					addDefaultIcons
						? {
								name: "check",
								color: "green",
								size: "16",
							}
						: undefined
				}
				hideBullets={!addDefaultIcons}
			/>
		</div>
	);
};
