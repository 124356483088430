import * as React from "react";

import { CheckBox } from "@bokio/elements/CheckBox/CheckBox";
import classes from "@bokio/utils/classes";

import TableData from "./TableData";

import type { TableAutoProps } from "./TableAutoProps";

import * as styles from "./style.scss";

export interface TableHeaderProps {
	children?: React.ReactNode;
	check?: boolean;
	clickableItems?: boolean;
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	autoProps?: TableAutoProps;
}

class TableHeader extends React.Component<React.PropsWithChildren<TableHeaderProps>> {
	onChange(checked: boolean) {
		if (this.props.onChange) {
			this.props.onChange(checked);
		}
	}

	render() {
		const classNames = classes(styles, "table__th", {
			table__th__header: !this.props.autoProps || this.props.autoProps.nestingLevel === 0,
			table__th__subheader: this.props.autoProps && this.props.autoProps.nestingLevel === 1,
			table__th__subsubheader: this.props.autoProps && this.props.autoProps.nestingLevel >= 2,
		});

		return (
			<thead>
				<tr className={classNames}>
					{this.props.check && (
						<TableData>
							<CheckBox
								onChange={(checked: boolean) => this.onChange(checked)}
								checked={this.props.checked}
								rightMargin={true}
							/>
						</TableData>
					)}
					{this.props.children}
					{this.props.clickableItems && <TableData />}
				</tr>
			</thead>
		);
	}
}

export default TableHeader;
