import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import Markdown from "@bokio/elements/Markdown/Markdown";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { noop } from "@bokio/shared/utils";

import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import SupportMessageType = m.Entities.Support.SupportMessageType;

export interface BbaInactivityFeeModalProps {
	companyId: string;
	type: m.Entities.Support.SupportMessageType;
	resolve: () => void;
	resolveRequest: RequestState<m.Envelope<boolean, m.SimpleError>>;
	dismiss: () => void;
	dismissRequest: RequestState<m.Envelope<boolean, m.SimpleError>>;
}

export const BbaInactivityFeeModal = (props: BbaInactivityFeeModalProps) => {
	if (props.type !== SupportMessageType.BbaInactivityFee) {
		return null;
	}

	return (
		<>
			<Modal
				visible={true}
				disableBackgroundClick={true}
				testId="BbaInactivityFee_modal"
				onClose={noop} // Not closable
				title={"Title to be determined"}
				disabled={true} // disables close button
			>
				<ModalContent>
					<Markdown markdownContent={"Content To Be Determined"} />
				</ModalContent>

				<ModalFooter>
					<Button
						appearance="secondary"
						disabled={props.resolveRequest.isLoading}
						loading={props.resolveRequest.isLoading}
						type="button"
						onClick={props.resolve}
					>
						{"Keep BBA"}
					</Button>

					<Button
						disabled={props.dismissRequest.isLoading}
						loading={props.dismissRequest.isLoading}
						type="button"
						onClick={() => {
							props.dismiss;
							getRoute("bokioBusinessAccountOffboarding", { company: props.companyId });
						}}
					>
						{"Terminate BBA"}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
