import {
	LoadingBox,
	LoadingContent,
	LoadingList,
	LoadingPage,
	LoadingProgress,
	LoadingSpinner,
	LoadingText,
} from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";

import type { NoticeProps } from "@bokio/elements/Notice/Notice";
import type { Envelope } from "@bokio/mobile-web-shared/core/model/model";
import type { MetaData } from "@bokio/mobile-web-shared/core/model/types";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";
import type * as React from "react";

export type LoadingComponents = "progress" | "spinner" | "list" | "box" | "text" | "content" | "page";

const mapLoadingComponent = (
	whenLoading: LoadingComponents | React.ReactNode | (() => React.ReactNode),
): React.ReactNode | null => {
	if (typeof whenLoading === "function") {
		return whenLoading();
	}

	switch (whenLoading) {
		case "spinner":
			return <LoadingSpinner />;
		case "progress":
			return <LoadingProgress />;
		case "box":
			return <LoadingBox />;
		case "list":
			return <LoadingList />;
		case "text":
			return <LoadingText />;
		case "content":
			return <LoadingContent />;
		case "page":
			return <LoadingPage />;
		default:
			return whenLoading ?? null;
	}
};

export interface RenderRequestError<TError> {
	Error: TError | undefined;
	ErrorMessage: string | undefined;
}

interface RenderRequestProps<Result, TError> {
	request: RequestState<Envelope<Result, TError>>;
	whenLoading: LoadingComponents | React.ReactNode | (() => React.ReactNode);
	whenError?: "useDefault" | ((result: RenderRequestError<TError>) => React.ReactNode);
	errorNoticeProps?: NoticeProps;
	useCacheWhenLoading?: boolean;
	children?: (state: Result, meta?: MetaData) => React.ReactNode;
}

/** Renders a request and helps you with null-propagation.
 * @example
 * <RenderRequest
 * 	request={SomeRequestWithEnvelope}
 * 	whenLoading="progress"
 * 	whenError="useDefault"
 * >
 * 	{data => <Content data={data} />}
 * </RenderRequest>
 *
 */
export default function RenderRequest<Result, TError>({
	request,
	children,
	whenLoading,
	useCacheWhenLoading,
	whenError = "useDefault",
	errorNoticeProps,
}: RenderRequestProps<Result, TError>) {
	const renderError = (error: RenderRequestError<TError>) => {
		if (whenError === "useDefault") {
			return (
				// Test ID is matching RenderRequestError by default. Consider replacing the implementation here with that component.
				<Notice testId="LoadingWithError_ErrorMessage" color="warning" {...errorNoticeProps}>
					<Markdown markdownContent={error.ErrorMessage || ""} />
				</Notice>
			);
		}
		return whenError(error);
	};

	const useCache = useCacheWhenLoading && request.data !== undefined;

	if (request.isLoading && !useCache) {
		return mapLoadingComponent(whenLoading);
	}

	// Standard Envelope error
	if (request.error) {
		const error: RenderRequestError<TError> = {
			Error: undefined,
			ErrorMessage: request.errorMessage,
		};
		return renderError(error);
	}

	if (request.data && !request.data.Success) {
		const error: RenderRequestError<TError> = {
			Error: request.data.Error,
			ErrorMessage: request.data.ErrorMessage,
		};
		return renderError(error);
	}

	if (!request.data) {
		return null;
	}

	return children ? children(request.data.Data, request.data.Meta) : null;
}
