import { matchPath } from "react-router";

import { routes } from "@bokio/shared/route";

export function isActiveRoute(
	matchExact: boolean | undefined,
	currentRoute: string | undefined,
	route: string | undefined,
) {
	// Try to match the current internal route
	if (route && currentRoute) {
		const regexToMatch = matchExact ? route + "$" : route; // uses exact match or part match
		return !!currentRoute.match(regexToMatch);
	}
	return false;
}

export function isBookkeepingRoute(pathname: string) {
	const match =
		matchPath(routes.preBookkeep, pathname) ||
		matchPath(routes.preBookkeepSupplierInvoice, pathname) ||
		matchPath(routes.bookkeepReceipt, pathname) ||
		matchPath(routes.bookkeepExpense, pathname) ||
		matchPath(routes.bookkeepTransaction, pathname) ||
		matchPath(routes.bookkeepSupplier, pathname);

	return Boolean(match);
}
