import { useCallback, useEffect, useRef } from "react";

export const useTrackPageVisibility = ({
	onPageVisible,
	onPageHidden,
}: {
	onPageVisible?: () => void;
	onPageHidden?: () => void;
}) => {
	const pageNavigationTimeoutRef = useRef<NodeJS.Timeout>();

	// This function calls the returned callback, if the window is visible
	const visibilityChanges = useCallback(() => {
		if (document.visibilityState == "visible") {
			// Delay the return to make sure the window is in a state that can perform network requests
			clearTimeout(pageNavigationTimeoutRef.current);
			if (onPageVisible !== undefined) {
				pageNavigationTimeoutRef.current = setTimeout(onPageVisible, 1000);
			}
			return true;
		} else if (document.visibilityState == "hidden") {
			clearTimeout(pageNavigationTimeoutRef.current);
			if (onPageHidden !== undefined) {
				pageNavigationTimeoutRef.current = setTimeout(onPageHidden, 1000);
			}
		}
		return false;
	}, [onPageVisible, onPageHidden]);

	// Wait for return from BankID
	useEffect(() => {
		// Wait until the visibility changes back to visible
		document.addEventListener("visibilitychange", visibilityChanges);

		return () => {
			clearTimeout(pageNavigationTimeoutRef.current);
			document.removeEventListener("visibilitychange", visibilityChanges);
		};
	}, [visibilityChanges]);
};
