import * as React from "react";

import { Modal, ModalContent } from "@bokio/components/Modal";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import { usePricePlanFeatureContext } from "@bokio/contexts/PricePlanFeatureContext/PricePlanFeatureContext";
import { Button } from "@bokio/designsystem/components/Button";
import { DotBadge } from "@bokio/designsystem/components/DotBadge/DotBadge";
import { Badge } from "@bokio/elements/Badge/Badge";
import { FormWithValidation } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingProgress, LoadingProgressText } from "@bokio/elements/Loading";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { PromoBadge } from "@bokio/elements/PromoBadge/PromoBadge";
import { Tooltip } from "@bokio/elements/Tooltip";
import { useLoader } from "@bokio/hooks/useLoader/useLoader";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useUmbracoApi } from "@bokio/shared/services/api/UmbracoApi";
import { useAgencyStatus, useCompanyUser, useUser } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";
import { trackEvent } from "@bokio/utils/t";

import { HelpArticle } from "../HelpArticle";
import { HelpMessageForm } from "../HelpMessageForm";
import HelpMessageStatus from "../HelpMessageStatus";
import { HelpSearch } from "../HelpSearch";
import { helpSearchAnalytics } from "../HelpSearchAnalytics";
import { HelpWindowMode, newHelpStateValidator } from "../helpSupportUtils";
import ToggleOnboardingOption from "./components/ToggleOnboardingOption/ToggleOnboardingOption";
import { WhatsNewFlyout } from "./components/WhatsNewFlyout/WhatsNewFlyout";

import type { FileWithPreview } from "@bokio/elements/FileUploader/components/FilePreview/FilePreview";
import type { HelpSearchSuggestion } from "@bokio/shared/models/HelpSearchSuggestion";
import type { UmbracoReleaseNoteModel } from "@bokio/shared/models/UmbracoReleaseNotesModel";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./help.scss";

import PricePlanFeature = m.Bokio.Common.Contract.ViewData.PricePlanFeature;

interface HelpProps {
	direction: "up" | "down";
	whatsNewData?: UmbracoReleaseNoteModel[];
	whatsNewBadge?: boolean;
	onWhatsNewClick?: () => void;
}

export type MenuHelpMessageFormValues = Omit<m.Contracts.HelpRequest, "CurrentPage" | "Subject">;

export const Help: React.FC<HelpProps> = ({ direction, whatsNewData, whatsNewBadge, onWhatsNewClick }) => {
	const { user } = useUser();
	const { companyInfo } = useCompanyInfo();
	const { location } = useRouter();
	const context = useAppContext();
	const [showWhatsNew, setShowWhatsNew] = React.useState(false);
	const [files, setFiles] = React.useState<FileWithPreview[]>([]);
	const { agencyStatus } = useAgencyStatus();
	const lang = GeneralLangFactory();
	const {
		visible,
		toggleVisible,
		view,
		onGoBack,
		onNavigate,
		openHelpArticle,
		resetHelpArticle,
		onClearHistory,
		setSearchString,
		areaPrefilled,
		messagePrefilled,
		referenceId,
		modalMode,
	} = useHelpContext();
	const companyUser = useCompanyUser();
	const { isPricePlanFeatureEnabled } = usePricePlanFeatureContext();

	const isAgency = !!companyUser.companyUserPermissions?.Partner;

	const [support, supportRequest] = useLazyApi(proxy.Settings.HelpController.Create.Post, {
		onSuccess: () => {
			helpSearchAnalytics.trackMessage();
			onNavigate(HelpWindowMode.MessageStatus);
			resetHelpArticle();
			setFiles([]);
		},
	});

	const [nonAutheticatedSupport, nonAutheticatedSupportRequest] = useLazyApi(
		proxy.Settings.HelpController.CreateNotAuthenticated.Post,
		{
			onSuccess: () => {
				helpSearchAnalytics.trackMessage();
				onNavigate(HelpWindowMode.MessageStatus);
				setFiles([]);
			},
		},
	);

	const [agencySupport, agencySupportRequest] = useLazyApi(proxy.BackOffice.AgencyHelpController.Create.Post, {
		onSuccess: () => {
			helpSearchAnalytics.trackMessage();
			onNavigate(HelpWindowMode.MessageStatus);
			setFiles([]);
		},
	});
	const [categories, categoriesRequest] = useLazyApi(proxy.Settings.HelpController.SupportCategories.Get);

	const pathname = location?.pathname;

	// Only load categories when help is visible
	React.useEffect(() => {
		if (visible && pathname && categoriesRequest.data?.Data === undefined && !categoriesRequest.isLoading) {
			categories({ Url: pathname });
		}
		return setFiles([]);
	}, [visible, categories, pathname, companyInfo?.Id, categoriesRequest.data?.Data, categoriesRequest.isLoading]);

	const { search } = useUmbracoApi();

	const doSendMessage = async (formData: MenuHelpMessageFormValues) => {
		const request = {
			...formData,
			CurrentPage: pathname || "",
			Subject: !!agencyStatus?.Id ? lang.ContactSupportAgency : "",
		};

		companyInfo
			? await support(companyInfo.Id, request, files)
			: !!agencyStatus?.Id
				? await agencySupport(agencyStatus.Id, request, files)
				: await nonAutheticatedSupport(request, files);
	};

	const helpItemClicked = (item: HelpSearchSuggestion) => {
		helpSearchAnalytics.cancelSearchTracking();
		onNavigate(HelpWindowMode.Article);
		openHelpArticle(item.Id);
	};

	const searchLoader = useLoader({
		endpoint: (searchString: string) => search(searchString, 6),
	});

	const onSearch = (searchString: string) => {
		onNavigate(HelpWindowMode.Search);
		if (searchString.trim()) {
			//Empty search strings makes Umbraco crash
			searchLoader.load(searchString);
		}
	};

	const onClearSearch = () => {
		onClearHistory();
		setSearchString("");
		searchLoader.reset();
		resetHelpArticle();
	};

	const handleContactSupport = () => {
		helpSearchAnalytics.trackContactSupport("Contact-Support");
		onNavigate(HelpWindowMode.Message);
	};

	const onCloseMessage = () => {
		helpSearchAnalytics.resetSearch();
		onNavigate(HelpWindowMode.Default);
		setSearchString("");
		searchLoader.reset();
		resetHelpArticle();
		modalMode && toggleVisible();
	};

	const sendMessageClick = async (formData: MenuHelpMessageFormValues) => {
		const validator = newHelpStateValidator(!!companyInfo);
		const vr = validator.validate(formData);
		const canSendMessage = vr.errors.length === 0;
		if (canSendMessage) {
			await doSendMessage(formData);
		}
	};

	const showSearch = view === HelpWindowMode.Search || view === HelpWindowMode.Default;
	const showBack = view === HelpWindowMode.Message;
	const confirmationMessage = lang.HelpConfirmationText;
	const isLoading =
		supportRequest.isLoading ||
		nonAutheticatedSupportRequest.isLoading ||
		categoriesRequest.isLoading ||
		agencySupportRequest.isLoading;

	const messageFormContent = visible && categoriesRequest.data?.Data && (
		<FormWithValidation<MenuHelpMessageFormValues, ValidatorResult>
			initialState={{
				Email: user?.Email || "",
				Message: messagePrefilled ?? "",
				Name: `${user?.FirstName ?? ""} ${user?.LastName ?? ""}`,
				Area: categoriesRequest.data.Data.SupportAreas.find(a => a.Key === areaPrefilled)
					? areaPrefilled
					: categoriesRequest.data.Data.DefaultArea,
				Category: m.Contracts.SupportFormCategory.NotSet,
				SubCategory: m.Contracts.SupportFormSubCategory.NotSet,
				Type: undefined,
				ReferenceId: referenceId ?? "",
				PhoneNumber: "",
			}}
			validator={asValidatorFunction(newHelpStateValidator(!!companyInfo))}
			onSubmit={async formData => {
				await sendMessageClick(formData);
			}}
		>
			{({ formData, setFormData, validation }) => (
				<>
					<RenderRequest request={categoriesRequest} whenLoading={<LoadingProgress />}>
						{data => (
							<HelpMessageForm
								isOpened={view === HelpWindowMode.Message}
								modalView={modalMode}
								formData={formData}
								setFormData={setFormData}
								isLoggedIn={!!companyInfo}
								validation={validation}
								areas={data.SupportAreas}
								files={files}
								setFiles={setFiles}
							/>
						)}
					</RenderRequest>
					{view === HelpWindowMode.MessageStatus && (
						<HelpMessageStatus
							confirmationMessage={confirmationMessage}
							isLoading={isLoading}
							onClose={onCloseMessage}
						/>
					)}
					{(view === HelpWindowMode.Article || view === HelpWindowMode.Message || view === HelpWindowMode.Search) && (
						<div className={modalMode ? styles.helpSearchBtnModal : styles.helpSearchBtn}>
							{view === HelpWindowMode.Message ? (
								<Button
									appearance="primary"
									type="submit"
									disabled={isLoading}
									testId="Help_ContactSupport_SendMessage"
								>
									{lang.HelpSendMessage}
								</Button>
							) : (
								<Button
									appearance="secondary"
									onClick={() => {
										helpSearchAnalytics.cancelSearchTracking();
										onNavigate(HelpWindowMode.Message);
									}}
									testId="Help_ContactSupport"
								>
									{lang.ContactSupport_action}
								</Button>
							)}
						</div>
					)}
					<LoadingProgressText
						text={lang.Help_SendingMessage}
						requests={[supportRequest, nonAutheticatedSupportRequest, agencySupportRequest]}
					/>
				</>
			)}
		</FormWithValidation>
	);

	// Show support form as modal if modalMode is set
	if (modalMode && (view === HelpWindowMode.Message || view === HelpWindowMode.MessageStatus) && visible) {
		return (
			<Modal
				title={lang.ContactSupport_action}
				visible={modalMode}
				onClose={onCloseMessage}
				testId="Help_ContactSupportModal"
			>
				<ModalContent>{messageFormContent}</ModalContent>
			</Modal>
		);
	}

	const message = () => {
		return <Markdown markdownContent={lang.Help_PhoneNumber_Tooltip} />;
	};

	const canReceivePhoneSupport = isPricePlanFeatureEnabled(PricePlanFeature.TelephoneSupport) || isAgency;

	return (
		<div
			className={mergeClassNames(
				direction === "up" ? styles.helpMenuUp : styles.helpMenu,
				visible && styles.showHelpMenu,
			)}
		>
			<div className={styles.mobileHeader}>
				<h2>{lang.Help_HowCanWeHelpYou}</h2>
				<Link style="none" className={styles.closeButton} onClick={toggleVisible}>
					<Icon name="cancel" size="22" />
				</Link>
			</div>
			{showSearch && (
				<HelpSearch
					itemClicked={(item: HelpSearchSuggestion) => helpItemClicked(item)}
					onClearSearch={onClearSearch}
					searchRequest={searchLoader.request}
					onSearch={onSearch}
				/>
			)}
			{view === HelpWindowMode.Article && (
				<div>
					<HelpArticle onGoBack={onGoBack} />
				</div>
			)}
			{showBack && (
				<a className={styles.backLink} onClick={onGoBack} data-testid="Help_Back">
					<Icon name="left-open" size="12" />
					{lang.Back}
				</a>
			)}

			{messageFormContent}

			{(view === HelpWindowMode.Default || (view === HelpWindowMode.Search && !searchLoader.request.data)) && (
				<div data-testid="Help_Links">
					<ToggleOnboardingOption onToggle={toggleVisible} />
					<Link className={styles.link} style="none" external={lang.BokioHelpBaseUrl} target={"_blank"}>
						{lang.HelpCenter_label}
					</Link>
					<Link
						className={styles.link}
						style="none"
						onClick={() => {
							setShowWhatsNew(true);
							onWhatsNewClick && onWhatsNewClick();
							trackEvent("What's new", "Click", "Open");
						}}
						testId="Help_WhatsNew"
					>
						{lang.MenuWhatsNew_label}
						{whatsNewBadge && (
							<div className={styles.badgeContainer}>
								<DotBadge testId="Help_WhatsNewBadge" />
							</div>
						)}
					</Link>
					<Link className={styles.link} style="none" external={lang.DataSecurityPageUrl} target={"_blank"}>
						{lang.DataSecurity}
					</Link>
					{companyInfo &&
						(context.featureAvailability.FindAnAccountant ? (
							<Link className={styles.link} style="none" external={lang.BokioPartnerUrl} target={"_blank"}>
								{lang.BokioPartner_label}
								<span className={styles.linkDescription}>{lang.BokioPartner_description}</span>
							</Link>
						) : (
							<Link
								onClick={toggleVisible}
								className={styles.link}
								style="none"
								route={getRoute("accountingServices", { company: companyInfo.Id })}
								target={"_blank"}
							>
								{lang.Menu_HireAccountant}
							</Link>
						))}
					<Link className={styles.link} style="none" external={lang.BokioFacebookCommunityUrl} target={"_blank"}>
						{lang.BokioEntrepreneurs_label}
						<span className={styles.linkDescription}>{lang.BokioEntrepreneurs_description}</span>
					</Link>
					<Link
						className={styles.link}
						style="none"
						onClick={handleContactSupport}
						target={"_blank"}
						testId="Help_Links_ContactSupport"
					>
						{lang.ContactSupport_action}
					</Link>
					<div data-testid="Help_PhoneSupport_Title" className={styles.divDisabled}>
						{lang.Menu_PhoneSupport_Title}
						<Tooltip showArrow contentGenerator={() => message()}>
							<Icon name={"info"} size="24" color="grey" />
						</Tooltip>
						{canReceivePhoneSupport ? (
							<>
								<div className={styles.badgePhoneSupportContainer}>
									<PromoBadge type="new" color="blue" />
								</div>
								<span className={styles.linkDescription}>{lang.Menu_PhoneSupport_Description}</span>
								<a data-testid="Help_PhoneSupport_Number" href={"tel:" + lang.Menu_PhoneSupport_Number}>{lang.Menu_PhoneSupport_Number}</a>
							</>
						) : (
							<>
								<div className={styles.badgePhoneSupportContainer} data-testid="Help_PhoneSupport_Locked">
									<Badge color="locked" icon="lock" iconPosition="left" name="" />
								</div>
								<span className={styles.linkDescription}>{lang.Menu_PhoneSupport_Description}</span>
							</>
						)}
					</div>
				</div>
			)}
			<WhatsNewFlyout
				onClose={() => {
					setShowWhatsNew(false);
					trackEvent("What's new", "Click", "Close");
				}}
				isOpen={showWhatsNew}
				whatsNewData={whatsNewData ?? []}
			/>
		</div>
	);
};
