import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./feedback.scss";

export type FeedbackGraphicType =
	| "closureJournals"
	| "complete"
	| "noExpenses"
	| "UploadsReceiptsOrInvoices"
	| "UploadsAdditionalFiles"
	| "externalOnboarding"
	| "factoringOnboarding"
	| "noNotifications"
	| "payrollTaxes"
	| "vacation"
	| "emptyState"
	| "emptyStateAlt1"
	| "emptyStateAlt2"
	| "emptyStateAlt3"
	| "agencyClientsList"
	| "agencyClientsRequests"
	| "agencyClientComments"
	| "agencyClientTasks"
	| "agencyDashboardComments"
	| "agencyDashboardTasks"
	| "bankInboxMessage"
	| "expenseClaimsPayments"
	| "bankInboxMessage"
	| "purchasesSuppliers"
	| "expensesMyExpensesClaims"
	| "expenseClaimsPermissions"
	| "expensesAllExpensesClailms"
	| "staffSalaryEmployees"
	| "staffSalaryVacation"
	| "settingsBankRules"
	| "settingsCustomBokkeping"
	| "settingsImportPreviousBookkeeping"
	| "noBookkeeping"
	| "supplierInvoices"
	| "emptyStateAccountantPanel"
	| "customTodoEmptyState"
	| "todoEmptyState"
	| "etrEmptyStateConnectBank"
	| "etrEmptyStateNoEvents";

interface FeedbackGraphicProps {
	type: FeedbackGraphicType;
	testId?: string;
	className?: string;
}

export const FeedbackGraphic = ({ type, testId, className }: FeedbackGraphicProps) => {
	const getClassName = (type: FeedbackGraphicType) => {
		switch (type) {
			case "closureJournals":
				return styles.ClosureJournals;
			case "settingsImportPreviousBookkeeping":
				return styles.settingsImportPreviousBookkeeping;
			case "settingsCustomBokkeping":
				return styles.SettingsCustomBokkeping;
			case "settingsBankRules":
				return styles.SettingsBankRules;
			case "staffSalaryEmployees":
				return styles.StaffSalaryEmployees;
			case "staffSalaryVacation":
				return styles.StaffSalaryVacation;
			case "expensesAllExpensesClailms":
				return styles.ExpensesAllExpensesClailms;
			case "expenseClaimsPermissions":
				return styles.ExpenseClaimsPermissions;
			case "expensesMyExpensesClaims":
				return styles.ExpensesMyExpensesClaims;
			case "complete":
				return styles.imageComplete;
			case "noExpenses":
				return styles.imageNoExpenses;
			case "expenseClaimsPayments":
				return styles.ExpenseClaimsPayments;
			case "purchasesSuppliers":
				return styles.PurchasesSuppliers;
			case "UploadsReceiptsOrInvoices":
				return styles.UploadsReceiptsOrInvoices;
			case "noBookkeeping":
				return styles.imageNoBookkeeping;
			case "UploadsAdditionalFiles":
				return styles.UploadsAdditionalFiles;
			case "externalOnboarding":
				return styles.imageExternalOnboarding;
			case "factoringOnboarding":
				return styles.imageFactoringOnboarding;
			case "noNotifications":
				return styles.imageNoNotifications;
			case "payrollTaxes":
				return styles.imagePayrollTaxes;
			case "vacation":
				return styles.imageVacation;
			case "emptyState":
				return styles.imageEmptyState;
			case "emptyStateAlt1":
				return styles.imageEmptyStateAlt1;
			case "emptyStateAlt2":
				return styles.imageEmptyStateAlt2;
			case "emptyStateAlt3":
				return styles.imageEmptyStateAlt3;
			case "agencyClientsList":
				return styles.imageAgencyClientslist;
			case "agencyClientsRequests":
				return styles.imageAgencyClientsRequests;
			case "agencyClientComments":
				return styles.imageAgencyClientComments;
			case "agencyClientTasks":
				return styles.imageAgencyClientTasks;
			case "agencyDashboardComments":
				return styles.imageAgencyDashboardComments;
			case "agencyDashboardTasks":
				return styles.imageAgencyDashboardTasks;
			case "bankInboxMessage":
				return styles.imageBankInboxMessage;
			case "supplierInvoices":
				return styles.SupplierInvoices;
			case "emptyStateAccountantPanel":
				return styles.imageEmptyStateAccountantPanel;
			case "customTodoEmptyState":
				return styles.imageEmptyStateCustomTodos;
			case "todoEmptyState":
				return styles.imageEmptyStateTodos;
			case "etrEmptyStateConnectBank":
				return styles.imageEtrEmptyStateConnectBank;
			case "etrEmptyStateNoEvents":
				return styles.imageEtrEmptyStateNoEvents;
			default:
				return styles.UploadsReceiptsOrInvoices;
		}
	};

	const classNames = mergeClassNames(getClassName(type), className);

	return <div data-testid={testId} className={classNames} />;
};
