import * as React from "react";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface BackOfficeContext {
	partner: m.Bokio.BackOffice.PartnerStatusDto;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const backOfficeContext = React.createContext<BackOfficeContext>({} as BackOfficeContext);

export const useBackOfficeContext = () => React.useContext(backOfficeContext);
