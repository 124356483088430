import { useCallback, useEffect, useRef } from "react";

import { readFromLocalStorage, writeToLocalStorage } from "@bokio/hooks/useLocalStorage/useLocalStorage";
import { useTrackPageVisibility } from "@bokio/hooks/useTrackPageVisibility/useTrackPageVisibility";
import getBankIdPollingStopCondition from "@bokio/mobile-web-shared/areas/bank/utils/getBankIdPollingStopCondition";
import { useApiPolling } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import { trackError } from "@bokio/utils/t";

import type { Endpoint, PollingOptions } from "@bokio/mobile-web-shared/hooks/useApi/useApi";

export type PaymentSigningProgressInfo = {
	hasInitiated: boolean;
	isPolling: boolean;
	paymentGroupIds: string[] | undefined;
	SuccessfulPaymentIds: string[] | undefined;
	signingToken: string | undefined;
};

enum SveaBankIdSigningLocalStorageSavedStates {
	AuthProgressInfo = "sveaAuthToken",
	PaymentSigningProgressInfo = "sveaPaymentSigningInfo",
}

/**
 * Save the status polling token when a Svea authentication is in progress. This token value is used to resume the polling process
 * if the polling is interuppted due to a page refresh while the polling is in progress.
 * @param token
 */
export function saveSveaAuthPollingTokenToLocalStorage(token: string) {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo, token);
}
export function readSveaAuthPollingTokenFromLocalStorage(): string | undefined {
	return readFromLocalStorage<string>(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo);
}
export function clearSveaAuthPollingTokenFromLocalStorage() {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.AuthProgressInfo, undefined);
}

/**
 * Save the status when a Svea payment signing is started & in progress. This is used to resume the polling with the same token
 * if the polling is interuppted due to a page refresh. Sometimes Safari in IOS deallocate the memory when it navigate to
 * BankId app. When the BankId app redirect the user back to Safari browser, if the memory is deallocated, it will trigger a refreshe.
 * In those scenario we need to resume the same polling session to continue the payment signing.
 * @param paymentSigningProgressInfo
 */
export function savePaymentSigningProgressInfoToLocalStorage(paymentSigningProgressInfo: PaymentSigningProgressInfo) {
	writeToLocalStorage<PaymentSigningProgressInfo>(
		SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo,
		paymentSigningProgressInfo,
	);
}
export function readPaymentSigningProgressInfoFromLocalStorage(): PaymentSigningProgressInfo | undefined {
	return readFromLocalStorage<PaymentSigningProgressInfo>(
		SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo,
	);
}
export function clearPaymentSigningProgressInfoFromLocalStorage() {
	writeToLocalStorage(SveaBankIdSigningLocalStorageSavedStates.PaymentSigningProgressInfo, undefined);
}

type ApiPollingEndpointType<TParameters extends unknown[], TResult, TError> = typeof useApiPolling<
	TParameters,
	TResult,
	TError
>;

type BankIdPollingOptions<TParameters, TResult, TError> = {
	isPending: (res: TResult) => boolean;
} & Omit<PollingOptions<TParameters, TResult, TError>, "stopCondition">;

/**
 * A polling hook that is used for BankID polling. It will automatically restart polling when the user returns to the page. This
 * is to avoid network connection suspension on mobile browsers (particularly iOS/Webkit) that can block polling.
 * See https://dev.azure.com/bokiodev/Voder/_wiki/wikis/Voder.wiki/1522/BankID-on-Mobile-Web for more details on the reasoning.
 *
 * Additionally, the hook logs when an error is encountered during polling and no successful polling result is encountered within 5 seconds,
 * suggesting that the user is stuck on the BankID page.
 * @param purpose A string describing the usage of the hook, for logging
 * @param endpoint same as in {@link useApiPolling}
 * @param options same as in {@link useApiPolling} minus the stopCondition
 * @returns same as {@link useApiPolling}
 */
export function useBankIdPolling<TParameters extends unknown[], TResult, TError>(
	purpose: string,
	endpoint: Endpoint<TParameters, TResult, TError>,
	options: BankIdPollingOptions<TParameters, TResult, TError>,
) {
	const apiErrorBankIdTimeoutRef = useRef<NodeJS.Timeout>();
	const pollerRef = useRef<ReturnType<ApiPollingEndpointType<TParameters, TResult, TError>>>();
	const lastResultRef = useRef<TResult>();
	const lastParametersRef = useRef<TParameters>();

	const optionsRef = useRef(options);
	optionsRef.current = options;

	useEffect(() => {
		clearTimeout(apiErrorBankIdTimeoutRef.current);
	}, []);

	const onStopped: (typeof options)["onStopped"] = useCallback(
		context => {
			if (context?.apiError) {
				// Log if the auth didn't complete within 5 seconds because then we have problems
				// where you get stuck on the login page after coming back from BankID app
				apiErrorBankIdTimeoutRef.current = setTimeout(() => {
					trackError("We got apiError but didn't complete the BankID within 5 seconds", purpose, {
						request: JSON.stringify(pollerRef.current?.request),
					});
				}, 5000);
			}

			if (!context?.error && !context?.apiError) {
				// Clear error timeout if the polling successfully stopped
				clearTimeout(apiErrorBankIdTimeoutRef.current);
			}

			optionsRef.current.onStopped?.(context);
		},
		[purpose],
	);

	const onEffect: (typeof options)["effect"] = useCallback((...args) => {
		if (args[0] !== null) {
			lastResultRef.current = args[0];
		}
		optionsRef.current.effect?.(...args);
	}, []);

	// Store the latest parameters in order to restart polling when returning
	const startPolling = useCallback((parameters: TParameters) => {
		lastParametersRef.current = parameters;
		if (!pollerRef.current) {
			throw new Error("pollerRef.current not set");
		}

		pollerRef.current.startPolling(parameters);
	}, []);

	// TODO: cancel apiErrorBankIdTimeoutRef if polling is intentionally stopped (cancellation, etc.)?
	const onPageVisible = useCallback(() => {
		// The last collection status was pending, resume polling
		if (lastResultRef.current && lastParametersRef.current && optionsRef.current.isPending(lastResultRef.current)) {
			if (!pollerRef.current) {
				throw new Error("pollerRef.current not set");
			}
			pollerRef.current.startPolling(lastParametersRef.current);
		}
	}, []);

	// Enter page: start polling if we had a pending poll
	useTrackPageVisibility({
		onPageVisible,
	});

	const pollingOptions = {
		...optionsRef.current,
		stopCondition: getBankIdPollingStopCondition(optionsRef.current.isPending),
		onStopped,
		effect: onEffect,
		interval: 2000,
	};

	pollerRef.current = useApiPolling(endpoint, pollingOptions);

	return {
		...pollerRef.current,
		startPolling,
	};
}
