import * as React from "react";
import { Link } from "react-router";

import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import { getAlignSelfClassName, getMarginClassName } from "../Button/button.helper";
import { getSizeClassName } from "./iconButton.helper";

import type {
	CommonButtonHtmlProps,
	CommonButtonProps,
	ExternalLinkButtonProps,
	LinkButtonProps,
	NormalButtonProps,
} from "../Button/button.types";
import type { FontelloIcons } from "@bokio/assets/fontello";

// prettier-ignore
import * as styles from "./iconButton.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as baseStyles from "../Button/button.base.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

interface IconButtonProps extends CommonButtonProps {
	icon: FontelloIcons;
}

type Props = IconButtonProps & (NormalButtonProps | LinkButtonProps | ExternalLinkButtonProps);

export const IconButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
	({ size = "normal", icon, disabled, testId, id, title, autoFocus, align, margin, ...props }, ref) => {
		const classNames = mergeClassNames(
			baseStyles.button,
			baseStyles.secondary,
			styles.iconButton,
			getSizeClassName(size),
			disabled && baseStyles.disabled,
			disabled && baseStyles.disabledSecondary,
			align && getAlignSelfClassName(align),
			margin && getMarginClassName(margin),
		);

		const commonProps: CommonButtonHtmlProps = {
			id,
			title,
			className: classNames,
			"data-testid": testId,
		};

		if (props.type === "link" && !props.external) {
			return (
				<Link
					{...commonProps}
					onClick={e => (disabled ? e.preventDefault() : props.onNavigation?.())}
					to={props.route}
					ref={ref as React.Ref<HTMLAnchorElement>}
				>
					<Icon name={icon} size={size === "small" ? "18" : "20"} className={baseStyles.icon} />
				</Link>
			);
		}

		if (props.type === "link" && props.external) {
			return (
				<a
					{...commonProps}
					onClick={e => (disabled ? e.preventDefault() : props.onNavigation?.())}
					href={props.route}
					download={props.download}
					target={props.target}
					ref={ref as React.Ref<HTMLAnchorElement>}
				>
					<Icon name={icon} size={size === "small" ? "18" : "20"} className={baseStyles.icon} />
				</a>
			);
		}

		return (
			<button
				{...commonProps}
				onClick={disabled ? undefined : props.onClick}
				disabled={disabled}
				autoFocus={autoFocus}
				ref={ref as React.Ref<HTMLButtonElement>}
				type="button"
			>
				<Icon name={icon} size={size === "small" ? "18" : "20"} className={baseStyles.icon} />
			</button>
		);
	},
);
