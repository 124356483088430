import * as styles from "@bokio/elements/Form/form.scss";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

type FieldProps = {
	className?: string;
	testId?: string;
};

const Field: React.FC<React.PropsWithChildren<FieldProps>> = ({ children, className, testId }) => {
	return (
		<div className={mergeClassNames(styles.field, className)} data-testid={testId}>
			{children}
		</div>
	);
};

export default Field;
