import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { InputField } from "@bokio/elements/Form";
import { RenderRequestError } from "@bokio/elements/Loading";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useUser } from "@bokio/shared/state/requests";
import { noop } from "@bokio/shared/utils";
import { trackException } from "@bokio/utils/t";

export const AccountantDetailsModal: React.FC = () => {
	const { user, reloadUser } = useUser();

	const generalLang = GeneralLangFactory();
	const backOfficeLang = BackOfficeLangFactory();
	const [state, setState] = React.useState({
		firstName: (user && user.FirstName) || "",
		lastName: (user && user.LastName) || "",
	});
	const [setInfo, setInfoRequest] = useLazyApi(proxy.Settings.UserController.Edit.Post, {
		onSuccess: () => reloadUser(),
	});
	const isFormValid = state.firstName.length > 0 && state.lastName.length > 0;
	const onSubmit = () => {
		if (!user) {
			trackException(new Error("Unexpected case: User is undefined"), "AccountantDetailsModal", {});
			return;
		}
		setInfo({
			FirstName: state.firstName,
			LastName: state.lastName,
			Language: user.Language,
			PhoneNumber: user.PhoneNumber,
			UserResearchGroup: user.UserResearchGroup,
		});
	};
	return (
		<Modal alwaysMounted={true} title={backOfficeLang.BackOffice_Name} visible={true} onClose={noop} disabled={true}>
			<form
				onSubmit={e => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<ModalContent>
					<p>{backOfficeLang.WeNeedSomeMoreInfo}</p>
					<InputField
						label={generalLang.FirstName}
						value={state.firstName}
						onChange={firstName => setState(s => ({ ...s, firstName }))}
					/>
					<InputField
						label={generalLang.LastName}
						value={state.lastName}
						onChange={lastName => setState(s => ({ ...s, lastName }))}
					/>
				</ModalContent>
				<RenderRequestError request={setInfoRequest} />
				<ModalFooter>
					<Button loading={setInfoRequest.isLoading} disabled={!isFormValid} onClick={onSubmit}>
						{generalLang.Submit}
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};
