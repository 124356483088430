import * as React from "react";

import { Badge } from "@bokio/elements/Badge/Badge";
import DeviceQuery from "@bokio/elements/DeviceQuery/DeviceQuery";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading/LoadingText";
import { PromoBadge } from "@bokio/elements/PromoBadge/PromoBadge";
import { mergeClassNames } from "@bokio/utils/classes";

import type { MenuBadgeProps } from "../../utils/MenuHelper";
import type { FontelloIcons } from "@bokio/assets/fontello";
import type { LinkProps } from "@bokio/elements/Link/Link";
import type { PromoBadgeProps } from "@bokio/elements/PromoBadge/PromoBadge";

import * as styles from "./menuSection.scss";

interface MenuSectionProps extends LinkProps {
	title: string;
	badge?: MenuBadgeProps | PromoBadgeProps;
	counter?: JSX.Element;
	icon?: FontelloIcons;
	children?: React.ReactNode;
	mainRoute?: string;
	currentRoute?: string;
	isActive: boolean;
	loading?: boolean;
	onMouseOver?: () => void;
	onMouseOut?: () => void;
	hasSubs: boolean;
	isCurrentRoute: boolean;
	toggleSection: (key: string) => void;
	expanded: boolean;
	sectionTestId?: string;
}

interface MenuSectionState {
	topPos: number;
}

const MenuSection = ({
	icon,
	route,
	title,
	external,
	onClick,
	mainRoute,
	hasSubs,
	isCurrentRoute,
	toggleSection,
	expanded,
	badge,
	counter,
	isActive,
	loading,
	children,
	testId,
	sectionTestId,
}: MenuSectionProps) => {
	const [state, setSectionState] = React.useState<MenuSectionState>({
		topPos: 0,
	});

	const menuItemRef = React.createRef<HTMLDivElement>();

	const getSubMenuPos = () => {
		setSectionState(prevState => ({
			...prevState,
			topPos: menuItemRef.current ? menuItemRef.current.getBoundingClientRect().top : 0,
		}));
	};

	const renderHeader = (mobileOrTablet: boolean) => {
		const linkProps: LinkProps = {
			onClick:
				mobileOrTablet && hasSubs
					? !isCurrentRoute
						? () => toggleSection(title)
						: undefined
					: (e: React.MouseEvent<HTMLAnchorElement>) => onClick && onClick(e),
			style: "none",
			className: mergeClassNames(
				styles.title,
				hasSubs && styles.hasSubs,
				hasSubs ? (expanded ? styles.subsExpanded : styles.subsCollapsed) : undefined,
			),
			external: (!mobileOrTablet || !hasSubs) && external ? external : undefined,
			route: !mobileOrTablet || !hasSubs ? (mainRoute && !external ? mainRoute : route && route) : undefined,
		};
		return (
			<Link {...linkProps}>
				{icon && <Icon name={icon} size="24" className={styles.icon} />}
				{title}
				{badge && (
					<div className={mergeClassNames(hasSubs ? styles.badgeContainerWithSubs : styles.badgeContainer)}>
						{"name" in badge ? <Badge name={badge.name} color={badge.color} /> : <PromoBadge {...badge} />}
					</div>
				)}
				{counter && <div className={hasSubs ? styles.badgeContainerWithSubs : styles.badgeContainer}>{counter}</div>}
			</Link>
		);
	};

	const active = isActive || expanded;
	if (loading) {
		return (
			<div className={styles.loader}>
				<LoadingText />
			</div>
		);
	} else {
		return (
			<div
				ref={menuItemRef}
				onMouseOver={getSubMenuPos}
				className={mergeClassNames(styles.section, active && styles.activeSection)}
				data-testid={testId}
			>
				<DeviceQuery minTabletWidth={1024}>
					{({ isMobile, isTablet }) => renderHeader(isMobile || isTablet)}
				</DeviceQuery>
				<div
					className={active ? styles.subItemsActive : styles.subItemsHover}
					style={!active ? { top: state.topPos } : {}}
					data-testid={sectionTestId}
				>
					{children}
				</div>
			</div>
		);
	}
};

export default MenuSection;
