import { useCallback, useContext, useEffect, useRef } from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Field from "./Field/Field";
import { FormContext } from "./Form";
import LabelFor from "./LabelFor/LabelFor";

import type { FormContextValue } from "./Form";
import type { LabelTooltip } from "./LabelFor/LabelFor";
import type { FieldValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./form.scss";

interface TextAreaFieldProps {
	value?: string;
	onChange?: (value: string) => void;
	name?: string;
	label?: React.ReactNode;
	hint?: string;
	info?: React.ReactNode;
	disabled?: boolean;
	height?: string;
	rows?: number;
	errors?: FieldValidationResult;
	placeholder?: string;
	className?: string;
	autoFocus?: boolean;
	maxLength?: number;
	readonly?: boolean;
	/**
	 * Controls how the textarea resizes. Manual is when the user is allowed to drag it larger.
	 * auto will auto grow as the user types.
	 */
	resizeMode?: "none" | "manual" | "auto";
	testId?: string;
	labelTestId?: string;
	onHeightChange?: (height: number) => void;
	tooltip?: LabelTooltip;
}

export function TextAreaField({
	label,
	hint,
	info,
	height,
	name,
	value,
	rows,
	placeholder,
	errors,
	className,
	disabled,
	autoFocus,
	testId,
	maxLength,
	tooltip,
	labelTestId,
	onHeightChange,
	onChange,
}: TextAreaFieldProps) {
	const { disableAllFields } = useContext<FormContextValue>(FormContext);
	const fieldRef = useRef<HTMLTextAreaElement>(null);

	const autoGrow = useCallback(() => {
		if (fieldRef.current) {
			const borderHeight = fieldRef.current.offsetHeight - fieldRef.current.clientHeight;
			fieldRef.current.style.minHeight = height || "auto";
			fieldRef.current.style.height = "auto";
			const newHeight = fieldRef.current.scrollHeight + borderHeight;
			fieldRef.current.style.height = newHeight + "px";
			if (onHeightChange !== undefined) {
				onHeightChange(newHeight);
			}
		}
	}, [onHeightChange, height]);

	useEffect(() => {
		if (value === undefined || value === "") {
			autoGrow();
		}
	}, [value, autoGrow]);

	const handleInputChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (onChange) {
			onChange(event.currentTarget.value);
		}
		autoGrow();
	};

	const style = { height };

	return (
		<Field>
			<LabelFor
				label={label}
				hint={hint}
				info={info}
				errors={errors && errors.errors}
				tooltip={tooltip}
				testId={labelTestId}
			>
				<textarea
					data-testid={testId}
					ref={fieldRef}
					className={mergeClassNames(styles.field__input, styles.textArea, className)}
					style={style}
					name={name}
					value={value}
					maxLength={maxLength}
					rows={rows || 1}
					onChange={handleInputChanged}
					disabled={disableAllFields || disabled}
					placeholder={placeholder}
					autoFocus={autoFocus}
				/>
			</LabelFor>
		</Field>
	);
}
