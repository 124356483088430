import * as React from "react";

import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";

import Layout from "../Layout/Layout";
import TopBar, { TopBarMode } from "../TopBar/TopBar";

export const UserRoute = ({ children }: { children: React.ReactNode }) => {
	const { clearUser } = useTopLevelUser();

	React.useEffect(() => {
		clearUser();
	}, [clearUser]);

	return (
		<Layout
			isDemo={false}
			noSideBar={true}
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.User} toggleMenu={menuProps.toggleMenu} />
				</div>
			)}
			content={<>{children}</>}
		/>
	);
};

export default UserRoute;
